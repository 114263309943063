import React, { useState, useEffect } from "react";
import { mdiArrowRight } from "@mdi/js";
import Icon from "@mdi/react";
import Button from "../../Button";
import { NavLink } from "react-router-dom";

// Import images directly
import banner1 from "../../../assets/images/misc/banner_1.png";
import banner2 from "../../../assets/images/misc/banner_2.jpg";
import banner3 from "../../../assets/images/misc/banner_3.jpg";

// Array of images for the slider
const bannerImages = [banner1, banner2, banner3];

function SectionBanner() {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Function to go to the next image
  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % bannerImages.length);
  };

  // Auto-slide effect
  useEffect(() => {
    const interval = setInterval(nextImage, 5000); // Change image every 5 seconds
    return () => clearInterval(interval); // Clean up interval on unmount
  }, []);

  return (
    <section className="relative h-[560px]" id="beranda">
      {/* Image Slider */}
      {bannerImages.map((image, index) => (
        <img
          key={index}
          src={image}
          alt={`Banner ${index + 1}`}
          className={`absolute inset-0 w-full h-full object-cover transition-opacity duration-1000 ${currentIndex === index ? "opacity-100" : "opacity-0"
            }`}
        />
      ))}
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>
      <div className="relative z-10 flex flex-col justify-center items-center h-full px-6 md:px-4 max-w-container mx-auto">
        <article className="flex flex-col gap-6 col-span-1 sm:col-span-6 md:col-span-4 items-center">
          <h1 className="text-white font-bold text-xl lg:text-[32px] tracking-[0.005em] leading-[140%] lg:leading-[120%] max-w-3xl text-center">
            Konsultan, Manajemen Program CSR dan Social Impact Investment
          </h1>
          <p className="text-white text-base font-medium lg:text-[16px] tracking-[0.005em] leading-[140%] lg:leading-[120%] max-w-3xl text-center">
            Melalui spirit gotong royong melangkah bersama untuk kebaikan, kami hadir untuk menciptakan Social Impact Investment yang positif dan berkelanjutan.
          </p>

          <div className="flex flex-row col-span-2 gap-4">
            <NavLink to={"/about"} title="Tentang Kami" aria-label="Tentang Kami">
              <Button
                btnType="transparent"
                customStyle="rounded-full">
                <div className="montserrat not-italic font-bold leading-[22px] text-center text-sm tracking-[0.005em] px-2.5">
                  Tentang Kami
                </div>
              </Button>
            </NavLink>

            <NavLink to={"/contact-us"} title="Hubungi Kami" aria-label="Hubungi Kami">
              <Button
                btnType="transparent"
                customStyle="rounded-full">
                <div className="montserrat not-italic font-bold leading-[22px] text-center text-sm tracking-[0.005em] px-2.5">
                  Hubungi Kami
                </div>
              </Button>
            </NavLink>
          </div>

        </article>
      </div>

      {/* Navigation Dots */}
      <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex gap-2">
        {bannerImages.map((_, index) => (
          <div
            key={index}
            onClick={() => setCurrentIndex(index)}
            className={`w-3 h-3 rounded-full cursor-pointer transition-colors ${currentIndex === index ? "bg-primary" : "bg-white"
              }`}
          ></div>
        ))}
      </div>
    </section>
  );
}

export default SectionBanner;
