const initialState = {
    accessToken: null,
    tokenType: null,
    isLoading: false,
    isFulfilled: false,
    isRejected: false,
};

const auth = (state = initialState, action) => {
    switch (action.type) {
        case 'POST_LOGIN_PENDING':
            return {
                ...state,
                isLoading: true,
                isFulfilled: false,
                isRejected: false,
            };
        case 'POST_LOGIN_REJECTED':
            return {
                ...state,
                isLoading: false,
                isRejected: true,
            };
        case 'POST_LOGIN_FULFILLED':
            return {
                ...state,
                isLoading: false,
                isFulfilled: true,
                error: '',
                accessToken: action?.payload?.data?.data?.accessToken??null,
                tokenType: action?.payload?.data?.data?.tokenType??null,
            };
        case 'POST_REGISTER_PENDING':
            return {
                ...state,
                isLoading: true,
                isFulfilled: false,
                isRejected: false,
            };
        case 'POST_REGISTER_REJECTED':
            return {
                ...state,
                isLoading: false,
                isRejected: true,
            };
        case 'POST_REGISTER_FULFILLED':
            return {
                ...state,
                isLoading: false,
                isFulfilled: true,
                error: '',
                accessToken: action?.payload?.data?.data?.accessToken??null,
                tokenType: action?.payload?.data?.data?.tokenType??null,
            };
        default:
            return state;
    }
}

export default auth
