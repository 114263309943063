import React from 'react';
import DonationNews from './Components/News';

function NewsDetail({ title}) {
    return (
        <div className="news-detail">
            <div className="text-xs text-default">dadad</div>
            <div className="text-lg text-dark montserrat font-bold mt-1 mb-1">{title}</div>
            <p className="text-sm text-default">dada</p>
        </div>
    );
}

export default NewsDetail;
