import { mdiContentCopy, mdiTrayArrowUp, mdiImage, mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useEffect, useRef, useState } from "react";
import Card from "../../components/Card";
import { useNavigate, useLocation } from "react-router-dom";
import Countdown from "react-countdown";
import TabTitle from "../../utils/TabTitle";
import PanduanPembayaran from "../../utils/PanduanPembayaran";
import Button from "../../components/Button";
import ModalAlert from "../../components/ModalAlert";
import { apiUpload } from "../../api/apiUpload";
import Modal from "../../components/Modal";
import ProgessUpload from "../../components/FormPage/ProgressUpload";
import { isDesktop, isMobile } from "react-device-detect";
import NavBar from "../../components/LandingPage/Navbar";
import Footer from "../../components/LandingPage/Footer";
import ModalDialog from "../../components/ModalDialog";
import progressStatus from "../../assets/images/status/progress-upload.png";
import failedStatus from "../../assets/images/status/failed-upload.png";
import successStatus from "../../assets/images/status/success-upload.png";
import api from "../../api";

const FormPaymentPage = () => {
  TabTitle("Pembayaran - Langkah Baik");
  let navigate = useNavigate();
  let { state } = useLocation();
  const inputFileRef = useRef();
  const [dragActive, setDragActive] = useState(false);
  const [tempImgFile, setTempImgFile] = useState(null);
  const [imgFile, setImgFile] = useState(null);
  const [dataImgURL, setDataImgURL] = useState(null);
  const [modalOpen, setModalOpen] = useState({ status: false, type: "" });
  const [modalAlert, setModalAlert] = useState({
    alertTitle: "",
    alertMessage: "",
  });
  const [modalDialog, setModalDialog] = useState({
    alertInfo: "",
    alertImage: null,
  });
  const [statusUpload, setStatusUpload] = useState("");
  const [progressUpload, setProgressUpload] = useState(0);
  const [idReservation, setIdReservation] = useState(null);
  const [idPaymentMethod, setIdPaymentMethod] = useState(null);
  const [selectedBank, setSelectedBank] = useState("");
  const [panduan, setPanduan] = useState([]);
  const [dateFinished, setDateFinished] = useState(null);
  const [isSuccessPaid, setIsSuccessPaid] = useState(false);
  const [isCountdown, setIsCountdown] = useState(false);
  const [bankVirtualAccount, setBankVirtualAccount] = useState("");
  const [accountName, setAccountName] = useState("");
  const [formatTotalPayment, setFormatTotalPayment] = useState("");
  const [totalPayment, setTotalPayment] = useState(null);
  const [selectedGuide, setSelectedGuide] = useState(0);
  const [redirectUrls, setRedirectUrls] = useState([]);
  const [paymentWindowClosed, setPaymentWindowClosed] = useState(null);
  const [isExpired, setIsExpired] = useState(false);

  // convert to formatted DD/MMMM/YYYY HH:MM
  const dateFormat = (dateInput) => {
    let date = new Date(dateInput).toLocaleString("id-ID", {
      weekday: "long",
      day: "2-digit",
      month: "long",
      year: "numeric",
    });
    let time = new Date(dateInput).toLocaleTimeString([], {
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
    });
    return date + " " + time;
  };

  const numberWithCommas = (intValue) => {
    return intValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const copyToClipboard = async (value) => {
    try {
      await navigator.clipboard.writeText(value);
    } catch (err) {
      setModalAlert({
        alertTitle: "Gagal",
        alertMessage: "Gagal menyalin kode/text.",
      });
      setModalOpen({ status: true, type: "alert" });
    }
  };

  const countdownRenderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      setIsCountdown(false);
      return <span>Countdown complete!</span>;
    } else {
      return (
        <span>
          {(hours < 10 ? "0" : "") + hours}:
          {(minutes < 10 ? "0" : "") + minutes}:
          {(seconds < 10 ? "0" : "") + seconds}
        </span>
      );
    }
  };

  const refreshPaymentStatus = async () => {
    console.log("refresh");

    try {
      const {data} = await api.get(`/v1/donation/show/${state.api.id}`);

      const expirationDate = new Date(data.data.expired_date);
      const currentDate = new Date();

      setIsExpired(expirationDate < currentDate);

      const statusInfo = data.data.is_paid ? "Pembayaran Berhasil. Terima Kasih telah berdonasi." : "Menunggu Pembayaran";
      const statusImg =  data.data.is_paid ? successStatus : progressStatus;

      console.log(`refresh ${statusInfo}- ${data.data}`);
      setModalDialog({
        alertInfo: isExpired ? "Pembayaran Gagal atau Kadaluarsa" : statusInfo,
        alertImage: isExpired ? failedStatus : statusImg
      });

      setModalOpen({ status: true, type: "payment" });

    } catch ({response}) {
      setModalAlert({
        alertTitle: `Galat ${response.status}`,
        alertMessage:
           response.status === 0
            ? "Tidak ada koneksi internet. Silahkan coba lagi setelah koneksi internet stabil."
            : response.data.error.message,
      });
    }

  };

  const openPaymentWindow = () => {
    let link = "";
    if (isDesktop) {
      link = redirectUrls.desktopWeb;
    } else if (isMobile) {
      link = redirectUrls.mobileWeb;
    } else {
      link = redirectUrls.mobileDeeplink;
    }
    if (link === null) {
      link =
        redirectUrls.mobileDeeplink ||
        redirectUrls.mobileWeb ||
        redirectUrls.desktopWeb;
    }
    const win = window.open(link, "Pembayaran");
    const timer = setInterval(() => {
      if (win.closed) {
        clearInterval(timer);
        setPaymentWindowClosed(true);
      }
    });
  };

  const postUploadFileReceipt = async () => {
    const formData = new FormData();
    formData.append("paymentMethodId", idPaymentMethod);
    formData.append("image", imgFile);
    try {
      setModalOpen({ status: false, type: "" });
      setStatusUpload("uploading");
      await apiUpload
        .post(
          `/v1/reservations/${idReservation}/upload-payment-proof`,
          formData,
          {
            onUploadProgress: (progressEvent) => {
              setProgressUpload(
                Math.round((progressEvent.loaded / progressEvent.total) * 100)
              );
            },
          }
        )
        .then(({ response }) => {
          setIsSuccessPaid(true);
          setStatusUpload("success");
        });
    } catch ({ response }) {
      setIsSuccessPaid(false);
      setStatusUpload("failed");
      const errorMsg = response.data;
      if (errorMsg.error.errors[0].message) {
        alert(errorMsg.error.errors[0].message);
      }
    }
  };

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files, e.dataTransfer.files[0]);
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files, e.target.files[0]);
    }
  };

  const handleFile = (files, file) => {
    if (files && file) {
      if (file.type.match(/image\/(jpg|jpeg|png|pjp|pjpeg|jfif)/i)) {
        if (file.size < 5000000) {
          setTempImgFile(files && file);
          setImgFile(file);
        } else {
          setModalAlert({
            alertTitle: "Gagal",
            alertMessage: "Mohon pilih file gambar dengan ukuran maksimal 5MB",
          });
          setModalOpen({ status: true, type: "alert" });
          return;
        }
      } else {
        setModalAlert({
          alertTitle: "Gagal",
          alertMessage: "Mohon pilih file gambar dengan format JPG/JPEG/PNG",
        });
        setModalOpen({ status: true, type: "alert" });
        return;
      }
    } else {
      setModalAlert({
        alertTitle: "Gagal",
        alertMessage: "Mohon pilih file terlebih dahulu",
      });
      setModalOpen({ status: true, type: "alert" });
      return;
    }
  };

  useEffect(() => {
    let fileReader,
      isCancel = false;
    if (tempImgFile) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setDataImgURL(result);
        }
      };
      fileReader.readAsDataURL(tempImgFile);
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [tempImgFile]);

  useEffect(() => {
    if (paymentWindowClosed === true) {
      setIsSuccessPaid(true);
      setIsCountdown(true);
    }
  }, [paymentWindowClosed]);

  useEffect(() => {
    if (!!state) {
      setIdReservation(state.id);
      setIdPaymentMethod(state.paymentId);
      setIsSuccessPaid(false);
      setBankVirtualAccount(state.api.accountNumber);
      setAccountName(state.api.accountName);
      setFormatTotalPayment(numberWithCommas(state.api.payAmount));
      setTotalPayment(state.api.payAmount);
      setDateFinished(new Date(state.api.expiredAt));
      setIsCountdown(true);
      setRedirectUrls(state.api.redirect ?? []);

      setSelectedBank(state.form.name);
      if (state.form.name === "Transfer BCA" && state.api.accountName === "") {
        setBankVirtualAccount("6821362498");
      }
      PanduanPembayaran.forEach((item) => {
        if (item.label === state.form.name) {
          setPanduan(item.type);
        }
      });
    } else {
      //   navigate("/form", {replace: true});
    }
  }, []);

  return (
    <>
      {statusUpload !== "" && (
        <ProgessUpload
          status={statusUpload}
          setStatus={setStatusUpload}
          progress={progressUpload}
        />
      )}
      <ModalAlert
        isOpen={modalOpen.status && modalOpen.type === "alert"}
        closeModal={() => {
          setModalOpen({ status: false, type: "" });
          setModalAlert({ alertTitle: "", alertMessage: "" });
        }}
        alertTitle={modalAlert.alertTitle}
        alertMessage={modalAlert.alertMessage}
      />
       <ModalDialog
        isOpen={modalOpen.status && modalOpen.type === "payment"}
        closeModal={() => {
          setModalOpen({ status: false, type: "" });
          setModalDialog({ alertInfo: "", alertImage: null });
        }}
        alertInfo={modalDialog.alertInfo}
        alertImage={modalDialog.alertImage}
      />
      <Modal
        isOpen={modalOpen.status && modalOpen.type === "upload"}
        closeModal={() => {
          setModalOpen({ status: false, type: "" });
          setDataImgURL(null);
          setTempImgFile(null);
          setImgFile(null);
        }}
      >
        <div className="p-6">
          <h1 className="text-xl montserrat mb-6 text-dark font-bold">
            Upload Bukti Pembayaran
          </h1>
          {dataImgURL ? (
            <div
              className="bg-neutral p-4 rounded-lg w-full flex justify-center relative"
              onDragEnter={handleDrag}
            >
              <div className="relative min-h-0 h-[360px]">
                <button
                  className="z-[1] absolute -top-2 -right-2"
                  onClick={() => {
                    setDataImgURL(null);
                    setTempImgFile(null);
                    setImgFile(null);
                  }}
                >
                  <Icon
                    path={mdiClose}
                    size={1}
                    className="text-white bg-default rounded-full p-1"
                  />
                </button>
                <img
                  src={dataImgURL}
                  alt="preview"
                  className="h-full object-contain"
                />
              </div>
              {dragActive && (
                <div
                  className="absolute inset-0 bg-white text-center flex flex-col justify-center z-10"
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={handleDrop}
                >
                  <div className="flex justify-center">
                    <Icon path={mdiImage} size={3} className="text-primary" />
                  </div>
                  <div className="text-base text-dark text-center">
                    Drag and drop here to upload your image
                  </div>
                  <div className="text-sm text-default mb-8 text-center">
                    You only can upload jpg or png file (max 5 MB)
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="border-dashed border text-primary rounded-lg w-full">
              <form
                id="form"
                className="relative p-11"
                onDragEnter={handleDrag}
                onSubmit={(e) => e.preventDefault()}
              >
                <div className="flex justify-center">
                  <div className="border border-dashed border-primary p-0.5 rounded-lg">
                    <Icon path={mdiImage} size={3} className="text-primary" />
                  </div>
                </div>
                <div className="text-base text-dark text-center">
                  Drag and drop here to upload your image
                </div>
                <div className="text-sm text-default mb-8 text-center">
                  You only can upload jpg or png file (max 5 MB)
                </div>
                <div className="flex justify-center">
                  <input
                    className="hidden"
                    type="file"
                    name="upload-bukti-pembayaran"
                    id="upload-bukti-pembayaran"
                    title="Upload Bukti Pembayaran"
                    placeholder="Upload Bukti Pembayaran"
                    accept="image/jpg, image/jpeg, image/png"
                    onChange={handleChange}
                    ref={inputFileRef}
                  />
                  <label htmlFor="upload-bukti-pembayaran">
                    <Button
                      btnType={"secondary"}
                      children={"Or select file"}
                      click={() => inputFileRef.current.click()}
                    />
                  </label>
                </div>
                {dragActive && (
                  <div
                    className="absolute inset-0 bg-white bg-opacity-80"
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                  ></div>
                )}
              </form>
            </div>
          )}
          <div className="mt-6 flex justify-between">
            <div>
              <Button
                customStyle=" rounded-full"
                click={() => {
                  setModalOpen({ status: false, type: "" });
                  setDataImgURL(null);
                  setTempImgFile(null);
                  setImgFile(null);
                }}
                btnType={"secondary"}
              >
                <div className="px-2 not-italic font-bold text-base leading-[140%] text-center tracking-[0.005em]">
                  Cancel
                </div>
              </Button>
            </div>
            <div>
              <Button
                customStyle=" rounded-full"
                btnType={"primary"}
                click={() => postUploadFileReceipt()}
                isDisabled={!imgFile && !dataImgURL && !tempImgFile}
              >
                <div className="px-2 not-italic font-bold text-base leading-[140%] text-center tracking-[0.005em]">
                  Upload
                </div>
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <header id="header" className="top-0 left-0 right-0 fixed z-50">
        <nav>
          <NavBar />
        </nav>
      </header>
      <main id="main" className="pt-[89px] md:pt-[121px]">
        <section className="grid grid-cols-1 sm:grid-cols-12 mx-auto px-4 xl:px-0 lg:max-w-7xl relative gap-10 py-4 md:py-12">
          <div className="hidden md:block md:col-span-2"></div>
          <div className="col-span-1 sm:col-span-12 md:col-span-3 flex flex-col gap-4">
            <div className="flex flex-col gap-[7px]">
              {isCountdown ? (
                isSuccessPaid ? (
                  <>
                    <span className="not-italic montserrat font-bold text-base leading-[22px] tracking-[0.005em] text-black">
                      Pembayaran Berhasil
                    </span>
                  </>
                ) : (
                  <>
                    <span className="not-italic montserrat font-bold text-base leading-[22px] tracking-[0.005em] text-black">
                      Selesaikan pembayaran dalam
                    </span>
                    <div className="not-italic montserrat font-bold text-2xl leading-[34px] tracking-[0.005em] text-dangerDefault">
                      {dateFinished && (
                        <Countdown
                          date={dateFinished}
                          renderer={countdownRenderer}
                        />
                      )}
                    </div>
                  </>
                )
              ) : isSuccessPaid ? (
                <>
                  <span className="not-italic montserrat font-bold text-base leading-[22px] tracking-[0.005em] text-black">
                    Pembayaran Berhasil
                  </span>
                </>
              ) : (
                <>
                  <span className="not-italic montserrat font-bold text-base leading-[22px] tracking-[0.005em] text-black">
                    Pembayaran Gagal
                  </span>
                  <div className="not-italic montserrat font-bold text-2xl leading-[34px] tracking-[0.005em] text-dangerDefault">
                    Batas Waktu Tunggu Habis, Silahkan Coba Lagi
                  </div>
                </>
              )}
            </div>
            <div className="flex flex-col gap-[7px]">
              <span className="not-italic font-normal text-base leading-[22px] tracking-[0.005em] text-black">
                Batas Akhir Pembayaran
              </span>
              <div className="not-italic font-bold text-base leading-[22px] text-dark">
                {dateFormat(dateFinished)}
              </div>
            </div>
            {selectedBank.includes("Transfer") && (
              <Button
                customStyle=" rounded-full"
                click={() => setModalOpen({ status: true, type: "upload" })}
              >
                <div className="flex flex-row gap-1.5 px-1.5 items-center">
                  <div>
                    <Icon
                      path={mdiTrayArrowUp}
                      size={0.75}
                      className="text-white"
                    />
                  </div>
                  <div className="not-italic font-bold text-sm leading-[140%] tracking-[0.005em] text-white">
                    Pilih File Bukti Pembayaran
                  </div>
                </div>
              </Button>
            )}
          </div>
          <div className="col-span-1 sm:col-span-12 md:col-span-5">
            <Card
              heading={`${selectedBank === "Transfer BCA"
                  ? ""
                  : selectedBank.includes("BNI") ||
                    selectedBank.includes("BRI") ||
                    selectedBank.includes("Mandiri") ||
                    selectedBank.includes("Permata")
                    ? "Virtual Account "
                    : ""
                } ${selectedBank}`}
              customHeadingStyle={
                "-mx-4 px-4 pb-4 border-b border-b-neutralBright"
              }
            >
              <dl className="flex flex-col gap-4">
                {bankVirtualAccount ? (
                  <div className="flex flex-row flex-wrap items-center justify-between">
                    <div className="flex flex-col gap-1">
                      <dt className="not-italic font-normal text-xs leading-[17px] tracking-[0.005em] text-dark">
                        {selectedBank === "Transfer BCA"
                          ? "Nomor Rekening"
                          : "Nomor Virtual Account"}
                      </dt>
                      <dd className="not-italic font-bold text-base leading-[22px] tracking-[0.005em] text-dark">
                        {bankVirtualAccount}
                      </dd>
                    </div>
                    <button
                      className="flex flex-row text-primary items-center gap-2"
                      onClick={() => copyToClipboard(bankVirtualAccount)}
                    >
                      <span className="not-italic font-bold text-base leading-[22px] tracking-[0.005em]">
                        Salin
                      </span>
                      <div>
                        <Icon path={mdiContentCopy} size={1} />
                      </div>
                    </button>
                  </div>
                ) : null}
                {accountName ? (
                  <div className="flex flex-row flex-wrap items-center justify-between">
                    <div className="flex flex-col gap-1">
                      <dt className="not-italic font-normal text-xs leading-[17px] tracking-[0.005em] text-dark">
                        Nama Pemilik Rekening
                      </dt>
                      <dd className="not-italic font-bold text-base leading-[22px] tracking-[0.005em] text-dark">
                        {accountName}
                      </dd>
                    </div>
                    <button
                      className="flex flex-row text-primary items-center gap-2"
                      onClick={() => copyToClipboard(accountName)}
                    >
                      <span className="not-italic font-bold text-base leading-[22px] tracking-[0.005em]">
                        Salin
                      </span>
                      <div>
                        <Icon path={mdiContentCopy} size={1} />
                      </div>
                    </button>
                  </div>
                ) : (
                  ""
                )}
                <div className="flex flex-row flex-wrap items-center justify-between">
                  <div className="flex flex-col gap-1">
                    <dt className="not-italic font-normal text-xs leading-[17px] tracking-[0.005em] text-dark">
                      Total Pembayaran
                    </dt>
                    <dd className="not-italic font-bold text-base leading-[22px] tracking-[0.005em] text-dark">
                      Rp {formatTotalPayment}
                    </dd>
                  </div>
                  <button
                    className="flex flex-row text-primary items-center gap-2"
                    onClick={() => copyToClipboard(totalPayment)}
                  >
                    <span className="not-italic font-bold text-base leading-[22px] tracking-[0.005em]">
                      Salin
                    </span>
                    <div>
                      <Icon path={mdiContentCopy} size={1} />
                    </div>
                  </button>
                </div>
                <Button
                  type="button"
                  click={() => refreshPaymentStatus()}
                  btnType="primary"
                >
                  <div className="w-full text-center">
                    Cek Status Pembayaran
                  </div>
                </Button>
                {isDesktop && selectedBank === "ShopeePay" && (
                  <div>
                    <div className="not-italic font-bold text-base leading-[22px] text-dark">
                      Gunakan perangkat mobile untuk melanjutkan
                      pembayaran.
                    </div>
                  </div>
                )}
              </dl>
            </Card>
          </div>
        </section>
      </main>

      {panduan.length > 0 ? (
        <section className="grid grid-cols-1 sm:grid-cols-12 mx-auto px-4 xl:px-0 lg:max-w-7xl relative gap-4 py-4 md:py-12">
          <div className="hidden md:block md:col-span-2"></div>
          <div className="col-span-12 md:col-span-10">
            <span className="not-italic montserrat font-bold text-base leading-[22px] tracking-[0.005em] text-black">
              Panduan Pembayaran
            </span>
          </div>
          <div className="hidden md:block md:col-span-2"></div>
          <div className="col-span-12 md:col-span-3 flex flex-col gap-4">
            {panduan?.map((list, index) => (
              <button
                key={"methodPay" + index}
                className={
                  (selectedGuide === index
                    ? "ring-1 ring-neutralBright px-4 "
                    : "") + " py-2 rounded-2xl gap-2 flex flex-start"
                }
                onClick={() => setSelectedGuide(index)}
              >
                {list.name}
              </button>
            ))}
          </div>
          <div className="col-span-12 md:col-span-5">
            <Card heading={panduan[selectedGuide]?.name}>
              <ol className="list-decimal w-full px-4">
                {panduan[selectedGuide]?.steps.map((list, index) => (
                  <li key={"desc" + index}>{list}</li>
                ))}
              </ol>
            </Card>
          </div>
        </section>
      ) : null}
      <footer id="footer">
        <Footer />
      </footer>
    </>
  );
};

export default FormPaymentPage;
