import React from 'react';

const ModalForm = ({ onClose, children }) => {
  return (
    <div className="modal fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg p-6 w-full mx-4 lg:w-1/2"> {/* Added margin for mobile */}
        {children} {/* Render children here */}
      </div>
    </div>
  );
};

export default ModalForm;