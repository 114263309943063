import {mdiThumbUpOutline} from "@mdi/js";
import Icon from "@mdi/react";
import api from "../../../api";
import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import {Swiper, SwiperSlide} from "swiper/react";
import blog1 from "../../../assets/images/articles/blog1.png";
import blog2 from "../../../assets/images/articles/blog2.jpg";
import blog3 from "../../../assets/images/articles/blog3.jpeg";
import blog4 from "../../../assets/images/articles/blog4.jpg";
import blog5 from "../../../assets/images/articles/blog5.jpg";
import blog6 from "../../../assets/images/articles/blog6.jpg";
import blog7 from "../../../assets/images/articles/blog7.jfif";
import blog8 from "../../../assets/images/articles/blog8.jpg";
import blog9 from "../../../assets/images/articles/blog9.jpg";
import blog10 from "../../../assets/images/articles/blog10.jfif";
import blog11 from "../../../assets/images/articles/blog11.jpg";
import blog12 from "../../../assets/images/articles/blog12.jfif";
import Button from "../../Button";

function SectionBlog() {
  const [blog, setBlog] = useState([]);

  useEffect(() => {
      getBlogs();
  }, []);

  const getBlogs = async () => {
      try {
          const response = await api.get("/v1/blogs-donation?limit=5");
          console.log(response.data.data);

          setBlog(response.data.data.items);
      } catch ({ response }) {
          console.log(response);
      }
  };

  return (
    <section
      id="blog-artikel-pilihan"
      className="py-16 border-b-[1px] border-b-bright"
    >
      <article className="max-w-container mx-auto px-6 md:px-4">
        <div className="font-bold mb-[40px] flex justify-start md:justify-center sm:text-[32px] leading-[140%] tracking-wider montserrat">
          Artikel Pilihan untuk Anda
        </div>
        <Swiper
          breakpoints={{
            640: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          className="flex items-center"
        >
           {blog.map((item, index) => (
            <SwiperSlide>
            <div className="flex flex-col">
              <img
                src={item.image?.resourceUrl || ""}
                alt={item.title}
                className="w-full h-[250px] lg:h-[300px] rounded-3xl object-cover"
              />
              <div className="my-4 text-dark leading-[140%] tracking-wider font-bold text-base">
                {item.title}
              </div>
              <a
                href={`/blog/detail/${item.slug}`}
                className="text-primary text-sm"
              >
                Baca Selengkapnya
              </a>
            </div>
          </SwiperSlide>
            ))}
        </Swiper>
        <div className="flex justify-center mt-16">
          <a href="/blog">
            <Button btnType="secondary" customStyle=" rounded-full">
              <div className="px-5 py-1 font-bold not-italic text-base leading-[140%] tracking-[0.005em] text-dark">
                Lihat Semua Artikel
              </div>
            </Button>
          </a>
        </div>
      </article>
    </section>
  );
}

export default SectionBlog;
