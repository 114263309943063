import {Swiper, SwiperSlide} from "swiper/react";
import {Mousewheel, Navigation} from "swiper";
import "swiper/css";
import {Menu} from "@headlessui/react";
import {mdiChevronDown, mdiChevronUp} from "@mdi/js";
import Icon from "@mdi/react";
import React, {useEffect, useRef, useState} from "react";
import Button from "./Button";

const TimePicker = ({value, setValue}) => {
  const [selectedHour, setSelectedHour] = useState(0);
  const [selectedMinute, setSelectedMinute] = useState(0);
  const [isValid, setIsValid] = useState(false);

  // set initial value
  const LIST_HOURS = Array.from(Array(24).keys());
  const LIST_MINUTES = Array.from(Array(60).keys());

  // refs
  const sliderHourRef = useRef(null);
  const sliderMinuteRef = useRef(null);

  const getCurrentTime = () => {
    const date = new Date();
    const currentHour = date.getHours();
    const currentMinute = date.getMinutes();
    setSelectedHour(currentHour);
    setSelectedMinute(currentMinute);
    sliderHourRef.current.swiper.slideTo(currentHour + 1);
    sliderMinuteRef.current.swiper.slideTo(currentMinute + 1);
  };

  const handleSubmit = () => {
    if (isValid)
      setValue(
        `${selectedHour < 10 ? `0${selectedHour}` : selectedHour}:${
          selectedMinute < 10 ? `0${selectedMinute}` : selectedMinute
        }`
      );
  };

  const handleReset = () => {
    getCurrentTime();
    setValue("");
  };

  useEffect(() => {
    if (selectedHour >= 7 && selectedHour <= 19) {
      if (selectedHour === 19 && selectedMinute >= 1) {
        setIsValid(false);
      } else {
        setIsValid(true);
      }
    } else {
      setIsValid(false);
    }
  }, [selectedHour, selectedMinute]);

  useEffect(() => {
    if (value) {
      const activeHour = parseInt(value.split(":")[0], 10);
      const activeMinute = parseInt(value.split(":")[1], 10);
      setSelectedHour(activeHour);
      setSelectedMinute(activeMinute);
      sliderHourRef.current.swiper.slideTo(activeHour + 1);
      sliderMinuteRef.current.swiper.slideTo(activeMinute + 1);
    } else {
      getCurrentTime();
    }
  }, []);

  return (
    <div className="flex flex-col gap-2 min-w-[210px] max-w-full overflow-y-hidden">
      <div className="p-3 not-italic font-normal font-inter text-sm leading-5 tracking-default text-neutralDefault">
        Jam Pengerjaan
      </div>
      <div className="flex flex-col gap-4 pb-2">
        <div className="flex flex-row">
          <div className="basis-1/2 font-workSans flex items-center justify-center not-italic font-semibold text-xs leading-4 tracking-[0.03em] uppercase text-neutralDark">
            JAM
          </div>
          <div className="basis-1/2 font-workSans flex items-center justify-center not-italic font-semibold text-xs leading-4 tracking-[0.03em] uppercase text-neutralDark">
            MENIT
          </div>
        </div>
        <div className="flex flex-row">
          <div className="basis-1/2 flex items-center justify-center">
            <button
              type="button"
              className="px-10 py-0.5 hours-timepicker-button-prev"
            >
              <Icon path={mdiChevronUp} size={1} />
            </button>
          </div>
          <div className="basis-1/2 flex items-center justify-center">
            <button
              type="button"
              className="px-10 py-0.5 minutes-timepicker-button-prev"
            >
              <Icon path={mdiChevronUp} size={1} />
            </button>
          </div>
        </div>
        <div className="flex flex-row border-t border-t-primaryLight border-b border-b-primaryLight">
          <div className="basis-1/2 flex items-center justify-center select-none not-italic font-normal text-3xl leading-9 text-dark max-h-[80px]">
            <Swiper
              direction="vertical"
              slidesPerView={1}
              loop
              centeredSlides
              mousewheel
              modules={[Mousewheel, Navigation]}
              speed={150}
              navigation={{
                nextEl: ".hours-timepicker-button-next",
                prevEl: ".hours-timepicker-button-prev",
              }}
              className="h-full w-full"
              ref={sliderHourRef}
              onSlideChange={(e) => {
                setSelectedHour(e.realIndex);
              }}
            >
              {LIST_HOURS.map((hour) => (
                <SwiperSlide key={`index${hour}`}>
                  <div className="h-full flex items-center justify-center">
                    {hour < 10 ? `0${hour}` : hour}
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="basis-1/2 flex items-center justify-center select-none not-italic font-normal text-3xl leading-9 text-dark max-h-[80px]">
            <Swiper
              direction="vertical"
              slidesPerView={1}
              loop
              centeredSlides
              mousewheel
              modules={[Mousewheel, Navigation]}
              speed={150}
              navigation={{
                nextEl: ".minutes-timepicker-button-next",
                prevEl: ".minutes-timepicker-button-prev",
              }}
              className="h-full w-full"
              ref={sliderMinuteRef}
              onSlideChange={(e) => {
                setSelectedMinute(e.realIndex);
              }}
            >
              {LIST_MINUTES.map((minute) => (
                <SwiperSlide key={`index${minute}`}>
                  <div className="h-full flex items-center justify-center">
                    {minute < 10 ? `0${minute}` : minute}
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
        <div className="flex flex-row">
          <div className="basis-1/2 flex items-center justify-center">
            <button
              type="button"
              className="px-10 py-0.5 hours-timepicker-button-next"
            >
              <Icon path={mdiChevronDown} size={1} />
            </button>
          </div>
          <div className="basis-1/2 flex items-center justify-center">
            <button
              type="button"
              className="px-10 py-0.5 minutes-timepicker-button-next"
            >
              <Icon path={mdiChevronDown} size={1} />
            </button>
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-end gap-4 p-3 border-t border-t-primaryBright">
        <Button
          btnType="secondary"
          customStyle=" rounded-full"
          click={handleReset}
        >
          <div className="not-italic font-bold text-base font-inter leading-default text-center tracking-default text-neutralDark">
            Reset
          </div>
        </Button>
        {isValid ? (
          <Menu.Item as="div">
            <Button
              btnType="primary"
              customStyle=" rounded-full"
              click={handleSubmit}
              isDisabled={!isValid}
            >
              <div className="not-italic font-bold text-base font-inter leading-default text-center tracking-default text-white">
                Apply
              </div>
            </Button>
          </Menu.Item>
        ) : (
          <Button
            btnType="primary"
            customStyle=" rounded-full"
            click={handleSubmit}
            isDisabled={!isValid}
          >
            <div className="not-italic font-bold text-base font-inter leading-default text-center tracking-default text-white">
              Apply
            </div>
          </Button>
        )}
      </div>
    </div>
  );
};

export default TimePicker;
