import React from "react";
import {Navigate, Outlet} from "react-router-dom";
import {connect, useSelector} from 'react-redux';

const accessToken = localStorage.getItem('accessToken');

const PrivateLayout = (props) => {
    const auth = useSelector(state => state.auth);
    const profile = useSelector(state => state.profile);

  return (
      auth.accessToken?
    <>
      <main id="main">
        <Outlet />
      </main>
    </> :
    <Navigate to="/login" />
  );
};

// export default PrivateLayout;
export default connect(null)(PrivateLayout)
