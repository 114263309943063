import React from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import banner1 from "../../assets/images/banner/promo-one-home-one-hope.png";
import banner2 from "../../assets/images/banner/promo-one-corp-many-hope.png";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import {EffectFade, Pagination, Autoplay} from "swiper";

const SliderPromo = () => {
  return (
    <div className="flex flex-col gap-2 items-start">
      <div className="w-full">
        <Swiper
          slidesPerView={1}
          spaceBetween={10}
          effect={"fade"}
          autoplay={{
            delay: 10000,
            disableOnInteraction: false,
          }}
          modules={[EffectFade, Pagination, Autoplay]}
          pagination={{
            el: ".promo-pagination",
            clickable: true,
            renderBullet: function (index, className) {
              return '<span class="' + className + ' max-h-1"></span>';
            },
          }}
          loop={true}
        >
          <SwiperSlide>
            <a href="https://wa.me/6281297984971">
              <img
                src={banner1}
                alt="Promo One Home One Hope"
                className="w-full"
              />
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href="https://wa.me/6281297984971">
              <img
                src={banner2}
                alt="Promo One Corp Many Hope"
                className="w-full"
              />
            </a>
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="promo-pagination block whitespace-nowrap opacity-50"></div>
    </div>
  );
};

export default SliderPromo;
