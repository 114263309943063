import {mdiMinusCircleOutline, mdiPlusCircleOutline} from "@mdi/js";
import {ReactComponent as Phone} from "../../assets/vectors/icons/phone-icon.svg";
import {ReactComponent as Clock} from "../../assets/vectors/icons/clock-icon.svg";
import {ReactComponent as Pencil} from "../../assets/vectors/icons/pencil-icon.svg";
import Icon from "@mdi/react";
import React from "react";
import TextField from "../TextField";
import Button from "../Button";
import {
  getStringAfterBrackets,
  getStringBetweenBrackets,
  isStringContainsBrackets,
  numberWithCommas,
} from "../../utils/Tools";

const ServiceOrder = ({data, minOrder, plusOrder, addNotes}) => {
  return (
    <div className="flex flex-col">
      <h3 className="montserrat not-italic font-bold text-2xl leading-8 tracking-[0.005em] text-primary">
        {data?.name}
      </h3>
      <p className="not-italic font-normal leading-5 text-default tracking-[0.005em] whitespace-pre-line">
        {data?.description}
      </p>
      {data?.services.map((layanan, layananIndex) => (
        <React.Fragment key={"items" + layanan.id}>
          {!!layanan.rate ? (
            <div className="flex flex-col gap-2">
              <h4 className="montserrat not-italic font-bold text-2xl leading-8 text-primary">
                {layananIndex !== 0
                  ? getStringBetweenBrackets(
                      data.services[layananIndex].name
                    ) !==
                    getStringBetweenBrackets(
                      data.services[layananIndex - 1].name
                    )
                    ? getStringBetweenBrackets(layanan.name)
                    : null
                  : getStringBetweenBrackets(layanan.name)}
              </h4>
              <div className="ring-1 ring-bright px-4 py-3 rounded-2xl flex flex-col gap-3">
                <div className="flex flex-row items-center justify-between">
                  <div className="flex flex-col gap-1 flex-grow">
                    <dt className="not-italic font-normal text-base leading-[22px] tracking-[0.005em] text-dark flex flex-row items-center gap-4">
                      <div>
                        {isStringContainsBrackets(layanan.name)
                          ? getStringAfterBrackets(layanan.name)
                          : layanan.name}
                      </div>
                    </dt>
                    <div className="flex flex-row gap-4 items-center">
                      <span className="flex flex-row items-center gap-1">
                        <div className="text-default flex flex-row items-center gap-1">
                          <Clock className="text-sm text-neutralBright" />
                          <span>1 Jam</span>
                        </div>
                      </span>
                      <dd className="not-italic font-bold text-sm leading-5 tracking-[0.005em] text-dark">
                        Rp {numberWithCommas(layanan.rate)}
                      </dd>
                    </div>
                  </div>
                  <div className="flex flex-row p-0 gap-4 items-center">
                    <button type="button" onClick={() => minOrder(layanan.id)}>
                      <Icon
                        path={mdiMinusCircleOutline}
                        size={1}
                        className="text-primary"
                      />
                    </button>
                    <div>{layanan.quantity}</div>
                    <button type="button" onClick={() => plusOrder(layanan.id)}>
                      <Icon
                        path={mdiPlusCircleOutline}
                        size={1}
                        className="text-primary"
                      />
                    </button>
                  </div>
                </div>
                <div className="w-full">
                  <TextField
                    type="text"
                    placeholder={"Notes..."}
                    value={layanan.note}
                    setValue={(e) => addNotes(layanan.id, e.target.value)}
                    iconLeft={<Pencil className="text-sm text-neutralBright" />}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="flex flex-col gap-2">
              <div></div>
              <div className="ring-1 ring-bright px-4 py-3 rounded-2xl flex flex-col gap-3">
                <div className="flex flex-row items-center justify-between">
                  <div className="flex flex-col gap-1 flex-grow">
                    <div className="not-italic font-normal text-base leading-[22px] tracking-[0.005em] text-dark flex flex-row items-center gap-4">
                      <dt>
                        {isStringContainsBrackets(layanan.name)
                          ? getStringAfterBrackets(layanan.name)
                          : layanan.name}
                      </dt>
                      <div className="text-default flex flex-row items-center gap-1">
                        <Clock className="text-sm text-neutralBright" />
                        <span>1 Jam</span>
                      </div>
                      <div className="flex flex-row gap-4 items-center flex-grow justify-end">
                        <span className="flex flex-row items-center gap-1"></span>
                        <dd className="not-italic font-bold text-sm leading-5 tracking-[0.005em] text-dark">
                          {/* if there is a price */}
                        </dd>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full">
                  <a href="https://wa.me/6281297984971">
                    <Button customStyle=" rounded-full w-full">
                      <div className="flex flex-row items-center justify-center gap-2 text-white w-full">
                        <Phone />
                        <div className="not-italic font-bold text-base leading-[22px] tracking-[0.005em]">
                          Hubungi Kami
                        </div>
                      </div>
                    </Button>
                  </a>
                </div>
              </div>
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default ServiceOrder;
