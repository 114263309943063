import React from "react";

const TextField = ({
  type = "text",
  refs,
  inputId,
  isRequired = false,
  label,
  inputName,
  customStyle = "",
  placeholder,
  value,
  iconLeft,
  setValue,
  helper = "",
  ...props
}) => {
  return (
    <>
      {label && (
        <div className="flex flex-row items-center gap-1.5" ref={refs}>
          <label htmlFor={inputId}>{label}</label>
          {isRequired && (
            <div className="w-1.5 h-1.5 rounded-full bg-red100"></div>
          )}
        </div>
      )}
      <div className="relative">
        <input
          type={type}
          className={
            (!!iconLeft ? "pl-10 " : "") +
            "outline-none border-none ring-1 ring-neutralBright placeholder:text-default text-default bg-neutralLight focus:bg-white rounded-2xl w-full" +
            customStyle
          }
          placeholder={placeholder}
          required={isRequired}
          name={inputName}
          id={inputId}
          value={value}
          onChange={setValue}
          {...props}
        />
        <div className="absolute top-0 left-0 bottom-0 flex items-center pl-2">
          {iconLeft}
        </div>
      </div>
      {!!helper && (
        <div className="not-italic font-normal text-sm leading-5 text-default">
          {helper}
        </div>
      )}
    </>
  );
};

export default TextField;
