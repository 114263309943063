import React from "react";
import {Outlet} from "react-router-dom";
import Footer from "../components/LandingPage/Footer";
import NavBar from "../components/LandingPage/Navbar";

const LandingPageLayout = () => {
  return (
    <>
      <header id="header" className="top-0 left-0 right-0 fixed z-50">
        <nav>
          <NavBar />
        </nav>
      </header>
      <main id="main" className="pt-[89px] md:pt-[110px]">
        <Outlet />
      </main>
      <footer id="footer">
        <Footer />
      </footer>
    </>
  );
};

export default LandingPageLayout;
