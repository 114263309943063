import React from "react";

const TextArea = ({
  inputId,
  inputName,
  isRequired = false,
  label,
  placeholder,
  value,
  setValue,
}) => {
  return (
    <>
      {label && (
        <div className="flex flex-row items-center gap-1.5">
          <label htmlFor={inputId}>{label}</label>
          {isRequired && (
            <div className="w-1.5 h-1.5 rounded-full bg-red100"></div>
          )}
        </div>
      )}
      <textarea
        className="outline-none border-none ring-1 ring-neutralBright placeholder:text-default text-default bg-neutralLight focus:bg-white rounded-2xl min-h-[170px]"
        placeholder={placeholder}
        required={isRequired}
        id={inputId}
        name={inputName}
        value={value}
        onChange={setValue}
      />
    </>
  );
};

export default TextArea;
