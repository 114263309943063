import axios from "axios";

export const apiUpload = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 10000,
  headers: {
    "Content-Type": "multipart/form-data",
    Accept: "application/json",
  },
});
