import {Menu} from "@headlessui/react";
import React from "react";

const DropdownList = ({target, setTarget, id, value}) => {
  return (
    <Menu.Item>
      {({active}) => (
        <input
          className={`${
            target.value === id
              ? "bg-primary text-white "
              : active
              ? "bg-bright text-dark cursor-pointer "
              : "bg-white text-dark "
          }flex flex-row items-center px-4 py-3 not-italic font-normal text-sm leading-5 text-left w-full`}
          type="button"
          value={value}
          onClick={() => setTarget({value: id, label: value})}
        />
      )}
    </Menu.Item>
  );
};

export default DropdownList;
