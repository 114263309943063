import model from '../../../assets/images/misc/about-us.jpg'

function SectionFoundation() {
    return (
        <section className="py-16 relative h-[480px]" id="foundation">
            <img src={model} alt="Banner Modal" className="absolute inset-0 w-full h-full object-cover" />
            <div className="absolute inset-0 bg-black bg-opacity-50"></div>
            <article className="relative z-10 grid grid-cols-3 gap-12 max-w-container mx-auto px-6 md:px-4">
                <div className="col-span-3 lg:col-span-2 flex flex-col gap-5">
                    <h2 className="font-bold text-[32px] leading-[130%] tracking-wider text-white montserrat">
                        <span className="underlined"><span className="text-white">Tentang Kami</span></span>
                    </h2>
                    <div>
                        <p className='text-white text-base font-medium lg:text-[16px] mt-4'>
                            Melalui spirit gotong royong melangkah bersama untuk kebaikan, kami hadir untuk menciptakan Social Impact Investment yang positif dan berkelanjutan.
                            Ada tiga layanan utama dari langkahbaik.id yaitu konsultasi CSR, Manajemen Program CSR dan Social Impact Investment.
                            Pengalaman yang berbeda melalui langkahbaik akan menjadi kontribusi positif bagi perusahaan dan pemangku kepentingannya.
                        </p>
                        <p className='text-white text-base font-medium lg:text-[16px] mt-4'>
                            Langkahbaik.id merupakan bagian dari <strong>Cleansheet</strong> yang misinya ingin terus memperluas kebermanfaatan.
                        </p>
                    </div>
                </div>
            </article>
        </section>

    );
}

export default SectionFoundation;