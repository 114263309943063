import React from "react";

const ServiceLoader = () => {
  return (
    <div className="px-4 py-3 flex flex-col gap-4 animate-pulse">
      <div className="bg-primaryLight rounded-full h-3 p-0"></div>
      <div className="flex flex-row gap-4">
        <div className="bg-primaryLight basis-3/12 rounded-full h-3 p-0"></div>
        <div className="bg-primaryLight basis-3/12 rounded-full h-3 p-0"></div>
      </div>
      <div className="bg-primaryLight rounded-full h-3 p-0"></div>
    </div>
  );
};

export default ServiceLoader;
