export const numberWithCommas = (intValue) => {
  return intValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

export const getStringBetweenBrackets = (string) => {
  let start = string.indexOf("[");
  let end = string.indexOf("]");
  return string.substring(start + 1, end);
};

export const getStringAfterBrackets = (string) => {
  let start = string.indexOf("] ");
  return string.substring(start + 2);
};

export const isStringContainsBrackets = (string) => {
  return string.includes("[") && string.includes("]");
};

export const delay = (time) => {
  return new Promise((res) => setTimeout(res, time));
};

export const combineDateTime = (date, time) => {
  return `${date}T${time}`;
};
