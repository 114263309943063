import TabTitle from "../../../utils/TabTitle";
import React from "react";
import cleansheet from "../../../assets/images/brands/cleansheet.png";
import indosiar from "../../../assets/images/brands/indosiar.png";
import sctv from "../../../assets/images/brands/sctv.png";
import yppp from "../../../assets/images/brands/yppp.png";
import baznas from "../../../assets/images/brands/baznas.png";
import ybm from "../../../assets/images/brands/ybm.png";
import paragon from "../../../assets/images/brands/paragon.png";
import kahf from "../../../assets/images/brands/kahf.png";
import alHurriyah from "../../../assets/images/brands/al-hurriyyah.png";
import wardah from "../../../assets/images/brands/wardah.png";
import biodef from "../../../assets/images/brands/biodef.jpg";
import semudahitu from "../../../assets/images/brands/semudahitu.png";
import bpi from "../../../assets/images/brands/bpi.png";
import majuindonesia from "../../../assets/images/brands/majuindonesia.png";
import ybmi from "../../../assets/images/brands/ybmi.png";
import tiptop from "../../../assets/images/brands/tiptop.png";

const PortofolioPage = () => {
  TabTitle("Partner - Langkah Baik");

  return (
    <section id="klien-kami" className="py-16">
      <article className="max-w-container mx-auto px-6 md:px-4 flex flex-col gap-5">
        <div className="pb-8 flex flex-col gap-4">
          <div className="font-bold flex flex-wrap justify-start lg:justify-center text-2xl md:text-[32px] leading-[140%] montserrat whitespace-nowrap">
            Portofolio Kami
          </div>
        </div>
        <div className="flex flex-row flex-wrap justify-center gap-x-28 gap-y-16">
          <img
            src={cleansheet}
            alt="Cleansheet"
            className="h-16"
          />
          <img src={indosiar} alt="Indosiar" className="h-16" />
          <img src={sctv} alt="sctv" className="h-16" />
          <img src={yppp} alt="yppp" className="h-16" />
          <img src={baznas} alt="baznas" className="h-16" />
          <img src={ybm} alt="ybm-pln" className="h-16" />
          <img src={paragon} alt="paragon" className="h-16" />
          <img src={kahf} alt="kahf" className="h-16" />
          <img src={alHurriyah} alt="alHurriyah" className="h-16" />
          <img src={wardah} alt="wardah" className="h-16" />
          <img src={biodef} alt="biodef" className="h-16" />
          <img src={semudahitu} alt="semudahitu" className="h-16" />
          <img src={bpi} alt="bpi" className="h-16" />
          <img src={majuindonesia} alt="maju-indonesia" className="h-16" />
          <img src={ybmi} alt="ybmi" className="h-16" />
          <img src={tiptop} alt="tiptip" className="h-16" />
        </div>
      </article>
    </section>
  );
};

export default PortofolioPage;
