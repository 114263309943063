import logo from "../../assets/images/logo/Logo.png";
import Icon from "@mdi/react";
import {mdiArrowLeft} from "@mdi/js";
import React from "react";
import {useNavigate, useLocation} from "react-router-dom";

const FormHeader = ({transactionCode}) => {
  let navigate = useNavigate();
  const location = useLocation().pathname;

  const handleBack = () => {
    if (location === "/form") {
      navigate("/");
    } else {
      navigate("/form", {replace: true});
    }
  };

  return (
    <nav className="border-b bg-white border-b-bright">
      <div className="grid grid-cols-12 mx-auto lg:max-w-7xl py-5 px-4 lg:px-4 2xl:px-0">
        <div className="col-span-2 flex items-center justify-between">
          <img src={logo} alt="CleanSheet" className="h-9 hidden sm:block" />
          <button className="h-9 block mr-0 sm:mr-5" onClick={handleBack}>
            <Icon path={mdiArrowLeft} size={1} className="text-primary" />
          </button>
        </div>
        {location === "/form/payment" ? (
          <div className="col-span-10 grid grid-cols-10">
            <div className="col-span-12 sm:col-span-6 flex items-center">
              <span className="not-italic montserrat text-2xl font-bold leading-[140%] tracking-[0.005em] text-primary">
                Pembayaran
              </span>
            </div>
            <div className="col-span-12 sm:col-end-9 sm:col-span-2 flex items-center">
              <dl className="flex flex-col items-start gap-1">
                <dt className="not-italic font-normal text-xs leading-[17px] tracking-[0.005em] text-dark">
                  Nomor Transaksi
                </dt>
                <dd className="not-italic font-bold text-base leading-[22px] tracking-[0.005em] text-dark">
                  {transactionCode}
                </dd>
              </dl>
            </div>
          </div>
        ) : location === "/form/progress" ? (
          <div className="col-span-10 grid grid-cols-10">
            <div className="col-span-12 sm:col-span-6 flex items-center">
              <span className="not-italic montserrat text-2xl font-bold leading-[140%] tracking-[0.005em] text-primary">
                Progress Pesanan
              </span>
            </div>
            <div className="col-span-12 sm:col-end-9 sm:col-span-2 flex items-center">
              <dl className="flex flex-col items-start gap-1">
                <dt className="not-italic font-normal text-xs leading-[17px] tracking-[0.005em] text-dark">
                  Nomor Transaksi
                </dt>
                <dd className="not-italic font-bold text-base leading-[22px] tracking-[0.005em] text-dark">
                  09879543325645764
                </dd>
              </dl>
            </div>
          </div>
        ) : (
          <div className="col-span-10 grid grid-cols-10">
            <div className="col-span-10">
              <span className="not-italic montserrat text-2xl font-bold leading-[140%] tracking-[0.005em] text-primary">
                Form Pemesanan
              </span>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default FormHeader;
