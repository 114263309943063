import FloatingWhatsapp from "../../../components/LandingPage/FloatingWhatsapp";
import TabTitle from "../../../utils/TabTitle";
import ModalAlert from "../../../components/ModalAlert";
import React, { useState, useEffect } from "react";
import Button from "../../../components/Button";
import TextField from "../../../components/TextField";
import { connect, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import logo from "../../../assets/images/logo/Logo.png";
import Icon from "@mdi/react";
import { mdiArrowLeft } from "@mdi/js";
import Footer from "../../../components/LandingPage/Footer";
import NavProfile from "../Component/NavProfile";
import api from "../../../api";
import { formatRupiah } from "../../../utils/RupiahFormat";

const DashboardPage = (props) => {
    TabTitle("Dashboard");
    const navigate = useNavigate();
    const auth = useSelector(state => state.auth);
    const profile = useSelector(state => state.profile);

    const [donation, setDonation] = useState([]);
    const [totalDonations, setTotalDonations] = useState({ total_amount: 0, pending_amount: 0 });
    const [totalCampaigns, setTotalCampaigns] = useState(0); // New state for total campaigns

    // global condition component
    const [modalOpen, setModalOpen] = useState({ status: false, type: "" });
    const [modalAlert, setModalAlert] = useState({
        alertTitle: "",
        alertMessage: "",
    });

    useEffect(() => {
        getDonations();
        getTotalDonations(); // Call the new function to get total donations
        getTotalCampaigns(); // Call to get total campaigns
    }, []);

    const getDonations = async () => {
        try {
            const response = await api.get("/v1/campaign/donations");
            console.log(response.data.data);

            setDonation(response.data.data);
        } catch ({ response }) {
            console.log(response);
        }
    };

    const getTotalDonations = async () => {
        try {
            const response = await api.get("/v1/donations/total");
            setTotalDonations(response.data.data); // Update state with total donations data
        } catch (error) {
            console.log(error.response);
        }
    };

    const getTotalCampaigns = async () => {
        try {
            const response = await api.get("/v1/campaigns/total");
            setTotalCampaigns(response.data.data.total_campaigns); // Update state with total campaigns data
        } catch (error) {
            console.log(error.response);
        }
    };

    return (
        <>
            <ModalAlert
                isOpen={modalOpen.status && modalOpen.type === "alert"}
                closeModal={() => {
                    setModalOpen({ status: false, type: "" });
                    setModalAlert({ alertTitle: "", alertMessage: "" });
                }}
                alertTitle={modalAlert.alertTitle}
                alertMessage={modalAlert.alertMessage}
            />
            <header id="header" className="top-0 z-50 sticky">
                <nav className="border-b bg-white border-b-bright">
                    <div className="grid grid-cols-12 mx-auto lg:max-w-7xl py-5 px-4 lg:px-4 2xl:px-0">
                        <div className="col-span-1 flex items-center justify-between">
                            <img src={logo} alt="CleanSheet" className="h-9 hidden sm:block" />
                        </div>
                        <div className="col-span-11 grid grid-cols-11">
                            <div className="col-span-10">
                                <span className="not-italic montserrat text-2xl font-bold leading-[140%] tracking-[0.005em] text-primary">
                                    Dashboard
                                </span>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>

            <section className="bg-neutralLight font-light leading-relaxed grid grid-rows-12 grid-flow-col grid-cols-12 mx-auto px-4 lg:px-4 xl:px-2 lg:max-w-full relative gap-4 lg:gap-y-6 lg:gap-x-4 py-2">
                <NavProfile />

                <div className="col-span-12 grid grid-cols-2 gap-4 mb-4">
                    <div className="bg-white rounded-lg shadow p-4">
                        <h3 className="text-lg font-bold">Total Campaigns</h3>
                        <p className="text-2xl">{totalCampaigns}</p> {/* Display total campaigns */}
                    </div>
                    <div className="bg-white rounded-lg shadow p-4">
                        <h3 className="text-lg font-bold">Total Donations</h3>
                        <p className="text-2xl">{formatRupiah(totalDonations.total_amount)}</p>
                    </div>
                    <div className="bg-white rounded-lg shadow p-4 col-span-2 md:col-span-1">
                        <h3 className="text-lg font-bold">Pending Donations</h3>
                        <p className="text-2xl">1</p>
                    </div>
                </div>

                <div className="row-span-2 col-span-9 bg-white rounded-xl shadow">
                    <table className="min-w-full">
                        <thead>
                            <tr className="bg-grey-300">
                                <th className="px-4 py-2 text-center">No</th>
                                <th className="px-4 py-2 text-center">Nomor Transaksi</th>
                                <th className="px-4 py-2 text-center">Nama</th>
                                <th className="px-4 py-2 text-center">Tanggal</th>
                                <th className="px-4 py-2 text-center">Nominal Donasi</th>
                                <th className="px-4 py-2 text-center">Status</th> {/* Added header for Status */}
                                <th className="px-4 py-2 text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {donation.map((item, index) => ( // Map over the donation array
                                <tr key={item.id} className={index % 2 === 0 ? "bg-white" : "bg-grey-50"}> 
                                    <td className="px-4 py-2 text-center">{index + 1}</td>
                                    <td className="px-4 py-2 text-center">{item.transaction_number}</td> {/* Adjust property names as needed */}
                                    <td className="px-4 py-2 text-center">{item.name}</td>
                                    <td className="px-4 py-2 text-center">{item.date}</td>
                                    <td className="px-4 py-2 text-center">{formatRupiah(item.amount)}</td>
                                    <td className="px-4 py-2 text-center">{item.is_paid ? "Paid" : "Pending"}</td> {/* Adjust property names as needed */}
                                    <td className="px-4 py-2 text-center">
                                        <button className="text-blue-500">Detail</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

            </section>
            <footer id="footer">
                <Footer />
            </footer>
        </>
    );
}

export default connect(null)(DashboardPage)
