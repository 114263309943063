const initialState = {
    isLoading: false,
    isFulfilled: false,
    isRejected: false,
    error: ''
};

const referenceSources = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_REFERENCE_SOURCES_PENDING':
            return {
                ...state,
                isLoading: true,
                isFulfilled: false,
                isRejected: false,
            };
        case 'GET_REFERENCE_SOURCES_REJECTED':
            return {
                ...state,
                isLoading: false,
                isRejected: true,
            };
        case 'GET_REFERENCE_SOURCES_FULFILLED':
            return {
                ...state,
                isLoading: false,
                isFulfilled: true,
                error: '',
            };
        default:
            return state;
    }
}

export default referenceSources
