const initialState = {
    isLoading: false,
    isFulfilled: false,
    isRejected: false,
    error: '',
    user: {
        name: null,
        phone: null,
        email: null,
        balance: 0
    },
};

const auth = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_PROFILE_PENDING':
            return {
                ...state,
                isLoading: true,
                isFulfilled: false,
                isRejected: false,
            };
        case 'GET_PROFILE_REJECTED':
            return {
                ...state,
                isLoading: false,
                isRejected: true,
            };
        case 'GET_PROFILE_FULFILLED':
            return {
                ...state,
                isLoading: false,
                isFulfilled: true,
                user: action?.payload?.data?.data?? {},
                error: '',
            };
        case 'EDIT_PROFILE_PENDING':
            return {
                ...state,
                isLoading: true,
                isFulfilled: false,
                isRejected: false,
            };
        case 'EDIT_PROFILE_REJECTED':
            return {
                ...state,
                isLoading: false,
                isRejected: true,
            };
        case 'EDIT_PROFILE_FULFILLED':
            return {
                ...state,
                isLoading: false,
                isFulfilled: true,
                error: '',
            };
        case 'ADD_CONTACT_PENDING':
            return {
                ...state,
                isLoading: true,
                isFulfilled: false,
                isRejected: false,
            };
        case 'ADD_CONTACT_REJECTED':
            return {
                ...state,
                isLoading: false,
                isRejected: true,
            };
        case 'ADD_CONTACT_FULFILLED':
            return {
                ...state,
                isLoading: false,
                isFulfilled: true,
                error: '',
            };
        case 'ADD_ADDRESS_PENDING':
            return {
                ...state,
                isLoading: true,
                isFulfilled: false,
                isRejected: false,
            };
        case 'ADD_ADDRESS_REJECTED':
            return {
                ...state,
                isLoading: false,
                isRejected: true,
            };
        case 'ADD_ADDRESS_FULFILLED':
            return {
                ...state,
                isLoading: false,
                isFulfilled: true,
                error: '',
            };
        case 'EDIT_ADDRESS_PENDING':
            return {
                ...state,
                isLoading: true,
                isFulfilled: false,
                isRejected: false,
            };
        case 'EDIT_ADDRESS_REJECTED':
            return {
                ...state,
                isLoading: false,
                isRejected: true,
            };
        case 'EDIT_ADDRESS_FULFILLED':
            return {
                ...state,
                isLoading: false,
                isFulfilled: true,
                error: '',
            };
        case 'DELETE_ADDRESS_PENDING':
            return {
                ...state,
                isLoading: true,
                isFulfilled: false,
                isRejected: false,
            };
        case 'DELETE_ADDRESS_REJECTED':
            return {
                ...state,
                isLoading: false,
                isRejected: true,
            };
        case 'DELETE_ADDRESS_FULFILLED':
            return {
                ...state,
                isLoading: false,
                isFulfilled: true,
                error: '',
            };
        default:
            return state;
    }
}

export default auth
