import {
  mdiClose,
  mdiEmailOutline,
  mdiFacebook,
  mdiGoogleMaps,
  mdiInstagram,
  mdiMenu,
  mdiPhoneOutline,
  mdiTwitter,
} from "@mdi/js";
import Icon from "@mdi/react";
import logo from "../../assets/images/logo/logo_langkahbaik.png";
import en from "../../assets/images/icons/en.png";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import Button from "../Button";
import Dropdown from "../Dropdown";

function NavBar() {
  const [isMobile, setIsMobile] = useState(false);
  const toggleMenu = () => setIsMobile(!isMobile);

  const serviceItems = [
    "Konsultasi CSR",
    "Manajemen Program",
    "Social Impact Investment"
  ];

  return (
    <div className="navbar-container bg-white lg:border-b lg:border-b-primary flex flex-col overflow-visible relative h-full" aria-label="main-navbar">
      {/* Desktop */}
      <div className="bg-white border-b border-b-primary w-full md:max-w-container mx-auto lg:border-b-0 py-5 px-6 grid grid-cols-2 md:grid-cols-12 gap-8">
        <div className="col-span-1 md:col-span-2">
          <a href="/" title="LangkahBaik" aria-label="Langkah Baik Logo">
            <img className="h-16 w-auto" src={logo} alt="Langkah Baik" />
          </a>
        </div>
        <div className="md:hidden col-end-3 col-span-1 flex justify-end">
          {!isMobile ? (
            <button
              type="button"
              onClick={toggleMenu}
              title="Open menu"
              className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              aria-expanded="false"
              aria-label="Toggle Open Navigation"
            >
              <span className="sr-only">Open menu</span>
              <Icon path={mdiMenu} size={1} className="text-primary" />
            </button>
          ) : (
            <button
              type="button"
              onClick={toggleMenu}
              title="Close menu"
              className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              aria-expanded="false"
              aria-label="Toggle Close Navigation"
            >
              <span className="sr-only">Close menu</span>
              <Icon path={mdiClose} size={1} className="text-primary" />
            </button>
          )}
        </div>
        <ul className="hidden md:col-span-10 md:flex flex-row items-stretch justify-end">
          <li className="flex items-center">
            <NavLink
              to={"/"}
              title="Beranda"
              aria-label="Beranda"
              className={({ isActive }) =>
                `flex items-center px-2 text-sm font-bold leading-[140%] tracking-wider ${isActive ? "text-primary" : "text-dark hover:text-primary"}`
              }
            >
              Beranda
            </NavLink>
          </li>
          <li className="flex items-center">
            <NavLink
              to={"/about"}
              title="Tentang Langkah Baik"
              aria-label="Tentang Langkah Baik"
              className={({ isActive }) =>
                `flex items-center px-2 text-sm font-bold leading-[140%] tracking-wider ${isActive ? "text-primary" : "text-dark hover:text-primary"}`
              }
            >
              Tentang Kami
            </NavLink>
          </li>
          <li className="flex items-center">
            <NavLink
              to={"/portofolio"}
              title="Portofolio"
              aria-label="Portofolio"
              className={({ isActive }) =>
                `flex items-center px-2 text-sm font-bold leading-[140%] tracking-wider ${isActive ? "text-primary" : "text-dark hover:text-primary"}`
              }
            >
              Portofolio
            </NavLink>
          </li>
          <li className="dropdown-container relative h-full flex items-center">
            <Dropdown label="Layanan" items={serviceItems} isMobile={false} />
          </li>
          <li className="flex items-center">
            <NavLink
              to={"/project-us"}
              title="Program"
              aria-label="Program"
              className={({ isActive }) =>
                `flex items-center px-2 text-sm font-bold leading-[140%] tracking-wider ${isActive ? "text-primary" : "text-dark hover:text-primary"}`
              }
            >
              Program
            </NavLink>
          </li>
          <li className="flex items-center">
            <NavLink
              to={"/blog"}
              title="Blog"
              aria-label="Blog"
              className={({ isActive }) =>
                `flex items-center px-2 text-sm font-bold leading-[140%] tracking-wider ${isActive ? "text-primary" : "text-dark hover:text-primary"}`
              }
            >
              Blog
            </NavLink>
          </li>
          <li className="flex items-center">
            <NavLink
              to={"/contact-us"}
              title="Hubungi Kami"
              aria-label="Hubungi Kami"
              className={({ isActive }) =>
                `flex items-center px-2 text-sm font-bold leading-[140%] tracking-wider ${isActive ? "text-primary" : "text-dark hover:text-primary"}`
              }
            >
              Hubungi Kami
            </NavLink>
          </li>
        </ul>
      </div>

      {/* Mobile */}
      {isMobile && (
        <div className="md:hidden relative">
          <div className="border-b border-b-primary bg-white py-6 px-5 overflow-y-auto flex flex-col gap-8">
            <ul className="flex flex-col gap-8">
              <li>
                <NavLink
                  to={"/"}
                  title="Beranda"
                  aria-label="Beranda"
                  onClick={toggleMenu}
                  className={({ isActive }) =>
                    `block px-4 py-2 text-sm font-bold leading-[140%] tracking-wider ${isActive ? "text-primary" : "text-dark"}`
                  }
                >
                  Beranda
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={"/about"}
                  title="Tentang Langkah Baik"
                  aria-label="Tentang Langkah Baik"
                  onClick={toggleMenu}
                  className={({ isActive }) =>
                    `block px-4 py-2 text-sm font-bold leading-[140%] tracking-wider ${isActive ? "text-primary" : "text-dark"}`
                  }
                >
                  Tentang Kami
                </NavLink>
              </li>
              <li className="relative">
                <Dropdown label="Layanan" items={serviceItems} isMobile={true} />
              </li>
              <li>
                <NavLink
                  to={"/project-us"}
                  title="Program - Langkah Baik"
                  aria-label="Program - Langkah Baik"
                  onClick={toggleMenu}
                  className={({ isActive }) =>
                    `block px-4 py-2 text-sm font-bold leading-[140%] tracking-wider ${isActive ? "text-primary" : "text-dark"}`
                  }
                >
                  Program
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={"/blog"}
                  title="Blog - Langkah Baik"
                  aria-label="Blog - Langkah Baik"
                  onClick={toggleMenu}
                  className={({ isActive }) =>
                    `block px-4 py-2 text-sm font-bold leading-[140%] tracking-wider ${isActive ? "text-primary" : "text-dark"}`
                  }
                >
                  Blog
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={"/contact-us"}
                  title="Hubungi Kami - Langkah Baik"
                  aria-label="Hubungi Kami - Langkah Baik"
                  onClick={toggleMenu}
                  className={({ isActive }) =>
                    `block px-4 py-2 text-sm font-bold leading-[140%] tracking-wider ${isActive ? "text-primary" : "text-dark"}`
                  }
                >
                  Hubungi Kami
                </NavLink>
              </li>
            </ul>
            <div className="flex flex-row w-full">
              <a
                href="#"
                className="border-l-[1px] border-neutral px-4 py-2 flex h-9"
              >
                <Icon path={mdiFacebook} size={0.8} className="text-primary" />
              </a>
              <button className="border-x-[1px] border-neutral px-4 py-2 flex h-9">
                <Icon path={mdiTwitter} size={0.8} className="text-primary" />
              </button>
              <a
                href="#"
                className="border-r-[1px] border-neutral px-4 py-2 flex h-9"
              >
                <Icon path={mdiInstagram} size={0.8} className="text-primary" />
              </a>
              <button className="border-r-[1px] border-neutral px-4 py-2 flex h-9 items-center">
                <img src={en} alt="English" className="h-full" />
                <span className="ml-1 text-dark text-xs leading-[140%] tracking-wider font-bold">
                  EN
                </span>
                {/* <Dropdown label={bahasa==="end"?"Total Fund":"Total Pendapatan"} custom="w-[300px] left-0">
            <RadioButton
              label="0-10.000.000"
              id="fund1"
              name="filter[campaigns_sum_collected_fund_between]"
              check={filterData}
              click={onChangeData}
              value="0,10000000"
            /> */}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default NavBar;
