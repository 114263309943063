import {createStore, applyMiddleware} from 'redux';
import {createLogger} from 'redux-logger';
import Promise from 'redux-promise-middleware';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'

import reducers from './Reducers';

const logger = createLogger ()

// Middleware: Redux Persist Config
const persistConfig = {
  // Root
  key: 'root',
  // Storage Method
  storage,
  // Whitelist (Save Specific Reducers)
  whitelist: [
    'auth',
    'profile'
  ],
  // Blacklist (Don't Save Specific Reducers)
  blacklist: [
  ],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = process.env.REACT_APP_DEV_MODE === 'true' ? createStore (
  persistedReducer,
  applyMiddleware(logger, Promise)
) : createStore (
  persistedReducer,
  applyMiddleware(Promise)
)

let persistor = persistStore(store);

export {
  store,
  persistor,
};
