import BaseAxios from './BaseAxios'

const apiReferenceSources = `${process.env.REACT_APP_API_URL}/v1/reference-sources`;

const getReferenceSources = () => {
    return{
      type: 'GET_REFERENCE_SOURCES',
      payload: BaseAxios.get(apiReferenceSources)
    }
}

export {
    getReferenceSources,
}
