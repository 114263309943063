import React, { useState, useEffect } from "react"; // Added useState and useEffect imports
import { NavLink, useNavigate, useLocation, useParams } from "react-router-dom";
import Button from "../../components/Button";
import NewsComponent from "./Components/News";
import Penyaluran from "./Components/Penyaluran";
import ModalForm from "../../components/ModalForm"; // Import your modal component
import api from "../../api";
import Modal from "../../components/Modal";
import logoBNI from "../../assets/images/payments/logo-bni.png";
import logoMandiri from "../../assets/images/payments/logo-mandiri.png";
import logoBankPermata from "../../assets/images/payments/logo-permatabank.png";
import logoBRI from "../../assets/images/payments/logo-bri.png";
import logoBCA from "../../assets/images/payments/logo-bca.png";
import logoBSI from "../../assets/images/payments/logo-bsi.png";
import logoDana from "../../assets/images/payments/logo-dana.png";
import logoLinkAja from "../../assets/images/payments/logo-linkaja.png";
import logoShopeePay from "../../assets/images/payments/logo-shopeepay.png";

const DonatePage = () => {
  let navigate = useNavigate();
  const { id } = useParams(); 

  const [activeTab, setActiveTab] = useState("deskripsi"); // Added state for active tab
  const [isModalOpen, setIsModalOpen] = useState(false); // Added state for modal visibility
  const [selectedAmount, setSelectedAmount] = useState(null); // Added state for selected donation amount
  const [nominal, setNominal] = useState(''); // Added state for nominal input
  const [modalAlert, setModalAlert] = useState({
    alertTitle: "",
    alertMessage: "",
  });
  const [paymentMethod, setPaymentMethod] = useState(null); // Added state for payment method
  const [paymentMethodList, setPaymentMethodList] = useState([]);
  const [postStateData, setPostStateData] = useState(null);
  const [campaignData, setCampaignData] = useState(null); // Added state for campaign data

  const toggleModal = () => setIsModalOpen(!isModalOpen); // Function to toggle modal

  useEffect(() => {
    getPaymentMethod(); // Call getPaymentMethod on component mount
    fetchCampaignData(); // Fetch campaign data
  }, []); // Empty dependency array to run only once

  const fetchCampaignData = async () => {
    try {
      const { data } = await api.get(`/v1/campaign/${id}`); // Fetch campaign data
      setCampaignData(data.data); // Set campaign data to state
    } catch (error) {
      console.error('Error fetching campaign data:', error);
    }
  };

  const getPaymentMethod = async () => {
    try {
      const { data } = await api.get("/v1/payment-methods");
      let temp = [];
      data.data.items.forEach((item) => {
        if (item.name.includes("BNI")) {
          temp.push({ ...item, logo: logoBNI });
        } else if (item.name.includes("Mandiri")) {
          temp.push({ ...item, logo: logoMandiri });
        } else if (item.name.includes("Permata")) {
          temp.push({ ...item, logo: logoBankPermata });
        } else if (item.name.includes("BRI")) {
          temp.push({ ...item, logo: logoBRI });
        } else if (item.name.includes("BCA")) {
          temp.push({ ...item, logo: logoBCA });
        } else if (item.name.includes("Dana")) {
          temp.push({ ...item, logo: logoDana });
        } else if (item.name.includes("LinkAja")) {
          temp.push({ ...item, logo: logoLinkAja });
        } else if (item.name.includes("ShopeePay")) {
          temp.push({ ...item, logo: logoShopeePay });
        } else if (item.name.includes("BSI")) {
          temp.push({ ...item, logo: logoBSI });
        }

        if (item.categoryId === "1") {
          temp[temp.length - 1].category = "Virtual Account";
        } else if (item.categoryId === "2") {
          temp[temp.length - 1].category = "E-Wallet";
        } else if (item.categoryId === "3") {
          temp[temp.length - 1].category = "Transfer Bank";
        }
      });
      temp.sort((a, b) => {
        if (a.categoryId < b.categoryId) {
          return -1;
        }
        if (a.categoryId > b.categoryId) {
          return 1;
        }
        return 0;
      });

      setPaymentMethodList(temp);
    } catch ({ response }) {
      console.log(response);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission

    const data = {
      name: document.getElementById('name').value,
      email: document.getElementById('email').value,
      phone: document.getElementById('phone').value,
      amount: parseFloat(nominal.replace(/\./g, '').replace(/,/g, '.')), // Convert nominal to numeric
      paymentMethodId: paymentMethod.id, // Use selected payment method
      campaignId: id
    };

    try {
      console.log('payload: ', data);
      console.log('payment method: ', paymentMethod);

      await api
        .post(`/v1/campaign/donation`, data)
        .then((response) => {
          // setIsConfirm(true);
          setPostStateData(response.data.data);

          navigate("/payment", {
            state: {
              api: response.data.data,
              form: paymentMethod,
              id: id,
              paymentId: paymentMethod.id,
            },
            replace: true,
          });
      
        })
        .catch(({ response }) => {
          // setIsConfirm(false);
          setModalAlert({
            alertTitle: `Galat ${response.status}`,
            alertMessage:
              response.status === 0
                ? "Tidak ada koneksi internet. Silahkan coba lagi setelah koneksi internet stabil."
                : "Gagal mengirim data ke server. Silahkan coba beberapa saat lagi atau hubungi admin Cleansheet.",
          });
        });
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const formatRupiah = (amount) => {
    return new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0, // No decimal places
      maximumFractionDigits: 0, // No decimal places
    }).format(amount || 0);
  };

  return (
    <section id="ayo-bergabung-menjadi-partner-kami">
      <article className="max-w-full pt-16 pb-32 px-4 md:px-4 lg:px-8 xl:px-10 flex flex-col gap-12">
        <div className="grid grid-cols-1 lg:grid-cols-1 md:grid-cols-1 gap-8 lg:gap-6">
          <div className="flex flex-col gap-6 p-6 bg-white drop-shadow-bottom rounded-xl col-span-1 lg:mx-32 h-auto">
            <div className="h-96 overflow-hidden">
              {campaignData && (
                <img
                  src={campaignData.image.resourceUrl}
                  alt="CleanSheet Rangers"
                  className="w-full h-full object-cover object-center"
                />
              )}
            </div>
            <h2 className="montserrat not-italic font-medium text-xl leading-[140%] tracking-[0.005em] text-dark text-start">
              {campaignData ? campaignData.title : ""}
            </h2>
            <div className="montserrat text-gray not-italic font-semibold text-xl leading-[100%] tracking-[0.005em]">
              <span className="text-primary">{campaignData ? formatRupiah(campaignData.raised_amount) : 0}</span>
            </div>
            <div className="montserrat text-default not-italic font-medium text-base leading-[20%] tracking-[0.005em]">
              Terkumpul dari <span className="text-dark font-semibold">{campaignData ? formatRupiah(campaignData.goal_amount) : 0}</span>
            </div>
            <progress
              value={campaignData ? (parseFloat(campaignData.raised_amount) / parseFloat(campaignData.goal_amount)) * 100 : 0}
              max="100"
              className="w-full h-2 bg-gray-200 rounded-full custom-progress appearance-none"
            />
            <div className="montserrat text-gray text-sm not-italic font-normal leading-[50%] tracking-[0.005em] flex justify-between"> {/* Added flex for alignment */}
              {campaignData && (
                <>
                  <p><span className="text-dark font-medium">
                    {((parseFloat(campaignData.raised_amount) / parseFloat(campaignData.goal_amount)) * 100).toFixed(0)}%
                  </span>&nbsp; Tercapai</p>

                  <span className="text-default font-medium"> {/* Changed to show days left in Bahasa */}
                    {campaignData.end_date ?
                      Math.ceil((new Date(campaignData.end_date) - new Date()) / (1000 * 60 * 60 * 24)) + " hari tersisa"
                      : ""}
                  </span>
                </>
              )}
            </div>
            <NavLink to="#" onClick={(e) => { e.preventDefault(); toggleModal(); }}> {/* Prevent default link behavior */}
              <Button customStyle=" w-full">
                <div className="w-full text-center not-italic font-bold text-sm lg:text-base leading-[140%] tracking-[0.005em] text-white py-1 px-3 md:px-5">
                  Donasi Sekarang
                </div>
              </Button>
            </NavLink>

            {/* Added Tab Navigation */}
            <div className="flex space-x-4 mb-4 relative overflow-x-auto"> {/* Added overflow-x-auto for horizontal scrolling */}
              {["deskripsi", "Kabar Terbaru", "Penyaluran"].map((tab) => (
                <button
                  key={tab}
                  onClick={() => setActiveTab(tab)}
                  className={`flex-1 py-2 px-4 rounded-lg ${activeTab === tab ? 'bg-primary text-white' : 'bg-light text-primaryDark'}`} // Updated button styles for active/inactive
                >
                  {tab.charAt(0).toUpperCase() + tab.slice(1)}
                </button>
              ))}
            </div>

            {/* Tab Content */}
            <div>
              {activeTab === "deskripsi" &&
                <div>
                  <div className="text-gray text-base not-italic font-normal leading-[140%] tracking-[0.005em] text-default">
                    <p>{campaignData ? campaignData.description : ""}</p>
                  </div>
                </div>
              }
              {activeTab === "Kabar Terbaru" &&
                <div><NewsComponent id={campaignData.id} /></div>
              }
              {activeTab === "Penyaluran" && <div><Penyaluran campaign={campaignData} /></div>}
            </div>

            {/* Modal Component */}
            {isModalOpen && (
              <ModalForm onClose={toggleModal}>
                <div className="p-4">
                  <h2 className="text-lg font-bold mb-4">Donasi Form</h2>
                  <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                      <label className="block text-sm font-medium mb-1" htmlFor="name">Nama</label>
                      <input
                        type="text"
                        id="name"
                        className="border rounded w-full py-2 px-3"
                        placeholder="Masukkan nama"
                      />
                    </div>
                    {/* Removed Address Field */}
                    {/* <div className="mb-4">
                      <label className="block text-sm font-medium mb-1" htmlFor="address">Address</label>
                      <input
                        type="text"
                        id="address"
                        className="border rounded w-full py-2 px-3"
                        placeholder="Enter your address"
                      />
                    </div> */}
                    <div className="mb-4">
                      <label className="block text-sm font-medium mb-1" htmlFor="email">Email</label>
                      <input
                        type="email"
                        id="email"
                        className="border rounded w-full py-2 px-3"
                        placeholder="Masukkan email"
                      />
                    </div>
                    <div className="mb-4">
                      <label className="block text-sm font-medium mb-1" htmlFor="phone">No. Hp</label>
                      <input
                        type="tel"
                        id="phone"
                        className="border rounded w-full py-2 px-3"
                        placeholder="Masukkan Phone"
                      />
                    </div>
                    {/* Donation Amount Options */}
                    <div className="grid grid-cols-2 gap-4 mb-4"> {/* Added grid for donation options */}
                      {["50000", "100000", "250000", "500000"].map((amount) => (
                        <button
                          key={amount}
                          type="button" // Ensure button type is set to "button"
                          onClick={() => {
                            setSelectedAmount(amount); // Set selected amount on click
                            setNominal(new Intl.NumberFormat('id-ID').format(amount)); // Format and set nominal input value
                          }}
                          className={`py-2 rounded ${selectedAmount === amount ? 'bg-primary text-white' : 'bg-white border border-gray-500 text-dark'}`} // Change style based on selection
                        >
                          Rp. {amount.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                        </button>
                      ))}
                    </div>
                    <div className="mb-4">
                      <label className="block text-sm font-medium mb-1" htmlFor="nominal">Nominal</label>
                      <input
                        type="text" // Change to text for better control over formatting
                        id="nominal"
                        value={nominal} // Set the value of the input to the nominal state
                        onChange={(e) => {
                          const value = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                          if (value) {
                            setNominal(new Intl.NumberFormat('id-ID').format(value)); // Format as Rupiah
                          } else {
                            setNominal(''); // Reset if empty
                          }
                        }}
                        onKeyDown={(e) => {
                          // Allow only numbers, backspace, and delete
                          if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                            e.preventDefault();
                          }
                        }}
                        className="border rounded w-full py-2 px-3"
                        placeholder="Masukkan Jumlah"
                      />
                    </div>
                    <div className="mb-4">
                      <label className="block text-sm font-medium mb-1" htmlFor="paymentMethod">Metode Pembayaran</label>
                      <select
                        id="paymentMethod"
                        className="border rounded w-full py-2 px-3"
                        onChange={(e) => {
                          const selectedMethodId = e.target.value; // Get the selected method ID
                          const selectedMethod = paymentMethodList.find(method => method.id === selectedMethodId);
                          setPaymentMethod(selectedMethod);

                        }}
                      >
                        <option value="">Pilih Metode Pembayaran</option>
                        {paymentMethodList.map((method) => (
                          <option key={method.id} value={method.id}>{method.name}</option> // Populate options from paymentMethodList
                        ))}
                      </select>
                    </div>
                    <button type="submit" className="bg-primary text-white py-2 px-4 rounded">Donasi</button>
                    <button type="button" onClick={toggleModal} className="bg-gray-300 text-black py-2 px-4 rounded">Batal</button> {/* Added cancel button */}
                  </form>
                </div>
              </ModalForm>
            )}
          </div>
        </div>
      </article>
    </section>
  );
};

export default DonatePage;