import img from "../../../../assets/images/banner/consultation_csr.webp";

function SectionLayanan() {
    return (
        <section className="py-16" id="sectionProblems">
            <article className="relative max-w-container mx-auto px-6 md:px-4">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                    <div className="center-web">
                        <img src={img} alt="" className="w-full h-96 object-cover" />
                    </div>
                    <div className='self-start'>
                        <h2 className="font-bold text-[32px] leading-[130%] tracking-wider text-dark montserrat">
                            <span className="underlined">Konsultasi CSR</span>
                        </h2>
                        <p className='text-base text-dark text-[16px] mt-4'>
                        Kami membantu perusahaan merancang & mengimplementasikan program CSR yang sesuai dengan visi dan misi perusahaan
                        </p>
                    </div>
                </div>
            </article>
        </section>
    );
}

export default SectionLayanan;