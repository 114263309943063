import {combineReducers} from 'redux';

import auth from './Auth';
import profile from './Profile';
import referenceSources from './ReferenceSources';
import order from './Order';

const appReducer = combineReducers ({
    auth,
    profile,
    referenceSources: referenceSources,
    order,
});

const rootReducer = (state, action) => {
  return appReducer(state, action)
}

export default rootReducer;
