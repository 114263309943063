import {useNavigate} from "react-router-dom";
import logo from "../../assets/images/logo/Logo.png";
import progressUpload from "../../assets/images/status/progress-upload.png";
import failedUpload from "../../assets/images/status/failed-upload.png";
import successUpload from "../../assets/images/status/success-upload.png";
import Button from "../Button";

function ProgessUpload({status, setStatus, progress = 0}) {
  let navigate = useNavigate();
  return (
    <div className="fixed inset-0 flex flex-col p-4 items-center overflow-y-auto bg-white z-50">
      <div>
        <img src={logo} className="max-h-9" alt="Cleansheet Indonesia" />
      </div>
      <div className="flex flex-auto flex-col items-center justify-center gap-8 px-4 py-8">
        <div>
          <img
            className="max-h-40 md:max-h-full"
            src={
              status === "success"
                ? successUpload
                : status === "failed"
                ? failedUpload
                : progressUpload
            }
            alt="status-pembayaran"
          />
        </div>
        <div className="montserrat not-italic font-bold text-2xl text-center leading-[140%] tracking-[0.005em] text-default">
          {status === "success" ? (
            "Bukti pembayaran berhasil diupload"
          ) : status === "failed" ? (
            <>
              Yaaah!
              <span className="block not-italic font-normal text-base leading-[140%] text-center tracking-[0.005em]">
                Bukti pembayaran kamu belum berhasil diupload
              </span>
            </>
          ) : (
            "Bukti pembayaran anda sedang kami proses"
          )}
        </div>
        {status === "success" ? (
          <div>
            <Button
              type="button"
              btnType="primary"
              click={() => navigate("/", {replace: true})}
              customStyle=" rounded-full"
            >
              <div className="px-2 not-italic font-bold text-base leading-[140%] tracking-[0.005em] text-white">
                Kembali ke Halaman Utama
              </div>
            </Button>
          </div>
        ) : status === "failed" ? (
          <div>
            <Button
              type="button"
              btnType="primary"
              click={() => setStatus("")}
              customStyle=" rounded-full"
            >
              <div className="px-2 not-italic font-bold text-base leading-[140%] tracking-[0.005em] text-white">
                Coba Upload Bukti Pembayaran Lagi
              </div>
            </Button>
          </div>
        ) : (
          <div className="h-2 w-full bg-primaryLight rounded-full overflow-hidden">
            <div
              className={`h-2 w-full rounded-full bg-blue-500`}
              style={{
                marginRight: `${100 - progress}%`,
                transition: `margin-right 100ms linear`,
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default ProgessUpload;
