import React from "react";
import contactImage from "../../../assets/images/misc/contact-us.jpg";
import { ReactComponent as Phone } from "../../../assets/vectors/icons/phone-icon.svg";
import { ReactComponent as MapMarker } from "../../../assets/vectors/icons/map-marker-icon.svg";
import { ReactComponent as Email } from "../../../assets/vectors/icons/envelope-icon.svg";

const SectionContactUs = () => {
    return (
        <section id="hubungi-kami" className="py-16">
            <article className="relative max-w-container mx-auto px-6 md:px-4">
                <div className="pb-8 flex flex-col gap-4">
                    <div className="font-bold flex flex-wrap justify-start lg:justify-center text-2xl md:text-[32px] leading-[140%] montserrat whitespace-nowrap">
                        Hubungi Kami
                    </div>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                    <div className="center-web">
                        <img src={contactImage} alt="" className="w-full object-cover rounded-3xl" />
                    </div>
                    <div className='flex flex-col gap-4 col-span-1 items-start p-6 bg-white rounded-3xl drop-shadow-bottom lg:h-[220px]'>
                        <div className="flex flex-row items-center">
                            <div>
                                <MapMarker className="self-center text-bright" />
                            </div>
                            <div className="text-dark font-medium leading-[140%] tracking-wider text-sm self-center ml-4 lg:ml-[21.79px] ">
                                Jalan Puspa Sari, Jl. Raya Taman Cimanggu Jl. Johar Raya No.1i Blok W6, Kota Bogor, Jawa Barat 16620
                            </div>
                        </div>
                        <div className="flex flex-row grow items-center">
                            <div>
                                <Phone className="self-center text-bright" />
                            </div>
                            <div className="text-dark font-medium leading-[140%] tracking-wider text-sm self-center ml-4 lg:ml-[21.79px]">
                                <dd>+62 858 8202 1749</dd>
                            </div>
                        </div>
                        <div className="flex flex-row grow items-center">
                            <div>
                                <Email className="self-center text-bright" />
                            </div>
                            <div className="text-dark font-medium leading-[140%] tracking-wider text-sm self-center ml-4 lg:ml-[21.79px]">
                                <dd>info@langkahbaik.id</dd>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-8">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.123456789012!2d106.7816201!3d-6.5631557!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69c5e753738e8f%3A0xda2c04cee11f96bc!2sCleansheet%20Indonesia%20%3A%20Aplikasi%20Penyedia%20Layanan%20Kebersihan%20dan%20Jasa%20Lainnya!5e0!3m2!1sen!2sid!4v1234567890123"
                        width="100%"
                        height="300"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                    ></iframe>
                </div>
            </article>
        </section>

    );
};

export default SectionContactUs;