import React from "react";
import Button from "./Button";
import Modal from "./Modal";

const ModalDialog = ({ isOpen, closeModal, alertInfo, alertImage }) => {
    return (
        <Modal isOpen={isOpen} closeModal={closeModal}>
            <div className="flex flex-col gap-4">
                <div>
                    <img
                        className="max-h-50 md:max-h-full w-full"
                        src={alertImage}
                        alt="status-pembayaran"
                    />
                </div>
                <div className="not-italic font-normal text-base leading-[22px] tracking-[0.005em] text-slateLight text-center">
                    {alertInfo}
                </div>
                <div className="flex flex-row justify-center">
                    <Button
                        customStyle="rounded-full w-full"
                        click={closeModal}
                    >
                        <div className="not-italic font-bold text-base leading-[22px] text-center tracking-[0.005em] w-full text-white">
                            OK
                        </div>
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default ModalDialog;
