import {
  mdiThumbUpOutline,
  mdiArrowLeftCircleOutline,
  mdiArrowRightCircleOutline,
  mdiArrowRight,
} from "@mdi/js";
import Icon from "@mdi/react";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import {Navigation, Pagination} from "swiper";
import consultation from "../../../assets/images/banner/consultation_csr.webp";
import program from "../../../assets/images/misc/manajemen-program.jpg";
import social from  "../../../assets/images/misc/social-impact-investment.jpg";
import React from "react";

function SectionLayanan() {
  let layanan = [
    {
      image: consultation,
      title: "Konsultasi CSR",
      description:
        "Kami membantu perusahaan merancang & mengimplementasikan program CSR yang sesuai dengan visi dan misi perusahaan",
      link: "/services/konsultasi-csr",
    },
    {
      image: program,
      title: "Manajemen Program",
      description:
        "Tim kami siap mendukung pengelolaan program CSR agar berjalan efektif, terukur, bekelanjutan dan memberikan dampak Positif",
      link: "/services/manajemen-program",
    },
    {
      image: social,
      title: "Social Impact Investment",
      description:
        "Manajemen Pendanaan (Crowdfunding) perseorangan maupun CSR perusahaan yang akan di investasikan ke wirausaha sosial / bisnis sosial agar dampaknya semakin luas dan terus berkelanjutan.",
      link: "/services/social-impact-investment",
    },
  ];
  return (
    <section
      className="relative overflow-hidden py-16 bg-service-texture bg-cover"
      id="layanan-kami"
    >
      <article className="md:max-w-container mx-auto px-6 md:px-4">
        <div className="flex flex-col justify-start md:justify-center">
          <div className="font-bold flex flex-row flex-wrap justify-start md:justify-center text-2xl lg:text-[32px] leading-[140%] montserrat">
            Layanan Kami &nbsp;
            <span>
              <span className="text-primary">Langkah</span>Baik.id
            </span>
          </div>
        </div>
      </article>
      <aside className="mt-[30px] min-w-0 md:max-w-container mx-auto px-0 sm:px-4 grid grid-cols-1 md:grid-cols-12">
        <button
          className="hidden md:flex justify-start col-span-1 layanan-swiper-button-prev disabled:opacity-50"
          title="Navigate Previous Slide"
          aria-label="Navigate Previous Service Slide"
        >
          <Icon
            path={mdiArrowLeftCircleOutline}
            size={2}
            className="flex justify-center self-center text-white"
          />
        </button>
        <div className="col-span-1 md:col-span-10">
          <div className="w-full md:max-w-[1024px]">
            <Swiper
              modules={[Navigation, Pagination]}
              breakpoints={{
                320: {
                  slidesPerView: 1.1,
                  centeredSlides: true,
                },
                640: {
                  slidesPerView: 2,
                  centeredSlides: false,
                },
                1024: {
                  slidesPerView: 3,
                  centeredSlides: false,
                },
              }}
              spaceBetween={10}
              pagination={{
                el: ".layanan-pagination",
                clickable: true,
                renderBullet: function (index, className) {
                  return '<span class="' + className + '"></span>';
                },
              }}
              navigation={{
                nextEl: ".layanan-swiper-button-next",
                prevEl: ".layanan-swiper-button-prev",
              }}
              loop={false}
            >
              {layanan.map((item, index) => (
                <React.Fragment key={index}>
                  <SwiperSlide>
                    <div className="rounded-[32px] p-3 h-full flex flex-col gap-4">
                      <div className="w-full h-[180px] rounded-2xl overflow-hidden">
                        <img
                          src={item.image}
                          alt={item.title}
                          className="inset-0 object-cover h-full w-96"
                        />
                      </div>
                      <div className="mt-4 text-primaryDark not-italic font-bold text-xl leading-[140%] tracking-[0.005em]">
                        {item.title}
                      </div>
                      <div className="text-gray-700 not-italic font-normal text-base leading-[140%] tracking-[0.005em]">
                        {item.description}
                      </div>
                      {/* Removed the "Lihat Detail" button and its container */}
                    </div>
                  </SwiperSlide>
                </React.Fragment>
              ))}
            </Swiper>
          </div>
        </div>
        <button
          className="hidden col-span-1 md:flex justify-end layanan-swiper-button-next disabled:opacity-50"
          title="Navigate Next Slide"
          aria-label="Navigate Next Service Slide"
        >
          <Icon
            path={mdiArrowRightCircleOutline}
            size={2}
            className="self-center text-white"
          />
        </button>
      </aside>
      <div
        className="mt-[32px] md:max-w-container mx-auto flex justify-center px-6 md:px-4"
        aria-label="service-navigation"
      >
        <div className="layanan-pagination block whitespace-nowrap"></div>
      </div>
      <div className="absolute h-1/2 bg-bright w-full bottom-0 -z-10"></div>
    </section>
  );
}

export default SectionLayanan;
