import TabTitle from "../../../utils/TabTitle";
import ModalAlert from "../../../components/ModalAlert";
import React, { useState, useEffect } from "react";
import Button from "../../../components/Button";
import { connect, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import api from "../../../api";
import logo from "../../../assets/images/logo/Logo.png";
import NavProfile from "../Component/NavProfile";

const BlogListPage = ({ id, ...props }) => {
    TabTitle("Blog");
    const navigate = useNavigate();

    const [blog, setBlog] = useState([]);

    // global condition component
    const [modalOpen, setModalOpen] = useState({ status: false, type: "" });
    const [modalAlert, setModalAlert] = useState({
        alertTitle: "",
        alertMessage: "",
    });

    useEffect(() => {
        getBlogs();
    }, []);

    const getBlogs = async () => {
        try {
            const response = await api.get("/v1/blogs-donation");
            console.log(response.data.data);

            setBlog(response.data.data.items);
        } catch ({ response }) {
            console.log(response);
        }
    };

    return (
        <>
            <ModalAlert
                isOpen={modalOpen.status && modalOpen.type === "alert"}
                closeModal={() => {
                    setModalOpen({ status: false, type: "" });
                    setModalAlert({ alertTitle: "", alertMessage: "" });
                }}
                alertTitle={modalAlert.alertTitle}
                alertMessage={modalAlert.alertMessage}
            />

            <header id="header" className="top-0 z-50 sticky">
                <nav className="border-b bg-white border-b-bright">
                    <div className="grid grid-cols-12 mx-auto lg:max-w-7xl py-5 px-4 lg:px-4 2xl:px-0">
                        <div className="col-span-1 flex items-center justify-between">
                            <img src={logo} alt="CleanSheet" className="h-9 hidden sm:block" />
                        </div>
                        <div className="col-span-11 grid grid-cols-11">
                            <div className="col-span-10">
                                <span className="not-italic montserrat text-2xl font-bold leading-[140%] tracking-[0.005em] text-primary">
                                    Dashboard
                                </span>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>

            <section className="bg-neutralLight font-light leading-relaxed grid grid-rows-12 grid-flow-col grid-cols-12 mx-auto px-4 lg:px-4 xl:px-2 lg:max-w-full relative gap-4 lg:gap-y-6 lg:gap-x-4 py-2">
                <NavProfile />
                <div className="row-span-2 col-span-9 bg-white">
                    <div className="border-b-2 p-4 border-bright flex mb-4">
                        <p className="text-2xl text-primary font-bold">Blog</p>
                    </div>
                    <div className="flex justify-end mb-4 p-4"> {/* Added this div for alignment */}
                        <Button customStyle="w-full" onClick={() => navigate("/dashboard/blog/add", { state: { id: id } })}>
                            Tambah
                        </Button>
                    </div>
                    <table className="min-w-full">
                        <thead>
                            <tr className="bg-grey-300"> 
                                <th className="px-4 py-2 text-center font-semibold">No</th> 
                                <th className="px-4 py-2 text-center font-semibold">Thumbnail</th>
                                <th className="px-4 py-2 text-center font-semibold">Judul</th>
                                <th className="px-4 py-2 text-center font-semibold">Tanggal</th> 
                                <th className="px-4 py-2 text-center font-semibold">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {blog.map((item, index) => (
                                <tr key={item.id} className={index % 2 === 0 ? "bg-white" : "bg-grey-50"}>
                                    <td className="px-4 py-2 text-center">{index + 1}</td>
                                    <td className="px-4 py-2 text-center">
                                        <img src={item.image?.resourceUrl || 'default-image-url.png'} alt={item.title} className="w-16 h-16 object-cover" /> {/* Added null checking for item.image */}
                                    </td>
                                    <td className="px-4 py-2 text-center">{item.title}</td>
                                    <td className="px-4 py-2 text-center">{item.updated_at}</td>
                                    <td className="px-4 py-2 text-center">
                                        <button
                                            className="text-blue-500"
                                            onClick={() => navigate(`/dashboard/blog/edit`, {
                                                state: { id: item.id }
                                            })} // Added navigation action
                                        >
                                            Edit
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </section>

        </>
    );
}

export default connect(null)(BlogListPage)