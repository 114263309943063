import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import Button from "../../components/Button";
import Card from "../../components/Card";
import Dropdown from "../../components/Dropdown";
import DropdownList from "../../components/DropdownList";
import ServiceList from "../../components/FormPage/ServiceList";
import TextArea from "../../components/TextArea";
import TextField from "../../components/TextField";
import api from "../../api";
import SliderPromo from "../../components/FormPage/SliderPromo";
import TabTitle from "../../utils/TabTitle";
import ModalAlert from "../../components/ModalAlert";
import {ReactComponent as ClockIcon} from "../../assets/vectors/icons/clock-icon.svg";
import {ReactComponent as CalendarIcon} from "../../assets/vectors/icons/calendar-icon.svg";
import TimePicker from "../../components/TimePicker";
import DateCalendarPicker from "../../components/DateCalendarPicker";
import {
  combineDateTime,
  getStringAfterBrackets,
  isStringContainsBrackets,
  numberWithCommas,
} from "../../utils/Tools";

const FormPesanPage = () => {
  TabTitle("Form Pemesanan - Cleansheet");
  // navigate
  let navigate = useNavigate();

  // refs
  const nameRefs = useRef(null);
  const phoneRefs = useRef(null);
  const refSourceRefs = useRef(null);
  const dateRefs = useRef(null);
  const timeRefs = useRef(null);
  const serviceRefs = useRef(null);

  // global condition component
  const [modalOpen, setModalOpen] = useState({status: false, type: ""});
  const [modalAlert, setModalAlert] = useState({
    alertTitle: "",
    alertMessage: "",
  });

  // data to processs
  const [dataAll, setDataAll] = useState([]);
  const [isServiceFetching, setIsServiceFetching] = useState(false);
  const [info, setInfo] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [selectCustomerGetInfo, setSelectCustomerGetInfo] = useState("");
  const [dateReservation, setDateReservation] = useState("");
  const [timeReservation, setTimeReservation] = useState("");

  const getCategories = async () => {
    try {
      const {data} = await api.get("/v1/categories");
      let temp = data.data.items.map((item) => {
        return {
          ...item,
          services: [],
        };
      });
      setDataAll(temp);
    } catch (error) {
      setModalAlert({
        alertTitle: `Error ${error.response.status}`,
        alertMessage: "Terjadi kesalahan saat mengambil data kategori layanan.",
      });
      setModalOpen({status: true, type: "alert"});
    }
  };

  const getInfo = async () => {
    try {
      const {data} = await api.get("/v1/reference-sources");
      setInfo(data.data.items);
    } catch (error) {
      setModalAlert({
        alertTitle: `Error ${error.response.status}`,
        alertMessage:
          error.response.status === 0
            ? "Tidak ada koneksi internet."
            : "Terjadi kesalahan saat memperoleh data dari server.",
      });
      setModalOpen({status: true, type: "alert"});
    }
  };

  const getServicesCategories = async (id) => {
    try {
      setIsServiceFetching(true);
      const {data} = await api.get(`/v1/categories/${id}/services`);
      let temp = data.data.items.map((item) => {
        return {
          ...item,
          quantity: 0,
          note: "",
        };
      });
      setDataAll((prevState) => {
        return prevState.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              services: temp,
            };
          } else {
            return item;
          }
        });
      });
      setIsServiceFetching(false);
    } catch (error) {
      setModalAlert({
        alertTitle: `Error ${error.response.status}`,
        alertMessage:
          error.response.status === 0
            ? "Tidak ada koneksi internet."
            : "Terjadi kesalahan saat mengambil data layanan.",
      });
      setModalOpen({status: true, type: "alert"});
      setIsServiceFetching(false);
    }
  };

  // validation
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isInputFocus, setIsInputFocus] = useState({
    name: true,
    phone: true,
    date: true,
    time: true,
  });
  const [isInputValidated, setIsInputValidated] = useState({
    name: false,
    phone: false,
    service: true,
  });

  const plusOrder = (id) => {
    setDataAll((prevState) => {
      return prevState.map((item) => {
        return {
          ...item,
          services: item.services.map((service) => {
            if (service.id === id) {
              return {
                ...service,
                quantity: service.quantity + 1,
              };
            } else {
              return service;
            }
          }),
        };
      });
    });
  };

  const minOrder = (id) => {
    setDataAll((prevState) => {
      return prevState.map((item) => {
        return {
          ...item,
          services: item.services.map((service) => {
            if (service.id === id) {
              return {
                ...service,
                quantity: service.quantity > 0 ? service.quantity - 1 : 0,
              };
            } else {
              return service;
            }
          }),
        };
      });
    });
  };

  const addNotes = (id, notes) => {
    setDataAll((prevState) => {
      return prevState.map((item) => {
        return {
          ...item,
          services: item.services.map((service) => {
            if (service.id === id) {
              return {
                ...service,
                note: notes,
              };
            } else {
              return service;
            }
          }),
        };
      });
    });
  };

  const submitOrder = (e) => {
    e.preventDefault();
    if (totalPrice === 0) {
      setIsInputValidated({...isInputValidated, service: false});
    }
    if (!isInputValidated.name) {
      nameRefs.current.scrollIntoView();
    }
    if (!isInputValidated.phone) {
      phoneRefs.current.scrollIntoView();
    }
    if (!isInputValidated.service || totalPrice === 0) {
      serviceRefs.current.scrollIntoView();
    }
    if (dateReservation === "") {
      setIsInputFocus({...isInputFocus, date: false});
      dateRefs.current.scrollIntoView();
    }
    if (timeReservation === "") {
      setIsInputFocus({...isInputFocus, time: false});
      timeRefs.current.scrollIntoView();
    }
    if (
      !isInputValidated.name ||
      !isInputValidated.phone ||
      totalPrice === 0 ||
      dateReservation === "" ||
      timeReservation === ""
    ) {
      return;
    }
    const formData = new FormData(e.target);
    const date = `${dateReservation.year}-${
      dateReservation.month < 10
        ? `0${dateReservation.month}`
        : dateReservation.month
    }-${
      dateReservation.day < 10 ? `0${dateReservation.day}` : dateReservation.day
    }`;
    let dateTime = new Date(combineDateTime(date, timeReservation));
    formData.append("appointmentDate", dateTime.toISOString());
    // prepare any data object or custom variable
    let preparedForm = Object.fromEntries(formData);
    preparedForm["referenceSourceId"] = selectCustomerGetInfo.value;
    preparedForm["services"] = combineServices();
    // send data
    postReservation(preparedForm);
  };

  const postReservation = async (formData) => {
    try {
      const {data} = await api.post("/v1/reservations", formData);
      if (data) {
        navigate("/form/confirmation", {
          state: {
            api: data,
            form: formData,
          },
        });
      }
    } catch (error) {
      setModalAlert({
        alertTitle: `Error ${error.response.status}`,
        alertMessage:
          error.response.status === 0
            ? "Tidak ada koneksi internet."
            : "Terjadi kesalahan saat mengirimkan data form ke server",
      });
      setModalOpen({status: true, type: "alert"});
    }
  };

  const verifyPhone = (intValue) => {
    let regex = new RegExp(/^(\+62|62|0)8[1-9][0-9]{5,13}$/);
    if (regex.test(intValue) === true) {
      setIsInputValidated({...isInputValidated, phone: true});
    } else {
      setIsInputValidated({...isInputValidated, phone: false});
    }
  };

  const combineServices = () => {
    let temp = [];
    dataAll.forEach((item) => {
      item.services.forEach((service) => {
        if (service.quantity > 0 && service.note !== "") {
          temp.push({
            id: service.id,
            note: service.note,
            quantity: service.quantity,
            category: item.name,
          });
        } else if (service.quantity > 0 && service.note === "") {
          temp.push({
            id: service.id,
            quantity: service.quantity,
            category: item.name,
          });
        }
      });
    });
    return temp;
  };

  useEffect(() => {
    getCategories();
    getInfo();
  }, []);

  useEffect(() => {
    if (selectCustomerGetInfo !== "") {
      setIsInputFocus({...isInputFocus, refSource: false});
    }
  }, [selectCustomerGetInfo]);

  useEffect(() => {
    let temp = 0;
    dataAll.forEach((item) => {
      item.services.forEach((service) => {
        if (service.quantity > 0) {
          temp += service.quantity * service.rate;
        }
      });
    });
    setTotalPrice(temp);
    if (temp > 0) setIsInputValidated({...isInputValidated, service: true});
  }, [dataAll]);

  return (
    <>
      <ModalAlert
        isOpen={modalOpen.status && modalOpen.type === "alert"}
        closeModal={() => {
          setModalOpen({status: false, type: ""});
          setModalAlert({alertTitle: "", alertMessage: ""});
        }}
        alertTitle={modalAlert.alertTitle}
        alertMessage={modalAlert.alertMessage}
      />
      <section
        id="form-pemesanan"
        className="grid grid-cols-1 sm:grid-cols-12 mx-auto px-4 lg:px-4 xl:px-0 lg:max-w-7xl relative gap-10 lg:gap-y-6 lg:gap-x-10 py-4 md:pt-[18px] md:pb-12"
      >
        <div className="col-span-1 sm:col-span-12 lg:col-end-11 lg:col-span-8">
          <SliderPromo />
        </div>
        <div className="col-span-1 sm:col-span-12 lg:col-end-8 lg:col-span-5">
          <form
            className="flex flex-col gap-6"
            id="form-reservasi"
            onSubmit={submitOrder}
          >
            <div className="flex flex-col gap-2">
              <TextField
                isRequired={true}
                refs={nameRefs}
                label={"Nama Lengkap"}
                inputId={"nama-lengkap"}
                type={"text"}
                inputName={"name"}
                placeholder={"Masukkan Nama Lengkap"}
                helper={
                  !isInputFocus.name &&
                  !isInputValidated.name && (
                    <span className="text-red100">Nama Tidak Boleh Kosong</span>
                  )
                }
                onBlur={() => {
                  setIsInputFocus({...isInputFocus, name: false});
                }}
                setValue={(e) => {
                  setIsInputFocus({...isInputFocus, name: true});
                  if (e.target.value !== "") {
                    setIsInputValidated({
                      ...isInputValidated,
                      name: true,
                    });
                  } else {
                    setIsInputValidated({
                      ...isInputValidated,
                      name: false,
                    });
                  }
                }}
              />
            </div>
            <div className="flex flex-col gap-2">
              <TextField
                isRequired={true}
                refs={phoneRefs}
                pattern="^-?[0-9]\d*\.?\d*$"
                label={"Nomor Handphone"}
                inputId={"phone"}
                type={"tel"}
                inputName={"phone"}
                placeholder={"Masukkan Nomor Handphone"}
                value={phoneNumber}
                autoComplete={"nope"}
                onBlur={() => {
                  setIsInputFocus({...isInputFocus, phone: false});
                  verifyPhone(phoneNumber);
                }}
                helper={
                  !!phoneNumber && !isInputFocus.phone ? (
                    isInputValidated.phone ? null : (
                      <span className="text-red100">Nomor Tidak Valid</span>
                    )
                  ) : null
                }
                setValue={(e) => {
                  setIsInputFocus({...isInputFocus, phone: true});
                  const val = e.target.value;
                  if (e.target.validity.valid) setPhoneNumber(e.target.value);
                  else if (val === "" || val === "-") setPhoneNumber(val);
                }}
              />
            </div>
            <div className="flex flex-col gap-2">
              <TextField
                isRequired={true}
                label={"Email"}
                inputId={"email"}
                type={"email"}
                inputName={"email"}
                placeholder={"Masukkan Email"}
              />
            </div>
            <div className="flex flex-col gap-2">
              <TextArea
                isRequired={true}
                label={"Alamat"}
                inputId={"address"}
                inputName={"address"}
                placeholder={"Masukkan Alamat"}
              />
            </div>
            <div className="flex flex-col gap-2">
              {info?.length > 0 ? (
                <>
                  <div
                    className="flex flex-row items-center gap-1.5"
                    ref={refSourceRefs}
                  >
                    <label>Anda mengetahui Cleansheet dari mana?</label>
                  </div>
                  <Dropdown
                    aditional={
                      "w-full rounded-2xl text-default focus:bg-white focus:ring-bright"
                    }
                    custom={"w-full"}
                    label={
                      !!selectCustomerGetInfo
                        ? selectCustomerGetInfo.label
                        : "Pilih salah satu"
                    }
                  >
                    <div className="rounded-lg ring-1 ring-bright flex flex-col items-start shadow-lg shadow-shadowBright overflow-hidden">
                      <div className="relative w-full h-12">
                        <input
                          type="button"
                          value=""
                          onClick={() => setSelectCustomerGetInfo("")}
                          className={
                            (!!selectCustomerGetInfo
                              ? "bg-white hover:bg-bright text-grey-500 "
                              : "bg-primary text-white ") + "px-4 py-3 w-full"
                          }
                        />
                        <span
                          className={
                            "absolute top-0 left-0 bottom-0 right-0 flex flex-row items-center px-4 py-3 not-italic font-normal text-sm leading-5 text-left w-full cursor-default select-none" +
                            (!!selectCustomerGetInfo
                              ? " text-grey-400"
                              : " text-white")
                          }
                        >
                          Pilih salah satu
                        </span>
                      </div>
                      {info?.map((data, dataId) => (
                        <React.Fragment key={"getInformation" + dataId}>
                          <DropdownList
                            target={selectCustomerGetInfo}
                            setTarget={setSelectCustomerGetInfo}
                            id={data.id}
                            value={data.name}
                          />
                        </React.Fragment>
                      ))}
                    </div>
                  </Dropdown>
                </>
              ) : (
                <div className="flex flex-row items-center gap-1.5">
                  <div className="not-italic font-normal text-base leading-[22px] tracking-[0.005em] text-slateLight">
                    Terjadi kesalahan memperoleh data dari server, silahkan muat
                    ulang atau tunggu beberapa saat lagi.
                  </div>
                </div>
              )}
            </div>
            <div className="flex flex-col xs:flex-row gap-6 items-stretch">
              <div className="flex flex-col gap-2 flex-grow">
                <>
                  <div
                    className="flex flex-row items-center gap-1.5"
                    ref={dateRefs}
                  >
                    <span>Tanggal Pengerjaan</span>
                    <div className="w-1.5 h-1.5 rounded-full bg-red100" />
                  </div>
                  <Dropdown
                    aditional="w-full rounded-2xl text-neutralDefault focus:bg-white focus:ring-bright"
                    custom="w-fit xs:w-full min-w-fit right-0 xs:left-0"
                    label={`${
                      dateReservation.day &&
                      dateReservation.month &&
                      dateReservation.year
                        ? `${dateReservation.day}/${dateReservation.month}/${dateReservation.year}`
                        : "DD/MM/YYYY"
                    }`}
                    dropdownIcon={<CalendarIcon className="w-4 h-4" />}
                  >
                    <div className="max-w-4xl shadow-lg shadow-shadowBright rounded-2xl bg-white border border-primaryBright">
                      <DateCalendarPicker
                        minDate="today"
                        value={dateReservation}
                        setValue={setDateReservation}
                      />
                    </div>
                  </Dropdown>
                  {dateReservation === "" && isInputFocus.date === false && (
                    <div className="not-italic font-normal text-sm leading-5 text-red100">
                      Mohon inputkan tanggal{" "}
                      <span className="inline xs:block">
                        pengerjaan yang valid
                      </span>
                    </div>
                  )}
                </>
              </div>
              <div className="flex flex-col gap-2 flex-grow">
                <>
                  <div
                    className="flex flex-row items-center gap-1.5"
                    ref={timeRefs}
                  >
                    <span>Jam Pengerjaan</span>
                    <div className="w-1.5 h-1.5 rounded-full bg-red100" />
                  </div>
                  <Dropdown
                    aditional="w-full rounded-2xl text-neutralDefault focus:bg-white focus:ring-bright"
                    custom="w-full min-w-fit right-0"
                    label={timeReservation || "HH:ss"}
                    dropdownIcon={<ClockIcon className="w-5 h-5" />}
                  >
                    <div className="max-w-4xl shadow-lg shadow-shadowBright rounded-2xl bg-white border border-primaryBright">
                      <TimePicker
                        value={timeReservation}
                        setValue={setTimeReservation}
                      />
                    </div>
                  </Dropdown>
                  {timeReservation === "" && !isInputFocus.time && (
                    <div className="not-italic font-normal text-sm leading-5 text-red100">
                      Mohon inputkan jam pengerjaan{" "}
                      <span className="inline xs:block">
                        antara 07:00 - 19:00
                      </span>
                    </div>
                  )}
                </>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              {dataAll?.length > 0 ? (
                <ServiceList
                  isRequired={true}
                  inputId={"list"}
                  label={"Jenis Layanan"}
                  dataAll={dataAll}
                  plusOrder={plusOrder}
                  minOrder={minOrder}
                  addNotes={addNotes}
                  refs={serviceRefs}
                  loading={isServiceFetching}
                  getServices={getServicesCategories}
                  isServiceValidated={isInputValidated.service}
                />
              ) : (
                <div className="flex flex-row items-center gap-1.5">
                  <div className="not-italic font-normal text-base leading-[22px] tracking-[0.005em] text-slateLight">
                    Terjadi kesalahan memperoleh data dari server, silahkan muat
                    ulang atau tunggu beberapa saat lagi.
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>
        <div className="col-span-1 sm:col-span-12 lg:col-end-11 lg:col-span-3 relative">
          <div className="sticky top-[125px] flex flex-col gap-4">
            <Card heading={"Ringkasan Pesanan"}>
              <dl className="flex flex-col gap-4">
                {dataAll.map((data, dataId) => (
                  <React.Fragment key={"service" + dataId}>
                    {data.services.map((service, serviceId) => (
                      <React.Fragment key={"service" + serviceId}>
                        {service.quantity > 0 ? (
                          <div
                            key={"selectedLayanan" + serviceId}
                            className="flex flex-row items-start gap-2"
                          >
                            <dt className="not-italic font-normal text-sm leading-5 tracking-[0.005em] text-default flex-auto">
                              {isStringContainsBrackets(service.name)
                                ? getStringAfterBrackets(service.name)
                                : service.name}
                            </dt>
                            <dd className="not-italic font-normal text-sm leading-5 text-right tracking-[0.005em] text-default whitespace-nowrap">
                              Rp{" "}
                              {numberWithCommas(
                                service.rate * service.quantity
                              )}
                            </dd>
                          </div>
                        ) : null}
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                ))}
                <div className="flex flex-row items-start border-t border-t-neutralBright pt-4 gap-2">
                  <dt className="not-italic font-bold text-base leading-[22px] tracking-[0.005em] text-dark flex-auto">
                    Total Harga
                  </dt>
                  <dd className="not-italic font-bold text-base leading-[22px] text-right tracking-[0.005em] text-dark whitespace-nowrap">
                    Rp {numberWithCommas(totalPrice)}
                  </dd>
                </div>
                <Button type="submit" form={"form-reservasi"} btnType="primary">
                  <div className="w-full text-center">Pesan</div>
                </Button>
              </dl>
            </Card>
          </div>
        </div>
      </section>
    </>
  );
};

export default FormPesanPage;
