import SectionLayanan from "../../../components/LandingPage/ServicesSection/ManajemenProgram/SectionLayanan";
import TabTitle from "../../../utils/TabTitle";

const ManajemenProgramPage = () => {
    TabTitle("Manajemen Program - LangkahBaik");
    return (
        <>
            <SectionLayanan />
        </>
    );
}

export default ManajemenProgramPage;