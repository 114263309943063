import Icon from "@mdi/react";
import usePrompt from "../../hooks/usePrompt";
import {
  mdiCalendar,
  mdiChevronRight,
  mdiClock,
  mdiClose,
  mdiEmail,
  mdiHome,
  mdiPercent,
  mdiPhone,
  mdiRadioboxBlank,
  mdiRadioboxMarked,
} from "@mdi/js";
import React, {Fragment, useEffect, useState} from "react";
import {isDesktop} from "react-device-detect";
import Modal from "../../components/Modal";
import {useLocation, useNavigate} from "react-router-dom";
import Card from "../../components/Card";
import Button from "../../components/Button";
import api from "../../api";
import TabTitle from "../../utils/TabTitle";
import ModalAlert from "../../components/ModalAlert";
import TextField from "../../components/TextField";
import CardPromo from "../../components/FormPage/CardPromo";
import logoBNI from "../../assets/images/payments/logo-bni.png";
import logoMandiri from "../../assets/images/payments/logo-mandiri.png";
import logoBankPermata from "../../assets/images/payments/logo-permatabank.png";
import logoBRI from "../../assets/images/payments/logo-bri.png";
import logoBCA from "../../assets/images/payments/logo-bca.png";
import logoBSI from "../../assets/images/payments/logo-bsi.png";
import logoDana from "../../assets/images/payments/logo-dana.png";
import logoLinkAja from "../../assets/images/payments/logo-linkaja.png";
import logoShopeePay from "../../assets/images/payments/logo-shopeepay.png";
import {
  delay,
  getStringAfterBrackets,
  isStringContainsBrackets,
  numberWithCommas,
} from "../../utils/Tools";

const FormDetailPage = () => {
  TabTitle("Konfirmasi Pemesanan - Cleansheet");
  let navigate = useNavigate();
  let {state} = useLocation();
  const [isModalOpen, setIsModalOpen] = useState({status: false, type: ""});
  const [modalAlert, setModalAlert] = useState({
    alertTitle: "",
    alertMessage: "",
  });
  const [alamat, setAlamat] = useState("");
  const [postStateData, setPostStateData] = useState(null);
  const [idReservation, setIdReservation] = useState("");
  const [name, setName] = useState("");
  const [telephone, setTelephone] = useState("");
  const [email, setEmail] = useState("");
  const [dateReserve, setDateReserve] = useState("");
  const [timeReserve, setTimeReserve] = useState("");
  const [data, setData] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [isConfirm, setIsConfirm] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [paymentMethodList, setPaymentMethodList] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState(null);
  // state to store list coupon to display
  const [coupon, setCoupon] = useState([]);
  // state for store user input/select coupon before store to active, value is coupon code
  const [couponInput, setCouponInput] = useState({
    inputField: "",
    selected: "",
    active: "",
  });
  const [listCouponMessage, setListCouponMessage] = useState("");
  // state for store active coupon data
  const [couponValue, setCouponValue] = useState("");
  const [couponActive, setCouponActive] = useState({});

  const getPaymentMethod = async (time) => {
    try {
      const {data} = await api.get("/v1/payment-methods");
      let today = new Date();
      let nextAfterToday = new Date(today);
      nextAfterToday.setDate(nextAfterToday.getDate() + 2);
      nextAfterToday.setHours(0, 0, 0, 0);
      let temp = [];
      data.data.items.forEach((item) => {
        if (item.name.includes("BNI")) {
          temp.push({...item, logo: logoBNI});
        } else if (item.name.includes("Mandiri")) {
          temp.push({...item, logo: logoMandiri});
        } else if (item.name.includes("Permata")) {
          temp.push({...item, logo: logoBankPermata});
        } else if (item.name.includes("BRI")) {
          temp.push({...item, logo: logoBRI});
        } else if (item.name.includes("BCA")) {
          temp.push({...item, logo: logoBCA});
        } else if (item.name.includes("Dana")) {
          temp.push({...item, logo: logoDana});
        } else if (item.name.includes("LinkAja")) {
          temp.push({...item, logo: logoLinkAja});
        } else if (item.name.includes("ShopeePay") && !isDesktop) {
          temp.push({...item, logo: logoShopeePay});
        } else if (item.name.includes("BSI")) {
          temp.push({...item, logo: logoBSI});
        }

        if (item.categoryId === "1") {
          temp[temp.length - 1].category = "Virtual Account";
        } else if (item.categoryId === "2") {
          temp[temp.length - 1].category = "E-Wallet";
        } else if (item.categoryId === "3") {
          temp[temp.length - 1].category = "Transfer Bank";
        }
      });
      temp.sort((a, b) => {
        if (a.categoryId < b.categoryId) {
          return -1;
        }
        if (a.categoryId > b.categoryId) {
          return 1;
        }
        return 0;
      });
      if (time < nextAfterToday) {
        setPaymentMethodList(
          temp.filter((item) => !item.name.includes("Transfer"))
        );
      } else {
        setPaymentMethodList(temp);
      }
    } catch ({response}) {
      setModalAlert({
        alertTitle: `Galat ${response.status}`,
        alertMessage:
          response.status === 0
            ? "Tidak ada koneksi internet. Silahkan coba lagi setelah koneksi internet stabil."
            : "Gagal mendapatkan data pembayaran dari server. Silahkan coba lagi atau hubungi admin Cleansheet.",
      });
      setIsModalOpen({status: true, type: "alert"});
    }
  };

  const submitData = async (e) => {
    e.preventDefault();
    let body = {};
    if (paymentMethod.id) {
      body = {
        paymentMethodId: paymentMethod.id,
      };
    }
    if (couponActive.id) {
      body = {
        ...body,
        couponId: couponActive.id,
      };
    }
    if (!!paymentMethod) {
      try {
        await api
          .post(`/v1/reservations/${idReservation}/pay`, body)
          .then((response) => {
            setIsConfirm(true);
            setPostStateData(response.data);
          })
          .catch(({response}) => {
            setIsConfirm(false);
            setModalAlert({
              alertTitle: `Galat ${response.status}`,
              alertMessage:
                response.status === 0
                  ? "Tidak ada koneksi internet. Silahkan coba lagi setelah koneksi internet stabil."
                  : "Gagal mengirim data ke server. Silahkan coba beberapa saat lagi atau hubungi admin Cleansheet.",
            });
          });
        setIsConfirmModalOpen(false);
      } catch ({response}) {
        setIsConfirm(false);
        setIsConfirmModalOpen(false);
        setModalAlert({
          alertTitle: `Galat ${response.status}`,
          alertMessage:
            response.status === 0
              ? "Tidak ada koneksi internet. Silahkan coba lagi setelah koneksi internet stabil."
              : "Gagal mengirim data ke server. Silahkan coba beberapa saat lagi atau hubungi admin Cleansheet.",
        });
        setIsModalOpen({status: true, type: "alert"});
      }
    }
  };

  const getListCoupon = async () => {
    try {
      const {data} = await api.get("/v1/vouchers");
      setCoupon(data.data.items);
      setListCouponMessage("");
    } catch ({response}) {
      setListCouponMessage("Gagal memperoleh data kupon");
    }
  };

  const searchCoupon = async (string) => {
    try {
      const {data} = await api.get(`/v1/vouchers/${string}`);
      if (data) {
        let temp = [];
        temp.push(data.data);
        setCoupon(temp);
        setCouponInput({...couponInput, selected: data.data.code});
      }
      setListCouponMessage("");
    } catch ({response}) {
      let message = response.data.error.message;
      if (message) {
        setListCouponMessage(message);
      } else {
        setListCouponMessage("Gagal memperoleh data kupon");
      }
    }
  };

  const getCouponId = async (string) => {
    try {
      const {data} = await api.get(`/v1/vouchers/${string}`);
      checkCoupon(data.data.id, string);
    } catch ({response}) {
      setModalAlert({
        alertTitle: `Galat ${response.status}`,
        alertMessage:
          response.status === 0
            ? "Tidak ada koneksi internet. Silahkan coba lagi setelah koneksi internet stabil."
            : "Kode kupon tidak valid. Silahkan coba lagi atau hubungi admin Cleansheet.",
      });
    }
  };

  const checkCoupon = async (voucherId, voucherCode) => {
    try {
      const {data} = await api.get(
        `/v1/voucher/eligible-to-use?voucherId=${voucherId}&reservationId=${idReservation}`
      );
      setModalAlert({
        alertTitle: "Kupon Berhasil Digunakan",
        alertMessage: `Kupon berhasil digunakan. Diskon sebesar Rp ${data.data.totalDiscount} telah ditambahkan.`,
      });
      let temp = data.data;
      temp.id = voucherId;
      setCouponValue(voucherCode);
      setCouponActive(temp);
      setCouponInput({
        inputField: "",
        selected: "",
        active: couponInput.selected,
      });
      getListCoupon();
    } catch ({response}) {
      setModalAlert({
        alertTitle: `Galat ${response.status}`,
        alertMessage:
          response.status === 0
            ? "Tidak ada koneksi internet. Silahkan coba lagi setelah koneksi internet stabil."
            : response.data.error.message,
      });
      setCouponValue("");
    }
  };

  const handleBtnPromoClick = () => {
    setIsModalOpen({status: false, type: ""});
    if (!couponInput.selected) {
      return;
    }
    if (
      (couponInput.inputField !== "" || couponInput.selected !== "") &&
      couponInput.active === ""
    ) {
      getCouponId(couponInput.selected);
    } else if (
      couponInput.selected !== "" &&
      couponInput.active !== "" &&
      couponInput.active !== couponInput.selected
    ) {
      getCouponId(couponInput.selected);
    }
  };

  usePrompt(
    "Leave this page will delete data, continue?",
    !isConfirm && !!state
  );

  useEffect(() => {
    const displayAlert = async () => {
      if (modalAlert.alertTitle !== "" && isConfirmModalOpen === false) {
        await delay(1000);
        setIsModalOpen({status: true, type: "alert"});
      }
    };

    displayAlert();
  }, [modalAlert, isConfirmModalOpen]);

  useEffect(() => {
    if (isConfirm) {
      navigate("/form/payment", {
        state: {
          api: postStateData.data,
          form: paymentMethod,
          id: idReservation,
          paymentId: paymentMethod.id,
        },
        replace: true,
      });
    }
  }, [isConfirm]);

  useEffect(() => {
    if (!!state) {
      setIsConfirm(false);
      setIdReservation(state.api.data.id);
      setAlamat(state.api.data.address);
      setName(state.api.data.occupant);
      setTelephone(state.api.data.phone);
      setEmail(state.form.email);
      let date = new Date(state.api.data.appointmentDate);
      setDateReserve(
        date.toLocaleString("id-ID", {
          weekday: "long",
          day: "2-digit",
          month: "long",
          year: "numeric",
        })
      );
      setTimeReserve(
        date.toLocaleTimeString([], {
          hour12: false,
          hour: "2-digit",
          minute: "2-digit",
        })
      );
      let temp = [];
      state.api.data.services.forEach((item) => {
        let tempItem = state.form.services.find((x) => x.id === item.id);
        if (tempItem) {
          temp.push({
            ...item,
            quantity: tempItem.quantity,
            category: tempItem.category,
            note: tempItem.note,
          });
        }
      });
      setData(temp);
      setTotalPrice(state.api.data.totalPrice);
      getPaymentMethod(date);
      getListCoupon();
    } else {
      navigate("/form", {replace: true});
    }
  }, []);

  return (
    <>
      <ModalAlert
        isOpen={isModalOpen.status && isModalOpen.type === "alert"}
        closeModal={() => {
          setIsModalOpen({status: false, type: ""});
          setModalAlert({alertTitle: "", alertMessage: ""});
        }}
        alertTitle={modalAlert.alertTitle}
        alertMessage={modalAlert.alertMessage}
      />
      <Modal
        isOpen={isModalOpen.status && isModalOpen.type === "voucher"}
        closeModal={() => {
          setIsModalOpen({status: false, type: ""});
        }}
      >
        <div className="flex flex-col gap-8">
          <div className="flex flex-row items-center">
            <span className="not-italic montserrat font-bold text-2xl leading-[34px] items-center tracking-[0.005em] text-primary flex-auto">
              Pakai Promo
            </span>
            <div className="flex flex-row items-center gap-2">
              <button
                type="button"
                disabled={couponInput.active === ""}
                onClick={() => {
                  setCouponInput({...couponInput, active: ""});
                  setCouponValue("");
                  setCouponActive(null);
                }}
                className="montserrat not-italic font-bold text-base leading-[140%] tracking-[0.005em] disabled:text-default text-primary"
              >
                Reset Promo
              </button>
              <button
                onClick={() => {
                  setIsModalOpen({status: false, type: ""});
                }}
              >
                <Icon path={mdiClose} size={1} className="text-default" />
              </button>
            </div>
          </div>
          <form
            className="flex flex-row items-center gap-4 justify-between"
            onSubmit={(e) => {
              e.preventDefault();
              if (couponInput.inputField !== "") {
                searchCoupon(couponInput.inputField);
              }
            }}
          >
            <div className="flex-auto">
              <TextField
                customStyle={" w-full"}
                placeholder={"Masukkan kode promo"}
                inputName={"coupon"}
                value={couponInput.inputField}
                setValue={(e) =>
                  setCouponInput({...couponInput, inputField: e.target.value})
                }
                onBlur={(e) => {
                  if (e.target.value !== "") {
                    setCouponInput({
                      ...couponInput,
                      inputField: e.target.value,
                    });
                  } else {
                    setCouponInput({
                      ...couponInput,
                      inputField: "",
                    });
                    getListCoupon();
                  }
                }}
                isRequired
              />
            </div>
            <Button
              isDisabled={couponInput.inputField === ""}
              type="submit"
              btnType="primary"
              customStyle=" rounded-full"
            >
              <span className="not-italic font-bold text-base leading-[140%] tracking-[0.005em] text-primaryLight">
                Terapkan
              </span>
            </Button>
          </form>
          <div className="flex flex-col items-start">
            <div className="montserrat not-italic font-bold text-xl leading-[140%] tracking-[0.005em] text-dark">
              Pilih promo
            </div>
            <div className="not-italic font-normal text-base leading-[140%] tracking-[0.005em] text-default">
              Yuk, gunakan promo biar makin hemat!
            </div>
          </div>
          <div className="flex flex-col gap-3 -mx-6 px-6 max-h-[400px] overflow-y-auto">
            {coupon.length > 0 ? (
              coupon.map((item) => (
                <React.Fragment key={"coupon" + item.id}>
                  <CardPromo
                    data={item}
                    selected={couponInput.selected}
                    active={couponInput.active}
                    handleClick={(code) => {
                      if (couponInput.selected === code) {
                        setCouponInput({
                          ...couponInput,
                          selected: "",
                        });
                      } else if (
                        couponInput.selected !== code &&
                        !couponInput.active
                      ) {
                        setCouponInput({
                          ...couponInput,
                          selected: code,
                        });
                      }
                    }}
                  />
                </React.Fragment>
              ))
            ) : (
              <div className="not-italic font-normal text-base leading-[140%] tracking-[0.005em] text-default">
                {listCouponMessage || "Tidak ada promo yang tersedia"}
              </div>
            )}
          </div>
          <div className="-mx-6 -mb-6 rounded-br-2xl rounded-bl-2xl px-6 drop-shadow-top bg-white py-6">
            <Button
              customStyle=" w-full rounded-full"
              click={handleBtnPromoClick}
            >
              <div className="not-italic font-bold text-base leading-[140%] tracking-[0.005em] text-center w-full">
                {((couponInput.selected !== "" || couponInput.active !== "") &&
                  "Pilih Promo") ||
                  "Pesan Tanpa Promo"}
              </div>
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={isConfirmModalOpen}
        closeModal={() => {
          setIsConfirmModalOpen(false);
          setPaymentMethod(null);
        }}
      >
        <div className="flex flex-col gap-8">
          <div className="flex flex-row items-center justify-between">
            <span className="not-italic montserrat font-bold text-2xl leading-[34px] items-center tracking-[0.005em] text-primary">
              Metode Pembayaran
            </span>
            <button
              onClick={() => {
                setIsConfirmModalOpen(false);
                setPaymentMethod(null);
              }}
            >
              <Icon path={mdiClose} size={1} className="text-default" />
            </button>
          </div>
          <form onSubmit={submitData}>
            <div className="flex flex-col">
              <div className="flex flex-col items-start self-stretch gap-3 -mx-6 px-6 pb-6 max-h-[400px] overflow-y-auto">
                {paymentMethodList?.length > 0 ? (
                  paymentMethodList.map((data, index) => (
                    <Fragment key={"paymentMethod" + data.id}>
                      {paymentMethodList[index].category !==
                      paymentMethodList[index - 1]?.category ? (
                        <div className="not-italic font-bold text-sm leading-[140%] tracking-[0.005em] text-primary">
                          {data.category}
                        </div>
                      ) : null}
                      <button
                        type="button"
                        className={
                          (paymentMethod
                            ? paymentMethod.id === data.id
                              ? "border-primary "
                              : "border-neutralBright "
                            : "border-neutralBright ") +
                          "flex items-center px-4 py-1 gap-2 bg-white border rounded-2xl self-stretch"
                        }
                        onClick={() =>
                          setPaymentMethod({id: data.id, label: data.name})
                        }
                      >
                        <div className="h-[44px] w-[44px] flex items-center">
                          {data.logo && (
                            <img
                              src={data.logo}
                              alt={data.name}
                              className="w-full"
                            />
                          )}
                        </div>
                        <div className="not-italic font-normal text-base leading-[22px] tracking-[0.005em] text-dark flex-grow text-left">
                          {data.name}
                        </div>
                        <div>
                          {paymentMethod ? (
                            paymentMethod.id === data.id ? (
                              <Icon
                                path={mdiRadioboxMarked}
                                size={1}
                                className="text-primary"
                              />
                            ) : (
                              <Icon
                                path={mdiRadioboxBlank}
                                size={1}
                                className="text-neutralBright"
                              />
                            )
                          ) : (
                            <Icon
                              path={mdiRadioboxBlank}
                              size={1}
                              className="text-neutralBright"
                            />
                          )}
                        </div>
                      </button>
                    </Fragment>
                  ))
                ) : (
                  <div className="not-italic font-normal text-base leading-[22px] tracking-[0.005em] text-slateLight">
                    Saat ini tidak ada metode pembayaran yang tersedia. Mohon
                    coba lagi nanti atau hubungi admin Cleansheet.
                  </div>
                )}
              </div>
              <div className="flex flex-row items-start -mx-6 -mb-6 rounded-br-2xl rounded-bl-2xl px-6 drop-shadow-top bg-white py-6 gap-16">
                <div className="flex flex-col items-start gap-1">
                  <div className="not-italic font-normal text-sm leading-5 tracking-[0.005em] text-dark">
                    Total Tagihan
                  </div>
                  <div className="not-italic font-bold text-base leading-[22px] tracking-[0.005em] text-dark">
                    Rp {numberWithCommas(totalPrice)}
                  </div>
                </div>
                <Button
                  type="submit"
                  btnType="primary"
                  customStyle=" rounded-full flex-grow justify-center"
                >
                  <span>Bayar</span>
                </Button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
      <section className="grid grid-cols-1 sm:grid-cols-12 mx-auto px-4 lg:px-4 xl:px-0 lg:max-w-7xl relative gap-10 py-4 md:py-12">
        <div className="hidden lg:block lg:col-span-2"></div>
        <div className="col-span-1 sm:col-span-12 lg:col-span-5 flex flex-col gap-10">
          <div className="flex flex-col items-start p-0 gap-2">
            <h3 className="not-italic font-bold text-base leading-[22px] tracking-[0.005em] text-primary">
              Informasi Pemesanan
            </h3>
            <div className="montserrat not-italic font-bold text-xl leading-7 tracking-[0.005em] text-dark">
              {name}
            </div>
            <ul className="flex flex-col gap-2">
              <li className="flex flex-row items-start gap-2">
                <div>
                  <Icon path={mdiPhone} size={1} className="text-bright" />
                </div>
                <span className="not-italic font-normal text-base leading-[22px] tracking-[0.005em] text-dark">
                  {telephone}
                </span>
              </li>
              <li className="flex flex-row items-start gap-2">
                <div>
                  <Icon path={mdiEmail} size={1} className="text-bright" />
                </div>
                <span className="not-italic font-normal text-base leading-[22px] tracking-[0.005em] text-dark">
                  {!!email ? email : "-"}
                </span>
              </li>
              <li className="flex flex-row items-start gap-2">
                <div>
                  <Icon path={mdiHome} size={1} className="text-bright" />
                </div>
                <span className="not-italic font-normal text-base leading-[22px] tracking-[0.005em] text-dark">
                  {alamat}
                </span>
              </li>
              <li className="flex flex-row items-start gap-2">
                <div>
                  <Icon path={mdiCalendar} size={1} className="text-bright" />
                </div>
                <span className="not-italic font-normal text-base leading-[22px] tracking-[0.005em] text-dark">
                  {dateReserve}
                </span>
              </li>
              <li className="flex flex-row items-start gap-2">
                <div>
                  <Icon path={mdiClock} size={1} className="text-bright" />
                </div>
                <span className="not-italic font-normal text-base leading-[22px] tracking-[0.005em] text-dark">
                  {timeReserve}
                </span>
              </li>
            </ul>
          </div>
          <div className="flex flex-col items-start p-0 gap-2 relative">
            <h3 className="not-italic font-bold text-base leading-[22px] tracking-[0.005em] text-primary z-[5]">
              Service
            </h3>
            <dl className="flex flex-col p-0 w-full z-[4]">
              {data?.map((services, servicesIndex) => (
                <div
                  className="first:-mt-6 mt-0"
                  key={"selectedLayanan" + servicesIndex}
                >
                  {servicesIndex !== 0 ? (
                    data[servicesIndex].category !==
                    data[servicesIndex - 1].category ? (
                      <h4
                        className={
                          "mt-6 montserrat not-italic font-bold text-xl leading-7 tracking-[0.005em] text-dark"
                        }
                      >
                        {services.category}
                      </h4>
                    ) : null
                  ) : (
                    <h4
                      className={
                        "mt-6 montserrat not-italic font-bold text-xl leading-7 tracking-[0.005em] text-dark"
                      }
                    >
                      {services.category}
                    </h4>
                  )}
                  <div className="flex flex-col items-start mt-2 border border-primaryBright rounded-2xl bg-white px-4 py-3 gap-2">
                    <div className="flex flex-row items-center p-0 gap-2 w-full">
                      <div className="flex flex-col items-start p-0 gap-1 flex-grow">
                        <div className="flex flex-row flex-wrap items-center gap-4">
                          <dt className="not-italic font-normal text-base leading-[22px] tracking-[0.005em] text-dark">
                            {isStringContainsBrackets(services.name)
                              ? getStringAfterBrackets(services.name)
                              : services.name}
                          </dt>
                          <div className="flex flex-row items-center gap-1">
                            <div>
                              <Icon
                                path={mdiClock}
                                size={1}
                                className="text-neutralBright"
                              />
                            </div>
                            <span className="not-italic font-normal text-base leading-[22px] tracking-[0.005em] text-default whitespace-nowrap">
                              1 Jam
                            </span>
                          </div>
                        </div>
                        <div className="not-italic font-normal text-sm leading-5 text-right tracking-[0.005em] text-default">
                          Rp {numberWithCommas(services.rate)}
                        </div>
                      </div>
                      <span className="not-italic font-normal text-base leading-[22px] text-center tracking-[0.005em] text-neutralBright">
                        x
                      </span>
                      <div className="not-italic font-normal text-base leading-[22px] text-center tracking-[0.005em] text-dark">
                        {services.quantity}
                      </div>
                    </div>
                    <dd className="not-italic font-normal text-sm leading-5 tracking-[0.005em] text-default self-stretch">
                      {services.note}
                    </dd>
                  </div>
                </div>
              ))}
            </dl>
          </div>
        </div>
        <div className="col-span-1 sm:col-span-12 lg:col-span-3 relative">
          <div className="sticky top-[125px] flex flex-col gap-6">
            <Button
              btnType="secondary"
              click={() => setIsModalOpen({status: true, type: "voucher"})}
            >
              <div className="flex flex-row items-center gap-2 w-full">
                <div>
                  <Icon path={mdiPercent} size={1} className="text-primary" />
                </div>
                <span className="not-italic font-normal text-sm leading-5 tracking-[0.005em] text-dark grow text-left">
                  {couponValue || "Masukkan Kode Promo"}
                </span>
                <div>
                  <Icon
                    path={mdiChevronRight}
                    size={1}
                    className="text-primary"
                  />
                </div>
              </div>
            </Button>
            <Card heading={"Ringkasan Pesanan"}>
              <dl className="flex flex-col gap-4">
                <div className="flex flex-row items-start gap-2">
                  <dt className="not-italic font-normal text-sm leading-5 tracking-[0.005em] text-default flex-auto">
                    Total Harga
                  </dt>
                  <dd className="not-italic font-normal text-sm leading-5 text-right tracking-[0.005em] text-default whitespace-nowrap">
                    Rp {numberWithCommas(totalPrice)}
                  </dd>
                </div>
                {couponValue && (
                  <div className="flex flex-row items-start gap-2">
                    <dt className="not-italic font-normal text-sm leading-5 tracking-[0.005em] text-default flex-auto">
                      Total Discount
                    </dt>
                    <dd className="not-italic font-normal text-sm leading-5 text-right tracking-[0.005em] text-default whitespace-nowrap">
                      Rp {numberWithCommas(couponActive.totalDiscount)}
                    </dd>
                  </div>
                )}
                <div className="flex flex-row items-start border-t border-t-neutralBright pt-4 gap-2">
                  <dt className="not-italic font-bold text-base leading-[22px] tracking-[0.005em] text-dark flex-auto">
                    Total Harga
                  </dt>
                  <dd className="not-italic font-bold text-base leading-[22px] text-right tracking-[0.005em] text-dark whitespace-nowrap">
                    {couponValue
                      ? `Rp ${numberWithCommas(couponActive.totalPaid)}`
                      : `Rp ${numberWithCommas(totalPrice)}`}
                  </dd>
                </div>
                <p className="not-italic font-normal text-sm leading-5 tracking-[0.005em] text-default">
                  *Total harga belum termasuk biaya transportasi
                </p>
                <Button
                  type="button"
                  click={() => setIsConfirmModalOpen(true)}
                  form={"form-1"}
                  btnType="primary"
                >
                  <div className="w-full text-center">Pilih Pembayaran</div>
                </Button>
              </dl>
            </Card>
            <div className="flex flex-col items-start gap-2 p-0">
              <h2 className="not-italic font-bold text-base leading-[22px] tracking-[0.005em] text-primary">
                Disclaimer
              </h2>
              <ol className="list-decimal not-italic font-normal text-sm leading-5 tracking-[0.005em] text-dark pl-4">
                <li>Daya listrik minimal 1300 watt</li>
                <li>TRANSFER dibawah jam 12.00 pengerjaan bisa dimulai H-1</li>
                <li>TRANSFER diatas jam 13.00 pengerjaan dimulai H-2</li>
                <li>
                  reschedule saat hari H, Dikenakan Pinalti Rp. 40.000/petugas
                </li>
                <li>
                  Pembatalan sebelum hari-H pengerjaan, Refund akan dikembalikan
                  sebesar 50%
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FormDetailPage;
