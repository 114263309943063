import SectionHeadline from "../../../components/LandingPage/BlogSection/SectionHeadline";
import SectionNewNews from "../../../components/LandingPage/BlogSection/SectionNewNews";
import TabTitle from "../../../utils/TabTitle";

const BlogPage = () => {
    TabTitle("Blog - Langkah Baik");
    return (
        <>
            <SectionHeadline />
        </>
    );
}

export default BlogPage;