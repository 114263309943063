import news from '../../../assets/images/banner/news.png';

function SectionNewNews() {
    return (
        <section className="py-16" id="headline">
            <article className="relative max-w-container mx-auto px-6 md:px-4">
                <div className="text-[22px] font-bold mb-7">Sorotan Hari Ini</div>
                <div className="grid grid-rows-5 gap-11">
                    <div className="grid grid-cols-4 gap-6">
                        <div className="col-span-1 max-h-[178px] max-w-[258] object-cover"><img className="w-full" src={news} alt="news" /></div>
                        <div className="col-span-3">
                            <div className="text-xs text-default">26 Juli 2022</div>
                            <div className="text-2xl text-dark montserrat font-bold mt-1 mb-1">Fogging Asap dan Embun, Mana yang Lebih Aman?</div>
                            <p className="text-sm text-default">Fogging bukan merupakan cara mencegah perkembangbiakan nyamuk disekitar kita, atau mensterilkan ruangan dengan desinfektan. Selain itu, fogging nyamuk hanya mematikan nyamuk dewasa saja, sedangkan telur dan jentik nyamuk, tidak akan mati dengan fogging. Namun akan tetap hidup dan berkembang menjadi nyamuk dewasa dalam 2-6 hari.</p>
                        </div>
                    </div>
                    <div className="grid grid-cols-4 gap-6">
                        <div className="col-span-1 max-h-[178px] max-w-[258] object-cover"><img className="w-full" src={news} alt="news" /></div>
                        <div className="col-span-3">
                            <div className="text-xs text-default">26 Juli 2022</div>
                            <div className="text-2xl text-dark montserrat font-bold mt-1 mb-1">Fogging Asap dan Embun, Mana yang Lebih Aman?</div>
                            <p className="text-sm text-default">Fogging bukan merupakan cara mencegah perkembangbiakan nyamuk disekitar kita, atau mensterilkan ruangan dengan desinfektan. Selain itu, fogging nyamuk hanya mematikan nyamuk dewasa saja, sedangkan telur dan jentik nyamuk, tidak akan mati dengan fogging. Namun akan tetap hidup dan berkembang menjadi nyamuk dewasa dalam 2-6 hari.</p>
                        </div>
                    </div>
                    <div className="grid grid-cols-4 gap-6">
                        <div className="col-span-1 max-h-[178px] max-w-[258] object-cover"><img className="w-full" src={news} alt="news" /></div>
                        <div className="col-span-3">
                            <div className="text-xs text-default">26 Juli 2022</div>
                            <div className="text-2xl text-dark montserrat font-bold mt-1 mb-1">Fogging Asap dan Embun, Mana yang Lebih Aman?</div>
                            <p className="text-sm text-default">Fogging bukan merupakan cara mencegah perkembangbiakan nyamuk disekitar kita, atau mensterilkan ruangan dengan desinfektan. Selain itu, fogging nyamuk hanya mematikan nyamuk dewasa saja, sedangkan telur dan jentik nyamuk, tidak akan mati dengan fogging. Namun akan tetap hidup dan berkembang menjadi nyamuk dewasa dalam 2-6 hari.</p>
                        </div>
                    </div>
                    <div className="grid grid-cols-4 gap-6">
                        <div className="col-span-1 max-h-[178px] max-w-[258] object-cover"><img className="w-full" src={news} alt="news" /></div>
                        <div className="col-span-3">
                            <div className="text-xs text-default">26 Juli 2022</div>
                            <div className="text-2xl text-dark montserrat font-bold mt-1 mb-1">Fogging Asap dan Embun, Mana yang Lebih Aman?</div>
                            <p className="text-sm text-default">Fogging bukan merupakan cara mencegah perkembangbiakan nyamuk disekitar kita, atau mensterilkan ruangan dengan desinfektan. Selain itu, fogging nyamuk hanya mematikan nyamuk dewasa saja, sedangkan telur dan jentik nyamuk, tidak akan mati dengan fogging. Namun akan tetap hidup dan berkembang menjadi nyamuk dewasa dalam 2-6 hari.</p>
                        </div>
                    </div>
                    <div className="grid grid-cols-4 gap-6">
                        <div className="col-span-1 max-h-[178px] max-w-[258] object-cover"><img className="w-full" src={news} alt="news" /></div>
                        <div className="col-span-3">
                            <div className="text-xs text-default">26 Juli 2022</div>
                            <div className="text-2xl text-dark montserrat font-bold mt-1 mb-1">Fogging Asap dan Embun, Mana yang Lebih Aman?</div>
                            <p className="text-sm text-default">Fogging bukan merupakan cara mencegah perkembangbiakan nyamuk disekitar kita, atau mensterilkan ruangan dengan desinfektan. Selain itu, fogging nyamuk hanya mematikan nyamuk dewasa saja, sedangkan telur dan jentik nyamuk, tidak akan mati dengan fogging. Namun akan tetap hidup dan berkembang menjadi nyamuk dewasa dalam 2-6 hari.</p>
                        </div>
                    </div>
                </div>
            </article>
        </section>

    );
}

export default SectionNewNews;