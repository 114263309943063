import news from '../../../assets/images/banner/news.png';
import api from '../../../api';
import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

function SectionHeadline() {
    const [blog, setBlog] = useState([]);

    useEffect(() => {
        getBlogs();
    }, []);

    const getBlogs = async () => {
        try {
            const response = await api.get("/v1/blogs-donation?limit=10");
            console.log(response.data.data);

            setBlog(response.data.data.items);
        } catch ({ response }) {
            console.log(response);
        }
    };

    return (
        <section className="py-16" id="headline">
            <article className="relative max-w-container mx-auto px-6 md:px-4">
                <div className="grid lg:grid-cols-2 gap-12">
                    <div>
                        <img className="w-full h-[350px] lg:h-[300px] object-cover" src={blog[0]?.image?.resourceUrl || ""} alt="" />
                        <div className="text-xs text-default mt-6 mb-2">{blog[0]?.updated_at || "" ? new Date(blog[0]?.updated_at).toLocaleDateString() : ""}</div>
                        <h2 className="mb-2 text-dark text-[32px] font-bold montserrat">{blog[0]?.title || ""}</h2>
                        <div className="text-limit text-sm text-default" dangerouslySetInnerHTML={{ __html: blog[0]?.content || "" }} />
                        <NavLink to={`/blog/detail/${blog[0]?.slug}`} state={{ slug: blog[0]?.slug }}>
                            <div className='text-primary'>Baca Selengkapnya</div>
                        </NavLink>
                    </div>
                    <div className="grid grid-rows-3 gap-4">
                        {blog.slice(1, 4).map((item, index) => (
                            <div key={item.id} className="grid grid-cols-2 gap-2">
                                <img className="w-full h-[250px] lg:h-[150px] object-cover" src={item.image?.resourceUrl || ""} alt="" />
                                <div>
                                    <div className="text-xs text-default">
                                        {item.updated_at || "" ? new Date(item.updated_at).toLocaleDateString() : ""}
                                    </div>
                                    <div className="font-bold text-dark montserrat text-xl mt-1 mb-1">{item.title || ""}</div>
                                    <div className="text-limit text-sm text-default" dangerouslySetInnerHTML={{ __html: item.content || "" }} />
                                    <NavLink to={`/blog/detail/${item.slug}`} state={{ slug: item.slug }}>
                                        <div className='text-primary'>Baca Selengkapnya</div>
                                    </NavLink>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                {blog.length > 4 ? (
                    <div>
                        <div className="text-[22px] font-bold mb-7 mt-8">Sorotan Hari Ini</div>
                        <div className="grid grid-rows gap-11">
                            {blog.slice(4).map((item, index) => (
                                <div className="grid grid-cols-4 gap-6">
                                    <div className="col-span-1 max-h-[178px] max-w-[258] object-cover"><img className="w-full h-[250px] lg:h-[150px] object-cover" src={item.image?.resourceUrl || ""} alt="" /></div>
                                    <div className="col-span-3">
                                        <div className="text-xs text-default">
                                            {item.updated_at || "" ? new Date(item.updated_at).toLocaleDateString() : ""}
                                        </div>
                                        <div className="text-2xl text-dark montserrat font-bold mt-1 mb-1">{item.title || ""}</div>
                                        <div className="text-limit text-sm text-default" dangerouslySetInnerHTML={{ __html: item.content || "" }} />
                                        <NavLink to={`/blog/detail/${item.slug}`} state={{ slug: item.slug }}>
                                            <div className='text-primary'>Baca Selengkapnya</div>
                                        </NavLink>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ) : (
                    <div></div> // Empty state message
                )}
            </article>
        </section>

    );
}

export default SectionHeadline;