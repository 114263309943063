import React from "react";

const Card = ({heading, customHeadingStyle = "", ...props}) => {
  return (
    <>
      <div className="flex flex-col p-4 gap-4 rounded-2xl ring-1 ring-neutralBright">
        <div
          className={
            customHeadingStyle +
            " not-italic font-bold text-base leading-[22px] tracking-[0.005em] text-dark"
          }
        >
          {heading}
        </div>
        {props.children}
      </div>
    </>
  );
};

export default Card;
