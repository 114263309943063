import React, { useState, useEffect } from "react";
import api from "../../../api";
import { useParams } from "react-router-dom";

const CampaignNewsDetailPage = () => {
    const { id } = useParams();
    const [newsData, setNewsData] = useState(null)

    useEffect(() => {
        fetchNewsData(id); // Fetch data if an ID is present
    }, [id]);

    const fetchNewsData = async (id) => {
        try {
            const { data } = await api.get(`/v1/news/campaign/${id}`);
            setNewsData(data.data);
        } catch (error) {
            console.error("Error fetching news data:", error);
        }
    };

    return (
        <section className="py-16" id="blog">
            <article className="relative max-w-container mx-auto px-6 md:px-4">
                <div className="flex flex-col gap-4">
                    {newsData?.image && <img src={newsData?.image.resourceUrl || ""} className="w-full" />}
                    <p className="text-sm text-default mt-6">{newsData?.created_at ? new Date(newsData?.created_at).toLocaleDateString() : ""}</p>
                    <h2 className="mb-2 text-dark text-[32px] font-bold">{newsData?.title || ""}</h2>
                    <div className="text-dark" dangerouslySetInnerHTML={{ __html: newsData?.content || "" }} />
                </div>
            </article>
        </section>
    );
};

export default CampaignNewsDetailPage;