import {Link} from "react-router-dom";

function Footer() {
  return (
    <div className="bg-dark lato text-white font-normal leading-[140%] tracking-wider text-sm">
      <div className="flex flex-col md:grid-cols-12 pt-16 gap-4 mx-auto">
        <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 px-6 lg:mx-16">
          <div className="md:flex justify-between">
            <nav
              aria-label="bottom-navigation"
              className="flex flex-col lg:flex justify-between gap-4 lg:gap-4 mb-6"
            >
               <div className="flex items-start text-base font-bold">Informasi</div>
              <Link to={"/about"}>
                <div className="flex items-start">Tentang Kami</div>
              </Link>
              <Link
                to={"/contact-us"}
              >
                <div className="flex items-start">Hubungi Kami</div>
              </Link>
              <Link to={"/blog"}>
                <div className="flex items-start">Blog</div>
              </Link>
            </nav>
          </div>
          <div className="md:flex justify-between">
            <nav
              aria-label="bottom-navigation"
              className="flex flex-col lg:flex justify-between gap-4 lg:gap-4 mb-6"
            >
               <div className="flex items-start text-base font-bold">Layanan</div>
              <Link to={"/services/konsultasi-csr"}>
                <div className="flex items-start">Konsultasi CSR</div>
              </Link>
              <Link to={"/services/manajemen-program"}
              >
                <div className="flex items-start">Manajemen Program</div>
              </Link>
              <Link to={"/services/social-impact-investment"}>
                <div className="flex items-start">Social Impact Investment</div>
              </Link>
            </nav>
          </div>
        </div>
        <div className="w-full py-6 items-center font-bold leading-[140%] tracking-wider text-center bg-primary">
              Hak Cipta © 2024 LangkahBaik.id.
          </div>
      </div>
    </div>
  );
}

export default Footer;
