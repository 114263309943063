import {mdiCheckCircle} from "@mdi/js";
import Icon from "@mdi/react";
import Countdown from "react-countdown";

const CardPromo = ({data, selected, active, handleClick}) => {
  const countdownRenderer = ({days, hours, minutes, seconds, completed}) => {
    if (completed) {
      return <span>Kode promo sudah berakhir</span>;
    }
    if (days > 180) {
      return <span>Berakhir lebih dari 6 bulan</span>;
    }
    if (days >= 90) {
      return <span>Berakhir lebih dari 3 bulan</span>;
    }
    if (days >= 60) {
      return <span>Berakhir lebih dari 2 bulan</span>;
    }
    if (days >= 30) {
      return <span>Berakhir 1 bulan</span>;
    }
    if (days > 0) {
      return <span>Berakhir {days} hari</span>;
    }
    if (hours > 0) {
      return (
        <span>
          Berakhir {hours} jam {minutes} menit {seconds} detik
        </span>
      );
    }
    if (minutes > 0) {
      return (
        <span>
          Berakhir {minutes} menit {seconds} detik
        </span>
      );
    }
    return <span>Berakhir {seconds} detik</span>;
  };

  return (
    <button
      onClick={() => handleClick(data.code)}
      className={
        (data.code === active || data.code === selected
          ? "bg-primaryLight border border-primary"
          : "bg-white border border-neutralBright") +
        " rounded-2xl p-4 flex flex-col gap-4"
      }
    >
      <div className="flex flex-col items-start gap-0">
        <div className="not-italic font-bold text-base leading-[140%] tracking-[0.005em] text-dark">
          {data.name}
        </div>
        <div className="not-italic font-normal text-base leading-[140%] text-default">
          Kode Promo: <span className="font-bold text-dark">{data.code}</span>
        </div>
      </div>
      <div className="flex flex-row gap-2 justify-between items-center w-full relative">
        <div className="not-italic font-normal text-base leading-[140%] tracking-[0.005em] text-dark">
          <Countdown date={data.availableUntil} renderer={countdownRenderer} />
        </div>
        {(data.code === active || data.code === selected) && (
          <div className="absolute bottom-0 right-0">
            <Icon path={mdiCheckCircle} size={1} className="text-primary" />
          </div>
        )}
      </div>
    </button>
  );
};

export default CardPromo;
