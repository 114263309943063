import SectionContactUs from "../../../components/LandingPage/HomeSection/SectionContactUs";
import TabTitle from "../../../utils/TabTitle";

const ContactUsPage = () => {
    TabTitle("Hubungi Kami - Langkah Baik");
    return (
        <>
        <SectionContactUs />
        </>
    );
}

export default ContactUsPage;