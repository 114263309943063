import React from "react";
import {Navigate, Outlet} from "react-router-dom";
import FormHeader from "../components/FormPage/FormHeader";

const accessToken = localStorage.getItem('accessToken');

const FormLayout = ({transactionCode}) => {
  return (
      accessToken?
    <>
      <header id="header" className="top-0 z-50 sticky">
        <FormHeader transactionCode={transactionCode} />
      </header>
      <main id="main">
        <Outlet />
      </main>
    </> :
    <Navigate to="/login" />
  );
};

export default FormLayout;
