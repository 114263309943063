import "@amir04lm26/react-modern-calendar-date-picker/lib/DatePicker.css";
import {Calendar, utils} from "@amir04lm26/react-modern-calendar-date-picker";
import React, {useEffect, useState} from "react";
import {Menu} from "@headlessui/react";
import Button from "./Button";

const DateCalendarPicker = ({minDate, value, setValue}) => {
  let startingDate = "";
  if (minDate === "today") {
    startingDate = utils().getToday();
  } else if (minDate.day && minDate.month && minDate.year) {
    startingDate = minDate;
  } else {
    startingDate = {day: "", month: "", year: ""};
  }

  const [selectedDay, setSelectedDay] = useState("");
  const [isValid, setIsValid] = useState(false);

  const submitDate = () => {
    if (isValid) {
      setValue({
        day: selectedDay.day,
        month: selectedDay.month,
        year: selectedDay.year,
      });
    }
  };

  useEffect(() => {
    if (value.day && value.month && value.year) {
      setSelectedDay({
        year: value.year,
        month: value.month,
        day: value.day,
      });
    }
  }, []);

  useEffect(() => {
    if (selectedDay.day && selectedDay.month && selectedDay.year) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [selectedDay]);

  return (
    <div className="flex flex-col min-w-[210px] max-w-full">
      <div className="flex flex-col gap-1">
        <div className="p-3 not-italic font-normal text-sm leading-5 tracking-[0.005em] text-default">
          Tanggal Pengerjaan
        </div>
        <div className="flex flex-row items-start mx-3 px-2 py-2.5 gap-2 bg-white border border-neutralBright rounded-md">
          {`${
            selectedDay.day && selectedDay.month && selectedDay.year
              ? `${selectedDay.day}/${selectedDay.month}/${selectedDay.year}`
              : "DD/MM/YYYY"
          }`}
        </div>
      </div>
      <Calendar
        value={selectedDay}
        onChange={setSelectedDay}
        colorPrimary="#2196F3"
        minimumDate={startingDate}
        calendarClassName="custom-calendar"
        slideAnimationDuration="0s"
        shouldHighlightWeekends
      />
      <div className="flex flex-row justify-end gap-4 p-3 border-t border-t-primaryBright">
        <Button
          btnType="secondary"
          customStyle=" rounded-full"
          click={() => {
            setSelectedDay("");
            setValue("");
          }}
        >
          <div className="not-italic font-bold text-base leading-[140%] text-center tracking-[0.005em] text-dark">
            Reset
          </div>
        </Button>
        <Menu.Item as="div">
          <Button
            btnType="primary"
            customStyle=" rounded-full"
            click={submitDate}
            isDisabled={!isValid}
          >
            <div className="not-italic font-bold text-base leading-[140%] text-center tracking-[0.005em] text-white">
              Apply
            </div>
          </Button>
        </Menu.Item>
      </div>
    </div>
  );
};

export default DateCalendarPicker;
