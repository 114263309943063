import TabTitle from "../../../utils/TabTitle";
import ModalAlert from "../../../components/ModalAlert";
import React, { useState } from "react";
import Button from "../../../components/Button";
import TextField from "../../../components/TextField";
import { connect, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { apiUpload } from "../../../api/apiUpload";
import ProgessUpload from "../../../components/FormPage/ProgressUpload";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import logo from "../../../assets/images/logo/Logo.png";
import Footer from "../../../components/LandingPage/Footer";
import NavProfile from "../Component/NavProfile";

const AddBlogPage = (props) => {
    TabTitle("Blog - Buat Baru");
    const navigate = useNavigate();
    const location = useLocation();
    const auth = useSelector(state => state.auth);
    const profile = useSelector(state => state.profile);
    const { id } = location.state || {}; // Access the id from state

    // global condition component
    const [modalOpen, setModalOpen] = useState({ status: false, type: "" });
    const [modalAlert, setModalAlert] = useState({
        alertTitle: "",
        alertMessage: "",
    });
    const [selectedImage, setSelectedImage] = useState(null); // State for the selected image
    const [imgFile, setImgFile] = useState(null);

    const [statusUpload, setStatusUpload] = useState("");
    const [progressUpload, setProgressUpload] = useState(0);
    const [editorData, setEditorData] = useState('');

    const handleBack = () => {
        navigate("/");
    };

    const postBlog = async () => {
        const formData = new FormData();
        formData.append("image", imgFile);

        // Add values from text fields
        formData.append("title", document.getElementById('title').value);
        formData.append("content", editorData);

        console.log(formData);
        try {
            setModalOpen({ status: false, type: "" });
            setStatusUpload("uploading");
            await apiUpload
                .post(
                    `/v1/blogs-donation/store`,
                    formData,
                    {
                        onUploadProgress: (progressEvent) => {
                            setProgressUpload(
                                Math.round((progressEvent.loaded / progressEvent.total) * 100)
                            );
                        },
                    }
                )
                .then(({ response }) => {
                    setStatusUpload("success");
                    navigate("/dashboard/blog");
                });
        } catch ({ response }) {
            setStatusUpload("failed");
            setModalOpen({status: true, type: "alert"});

            const errorMsg = response.data;
            if (errorMsg.error.errors[0].message) {
                alert(errorMsg.error.errors[0].message);
            }
        }
    };

    return (
        <>
            <ModalAlert
                isOpen={modalOpen.status && modalOpen.type === "alert"}
                closeModal={() => {
                    setModalOpen({ status: false, type: "" });
                    setModalAlert({ alertTitle: "", alertMessage: "" });
                }}
                alertTitle={modalAlert.alertTitle}
                alertMessage={modalAlert.alertMessage}
            />

            <header id="header" className="top-0 z-50 sticky">
                <nav className="border-b bg-white border-b-bright">
                    <div className="grid grid-cols-12 mx-auto lg:max-w-7xl py-5 px-4 lg:px-4 2xl:px-0">
                        <div className="col-span-1 flex items-center justify-between">
                            <img src={logo} alt="CleanSheet" className="h-9 hidden sm:block" />
                        </div>
                        <div className="col-span-11 grid grid-cols-11">
                            <div className="col-span-10">
                                <span className="not-italic montserrat text-2xl font-bold leading-[140%] tracking-[0.005em] text-primary">
                                    Dashboard
                                </span>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>

            <section className="bg-neutralLight font-light leading-relaxed grid grid-rows-12 grid-flow-col grid-cols-12 mx-auto px-4 lg:px-4 xl:px-2 lg:max-w-full relative gap-4 lg:gap-y-6 lg:gap-x-4 py-2">
                <NavProfile />

                <div className="row-span-2 col-span-9 bg-white">
                    <div className="border-b-2 p-4 border-bright flex mb-4">
                        <p className="text-2xl text-primary font-bold">Buat Blog</p>
                    </div>
                    <div className="flow-root p-4">
                        <div className='flex flex-col md:flex-row gap-8'>
                            <div className='flex flex-col mx-auto md:mx-0 w-full gap-6 md:w-64'>
                                <div className='w-64 h-48 overflow-hidden mx-auto md:mx-0'>
                                    <div className='w-full h-full flex items-center justify-center' style={{ backgroundColor: selectedImage ? 'transparent' : '#f0f0f0' }}>
                                        {selectedImage ? ( // Conditional rendering for image preview
                                            <img src={selectedImage} alt="Selected" className="w-full h-full object-cover" />
                                        ) : (
                                            <div className="w-full h-full flex items-center justify-center">
                                                <span className="text-gray-500">Pilih Gambar</span> {/* Placeholder text */}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='w-full flex justify-center'>
                                    <Button
                                        typeNavigation='button'
                                        btnStyle='secondary'
                                        btnType="secondary"
                                        customStyle='px-4 py-0.5 w-fit rounded-full'
                                        onClick={() => document.getElementById('imageUpload').click()} // Trigger file input
                                    >
                                        <div className='pSmallBold'>Pilih Gambar</div>
                                    </Button>
                                    <input
                                        id='imageUpload'
                                        type='file'
                                        accept='image/*' // Accept only image files
                                        style={{ display: 'none' }} // Hide the input
                                        onChange={(e) => {
                                            e.preventDefault(); // Prevent default behavior
                                            const file = e.target.files[0];
                                            if (file) {
                                                const reader = new FileReader();
                                                reader.onloadend = () => {
                                                    setSelectedImage(reader.result); // Update state with the selected image URL
                                                };
                                                reader.readAsDataURL(file); // Read the file as a data URL

                                                setImgFile(file);
                                            }
                                        }}
                                        onClick={(e) => e.stopPropagation()} // Prevent triggering postCampaign on click
                                    />
                                </div>
                            </div>
                            <form
                                id='form-update-profile'
                                className='flex flex-col gap-6 lg:w-full md:min-w-max'>
                                <div className="flex flex-col gap-2">
                                    <TextField
                                        inputId='title'
                                        inputName='title'
                                        label='Judul'
                                        isRequired
                                        customLabelStyle='col-span-4 sm:col-span-1'
                                        customContainerStyle='col-span-4 sm:col-span-3 '
                                        placeholder='Masukkan Judul'
                                    />
                                </div>
                                <div className="flex flex-col gap-2">
                                    <div style={{ maxWidth: '730px', width: '100%' }}> {/* Set max width for the editor */}
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data={editorData}
                                            onReady={editor => {
                                                editor.ui.view.editable.element.style.height = '300px'; // Set editor height here
                                                editor.ui.view.editable.element.style.maxWidth = '100%'; // Ensure it doesn't exceed container width
                                            }}
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                setEditorData(data);
                                            }}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className='flex flex-row justify-end mt-4'>
                            <Button
                                typeNavigation='button'
                                btnType='primary'
                                btnStyle='primary'
                                customStyle='px-4 py-0.5 w-fit rounded-full'
                                form='form-add-blog'
                                isDisabled={statusUpload == 'uploading'}
                                onClick={postBlog} // Pass the function reference
                            >
                                <div>{statusUpload == 'uploading' ? 'Loading...' : 'Simpan'}</div>
                            </Button>
                        </div>
                    </div>
                </div>
            </section>
            <footer id="footer">
                <Footer />
            </footer>
        </>
    );
}

export default connect(null)(AddBlogPage)
