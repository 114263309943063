import BaseAxios from './BaseAxios'

const url = `${process.env.REACT_APP_API_URL}/v1`;
const api_profile = `${url}/user/profile`;
const api_addcontact = `${url}v1/customer/kontak`;
const api_address = `${url}v1/customer/lokasi`;

const getProfile = () => {
  return{
    type: 'GET_PROFILE',
    payload: BaseAxios.get(api_profile)
  }
}

const editProfile = (data) => {
  return{
    type: 'EDIT_PROFILE',
    payload: BaseAxios.put(api_profile, data)
  }
}

const addContact = (data) => {
  return{
    type: 'ADD_CONTACT',
    payload: BaseAxios.post(api_addcontact, data)
  }
}

const addAddress = (data) => {
  return{
    type: 'ADD_ADDRESS',
    payload: BaseAxios.post(api_address, data)
  }
}

const editAddress = (data, id) => {
  return{
    type: 'EDIT_ADDRESS',
    payload: BaseAxios.put(`${api_address}/${id}`, data)
  }
}

const deleteAddress = (id) => {
  return{
    type: 'DELETE_ADDRESS',
    payload: BaseAxios.delete(`${api_address}/${id}`)
  }
}

export {
  getProfile,
  editProfile,
  addContact,
  addAddress,
  editAddress,
  deleteAddress
}
