import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Button from "../../Button";
import api from "../../../api";
import { formatRupiah } from "../../../utils/RupiahFormat";

const SectionProjectKami = () => {
  const [campaign, setCampaign] = useState([]);

  useEffect(() => {
    getCampaigns();
  }, []);

  const getCampaigns = async (limit = 4) => { // Added limit parameter with a default value
    try {
      const response = await api.get(`/v1/campaign?limit=${limit}`); // Included limit in the API request
      setCampaign(response.data.data);
    } catch ({ response }) {
      console.log(response);
    }
  };

  return (
    <section id="ayo-bergabung-menjadi-partner-kami">
      <article className="max-w-full pt-16 pb-32 mx-auto px-6 md:px-2 lg:px-4 xl:px-5 flex flex-col gap-12">
        <h1 className="montserrat not-italic font-bold text-3xl leading-[140%] tracking-[0.005em] text-dark text-center">
          Program Kami
        </h1>

        <div className="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2 gap-8 lg:gap-6">
          {campaign.map((item) => (
            <div key={item.id} className="flex flex-col gap-5 p-4 bg-white drop-shadow-bottom rounded-xl col-span-1 h-auto">
              <div className="h-48 overflow-hidden">
                <img
                  src={item.image.resourceUrl}
                  alt={item.title}
                  className="w-full h-full object-cover object-center"
                />
              </div>
              <h2 className="font-medium text-lg leading-[140%] tracking-[0.005em] text-dark line-clamp-2">
                {item.title}
              </h2>
              <div className="text-gray text-sm not-italic font-normal leading-[140%] tracking-[0.005em] line-clamp-2 ">
                <p>{item.description}</p>
              </div>
              <div className="font-medium text-base leading-[100%] tracking-[0.005em]">
                <span className="text-primary">{formatRupiah(item.goal_amount)}</span>
              </div>
              <progress
                value={item ? (parseFloat(item.raised_amount) / parseFloat(item.goal_amount)) * 100 : 0}
                max="100" className="w-full h-2 bg-gray-200 rounded-full custom-progress appearance-none" />
              <div className="text-gray text-sm not-italic font-normal leading-[50%] tracking-[0.005em]">
                <span className="text-dark font-medium">{((parseFloat(item.raised_amount) / parseFloat(item.goal_amount)) * 100).toFixed(0)}%</span>&nbsp; Tercapai
              </div>
              <NavLink to={`/project-us/donate/c${item.id}`} state={{ id: item.id }}>
                <Button customStyle=" w-full">
                  <div className="w-full text-center not-italic font-bold text-sm lg:text-base leading-[140%] tracking-[0.005em] text-white py-1 px-3 md:px-5">
                    Donasi Sekarang
                  </div>
                </Button>
              </NavLink>
            </div>
          ))}
        </div>

        <div className="flex justify-center mb-4">
          <NavLink to={`/project-us`}>
            <Button btnType="secondary" customStyle=" rounded-full">
              <div className="px-5 py-1 font-bold not-italic text-base leading-[140%] tracking-[0.005em] text-dark">
                Lihat Semua Program
              </div>
            </Button>
          </NavLink>
        </div>
      </article>
    </section>
  );
};

export default SectionProjectKami;
