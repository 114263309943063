import axios from 'axios';
const baseAxios = axios.create();

baseAxios.interceptors.request.use((config) => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
        if (config.method !== 'OPTIONS') {
            config.headers = {
                'Authorization' : `Bearer ${accessToken}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }
    }
    return config;
});

baseAxios.interceptors.response.use((response) => {
    return response;
    }, (error) => {
    if(error.response.data[0] === 'jwt expired' || error.response.data[0] === 'jwt malformed'){
        alert('Session expired')
    }
      console.log("error", error.response)
    return Promise.reject(error);
});

export default baseAxios;
