import React from "react";
import Button from "./Button";
import Modal from "./Modal";

const ModalAlert = ({isOpen, closeModal, alertTitle, alertMessage}) => {
  return (
    <Modal isOpen={isOpen} closeModal={closeModal}>
      <div className="flex flex-col gap-4">
        <div className="not-italic montserrat font-bold text-xl leading-5 tracking-[0.005em] text-slate">
          {alertTitle}
        </div>
        <div className="not-italic font-normal text-base leading-[22px] tracking-[0.005em] text-slateLight">
          {alertMessage}
        </div>
        <div className="flex flex-row">
          <Button
            customStyle=" rounded-full basis-1/2 ml-auto"
            click={closeModal}
          >
            <div className="not-italic font-bold text-base leading-[22px] text-center tracking-[0.005em] w-full text-white">
              OK
            </div>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalAlert;
