import React, { useState, useEffect } from "react";
import api from "../../../api";
import { useParams } from "react-router-dom";

const BlogDetailPage = () => {
    const { slug } = useParams();
    const [blogData, setBlogData] = useState(null)

    console.log(`slug ${slug}`);
    useEffect(() => {
        fetchBlogData(slug); // Fetch data if an ID is present
    }, [slug]);

    const fetchBlogData = async (slug) => {
        try {
            const { data } = await api.get(`/v1/blogs-donation/detail/${slug}`);
            setBlogData(data.data);
        } catch (error) {
            console.error("Error fetching blog data:", error);
        }
    };

    return (
        <section className="py-16" id="blog">
            <article className="relative max-w-container mx-auto px-6 md:px-4">
                <div className="flex flex-col gap-4">
                    {blogData?.image && <img src={blogData?.image.resourceUrl || ""} className="w-full" />}
                    <p className="text-sm text-default mt-6">{blogData?.updated_at ? new Date(blogData.updated_at).toLocaleDateString() : ""}</p>
                    <h2 className="mb-2 text-dark text-[32px] font-bold">{blogData?.title || ""}</h2>
                    <div className="text-dark" dangerouslySetInnerHTML={{ __html: blogData?.content || "" }} />
                </div>
            </article>
        </section>
    );
};

export default BlogDetailPage;