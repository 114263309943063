import TabTitle from "../../../utils/TabTitle";
import ModalAlert from "../../../components/ModalAlert";
import React, { useState } from "react";
import { connect, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import logo from "../../../assets/images/logo/Logo.png";
import Footer from "../../../components/LandingPage/Footer";
import NavProfile from "../Component/NavProfile";
import { apiUpload } from "../../../api/apiUpload";
import ProgessUpload from "../../../components/FormPage/ProgressUpload";
import EditCampaign from "./EditCampaign";
import AddPenyaluran from "./AddPenyaluran";
import CampaignNews from "./CampaignNews";

const CampaignDetailPage = (props) => {
    TabTitle("Profile");
    const navigate = useNavigate();
    const location = useLocation();
    const auth = useSelector(state => state.auth);
    const profile = useSelector(state => state.profile);
    const { id } = location.state || {}; // Access the id from state

    const [activeTab, setActiveTab] = useState("deskripsi"); // Added state for active tab

    // global condition component
    const [modalOpen, setModalOpen] = useState({ status: false, type: "" });
    const [modalAlert, setModalAlert] = useState({
        alertTitle: "",
        alertMessage: "",
    });
    const [selectedImage, setSelectedImage] = useState(null); // State for the selected image
    const [imgFile, setImgFile] = useState(null);

    const [statusUpload, setStatusUpload] = useState("");
    const [progressUpload, setProgressUpload] = useState(0);
    const [editorData, setEditorData] = useState('');

    const handleBack = () => {
        navigate("/");
    };

    const postCampaign = async () => {
        const formData = new FormData();
        formData.append("image", imgFile);

        // Add values from text fields
        formData.append("title", document.getElementById('title').value);
        formData.append("description", document.getElementById('desc').value);
        formData.append("goal_amount", document.getElementById('target').value);
        formData.append("start_date", formatDate(document.getElementById('dateStart').value));
        formData.append("end_date", formatDate(document.getElementById('dateEnd').value));

        console.log(formData);
        try {
            setModalOpen({ status: false, type: "" });
            setStatusUpload("uploading");
            await apiUpload
                .post(
                    `/v1/campaign`,
                    formData,
                    {
                        onUploadProgress: (progressEvent) => {
                            setProgressUpload(
                                Math.round((progressEvent.loaded / progressEvent.total) * 100)
                            );
                        },
                    }
                )
                .then(({ response }) => {
                    setStatusUpload("success");
                    navigate("/dashboard/campaign/detail");
                });
        } catch ({ response }) {
            setStatusUpload("failed");
            const errorMsg = response.data;
            if (errorMsg.error.errors[0].message) {
                alert(errorMsg.error.errors[0].message);
            }
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    return (
        <>
            {statusUpload !== "" && (
                <ProgessUpload
                    status={statusUpload}
                    setStatus={setStatusUpload}
                    progress={progressUpload}
                />
            )}
            <ModalAlert
                isOpen={modalOpen.status && modalOpen.type === "alert"}
                closeModal={() => {
                    setModalOpen({ status: false, type: "" });
                    setModalAlert({ alertTitle: "", alertMessage: "" });
                }}
                alertTitle={modalAlert.alertTitle}
                alertMessage={modalAlert.alertMessage}
            />
            <header id="header" className="top-0 z-50 sticky">
                <nav className="border-b bg-white border-b-bright">
                    <div className="grid grid-cols-12 mx-auto lg:max-w-7xl py-5 px-4 lg:px-4 2xl:px-0">
                        <div className="col-span-1 flex items-center justify-between">
                            <img src={logo} alt="CleanSheet" className="h-9 hidden sm:block" />
                        </div>
                        <div className="col-span-11 grid grid-cols-11">
                            <div className="col-span-10">
                                <span className="not-italic montserrat text-2xl font-bold leading-[140%] tracking-[0.005em] text-primary">
                                    Dashboard
                                </span>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>

            <section className="bg-neutralLight font-light leading-relaxed grid grid-rows-12 grid-flow-col grid-cols-12 mx-auto px-4 lg:px-4 xl:px-2 lg:max-w-full relative gap-4 lg:gap-y-6 lg:gap-x-4 py-2">
                <NavProfile />

                <div className="row-span-2 col-span-9 bg-white rounded-3xl drop-shadow-bottom">
                    <div className="border-b-2 p-4 border-bright flex mb-4">
                        <p className="text-2xl text-primary font-bold">Kabar Terbaru</p>
                    </div>
                    {/* Added Tab Navigation */}
                    <div className="flex space-x-4 mb-4 relative overflow-x-auto"> {/* Added overflow-x-auto for horizontal scrolling */}
                        {["deskripsi", "Kabar Terbaru", "Penyaluran"].map((tab) => (
                            <button
                                key={tab}
                                onClick={() => {
                                    console.log(`Switching to tab: ${tab}`); // Debug log
                                    setActiveTab(tab);
                                }}
                                className={`flex-1 py-2 px-4 rounded-lg ${activeTab === tab ? 'bg-primary text-white' : 'bg-light text-primaryDark'}`} // Updated button styles for active/inactive
                            >
                                {tab.charAt(0).toUpperCase() + tab.slice(1)}
                            </button>
                        ))}
                    </div>

                    <div className="flow-root p-4">
                        {console.log(`Current active tab: ${activeTab}`)} {/* Debug log */}
                        {activeTab === "deskripsi" &&
                            <div>
                                <EditCampaign id={id} /> {/* Pass the id prop here */}
                            </div>
                        }
                        {activeTab === "Kabar Terbaru" &&
                            <div>
                                <CampaignNews id={id} /> {/* Test with EditCampaign */}
                            </div>
                        }
                        {activeTab === "Penyaluran" && <div><AddPenyaluran id={id}/></div>}
                    </div>
                </div>

            </section>
            <footer id="footer">
                <Footer />
            </footer>
        </>
    );
}

export default connect(null)(CampaignDetailPage)
