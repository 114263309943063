const initialState = {
    isLoading: false,
    isFulfilled: false,
    isRejected: false,
    error: ''
};

const order = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ORDER_PENDING':
            return {
                ...state,
                isLoading: true,
                isFulfilled: false,
                isRejected: false,
            };
        case 'GET_ORDER_REJECTED':
            return {
                ...state,
                isLoading: false,
                isRejected: true,
            };
        case 'GET_ORDER_FULFILLED':
            return {
                ...state,
                isLoading: false,
                isFulfilled: true,
                error: '',
            };
        case 'CREATE_ORDER_PENDING':
            return {
                ...state,
                isLoading: true,
                isFulfilled: false,
                isRejected: false,
            };
        case 'CREATE_ORDER_REJECTED':
            return {
                ...state,
                isLoading: false,
                isRejected: true,
            };
        case 'CREATE_ORDER_FULFILLED':
            return {
                ...state,
                isLoading: false,
                isFulfilled: true,
                error: '',
            };
        default:
            return state;
    }
}

export default order