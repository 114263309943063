import SectionLayanan from "../../../components/LandingPage/ServicesSection/SocialImpact/SectionLayanan";
import TabTitle from "../../../utils/TabTitle";

const SocialImpactInvestmentPage = () => {
    TabTitle("Social Impact Investment - LangkahBaik");
    return (
        <>
            <SectionLayanan />
        </>
    );
}

export default SocialImpactInvestmentPage;