import SectionLayanan from "../../../components/LandingPage/ServicesSection/KonsultasiCSR/SectionLayanan";
import TabTitle from "../../../utils/TabTitle";

const KonsultasiCSRPage = () => {
    TabTitle("Konsultasi CSR - LangkahBaik");
    return (
        <>
            <SectionLayanan />
        </>
    );
}

export default KonsultasiCSRPage;