import img from "../../../assets/images/misc/about-us.jpg";

function SectionAbout() {
  return (
    <section className="w-full py-6 lg:py-16 relative" id="tentang-kami">
      <div className="md:max-w-container mx-auto px-6 md:px-4 grid grid-cols-1 lg:grid-cols-12 gap-4">
        <article className="col-span-1 lg:col-span-6 flex flex-col gap-5">
          <h2 className="font-bold text-[32px] leading-[130%] tracking-wider text-dark montserrat">
            <span className="underlined">Tentang LangkahBaik.id</span>
          </h2>
          <div className="flex flex-col gap-5">
            <p>
              Melalui spirit gotong royong melangkah bersama untuk kebaikan, kami hadir untuk menciptakan Social Impact Investment yang positif dan berkelanjutan.
              Ada tiga layanan utama dari langkahbaik.id yaitu konsultasi CSR, Manajemen Program CSR dan Social Impact Investment.
              Pengalaman yang berbeda melalui langkahbaik akan menjadi kontribusi positif bagi perusahaan dan pemangku kepentingannya.
            </p>
            <p>
              Langkahbaik.id merupakan bagian dari <strong>Cleansheet</strong> yang misinya ingin terus memperluas kebermanfaatan.
            </p>
          </div>
        </article>
        <aside className="col-span-1 lg:col-end-13 lg:col-span-5 flex justify-end">
          <div className="center-web">
            <img src={img} alt="" />
          </div>

        </aside>
      </div>
    </section>
  );
}

export default SectionAbout;
