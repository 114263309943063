import React, {useEffect, useState} from "react";
import ServiceLoader from "./ServiceLoader";
import ServiceOrder from "./ServiceOrder";
import {ReactComponent as GeneralCleaning} from "../../assets/vectors/icons/general-cleaning.svg";
import {ReactComponent as DeepCleaning} from "../../assets/vectors/icons/deep-cleaning.svg";
import {ReactComponent as PolesLantai} from "../../assets/vectors/icons/poles-lantai.svg";
import {ReactComponent as ToiletCleaning} from "../../assets/vectors/icons/toilet-cleaning.svg";
import {ReactComponent as FurnitureCleaning} from "../../assets/vectors/icons/furniture-cleaning.svg";
import {ReactComponent as HydroVacuum} from "../../assets/vectors/icons/hydro-vaccum.svg";
import {ReactComponent as PenyemprotanDisinfektan} from "../../assets/vectors/icons/penyemprotan-disinfektan.svg";
import {ReactComponent as OneHome} from "../../assets/vectors/icons/one-home-one-hope.svg";
import {ReactComponent as OneCorp} from "../../assets/vectors/icons/one-corp-many-hope.svg";

const ServiceList = ({
  isRequired = false,
  inputId,
  label,
  dataAll,
  plusOrder,
  minOrder,
  addNotes,
  refs,
  loading,
  getServices,
  isServiceValidated,
}) => {
  const [itemOpen, setItemOpen] = useState(null);

  const setServices = (index, id) => {
    setItemOpen({index, id});
    if (dataAll[index].services.length === 0) {
      getServices(id);
    }
  };

  const handleClickCategories = (index, id) => {
    if (itemOpen) {
      if (itemOpen.id === id) {
        setItemOpen(null);
      } else {
        setServices(index, id);
      }
    } else {
      setServices(index, id);
    }
  };

  useEffect(() => {
    setItemOpen(null);
  }, []);

  return (
    <>
      {label && (
        <div className="flex flex-row items-center gap-1.5" ref={refs}>
          <label htmlFor={inputId}>{label}</label>
          {isRequired && (
            <div className="w-1.5 h-1.5 rounded-full bg-red100"></div>
          )}
        </div>
      )}
      {!isServiceValidated && (
        <div className="text-red100">This field must be filled</div>
      )}
      <div className="grid grid-cols-2 gap-4">
        {dataAll.map((key, keyIndex, keyArray) => (
          <React.Fragment key={keyIndex}>
            <button
              type="button"
              className="col-span-1"
              onClick={() => handleClickCategories(keyIndex, key.id)}
            >
              <div
                className={
                  "flex flex-col items-center ring-1 rounded-lg h-full p-4 gap-1.5" +
                  (itemOpen
                    ? itemOpen.id === key.id
                      ? " bg-primary ring-primary"
                      : " bg-white ring-bright"
                    : " bg-white ring-bright")
                }
              >
                <div
                  className={
                    "w-12 h-12" +
                    (itemOpen
                      ? itemOpen.id === key.id
                        ? " text-white"
                        : " text-primary"
                      : " text-primary")
                  }
                >
                  {key.name === "General Cleaning" && (
                    <GeneralCleaning className="w-12 h-12" />
                  )}
                  {key.name === "Deep Cleaning" && (
                    <DeepCleaning className="w-12 h-12" />
                  )}
                  {key.name === "Poles Lantai" && (
                    <PolesLantai className="w-12 h-12" />
                  )}
                  {key.name === "Toilet Cleaning" && (
                    <ToiletCleaning className="w-12 h-12" />
                  )}
                  {key.name === "Furniture Cleaning" && (
                    <FurnitureCleaning className="w-12 h-12" />
                  )}
                  {key.name === "Hydro Vacuum" && (
                    <HydroVacuum className="w-12 h-12" />
                  )}
                  {key.name === "Penyemprotan Disinfektan" && (
                    <PenyemprotanDisinfektan className="w-12 h-12" />
                  )}
                  {key.name === "One Home One Hope" && (
                    <OneHome className="w-12 h-12" />
                  )}
                  {key.name === "One Corp Many Hope" && (
                    <OneCorp className="w-12 h-12" />
                  )}
                </div>
                <div
                  className={
                    (itemOpen
                      ? itemOpen.id === key.id
                        ? "text-white"
                        : "text-dark"
                      : "text-dark") +
                    " not-italic font-normal text-sm leading-5 text-center tracking-[0.005em]"
                  }
                >
                  {key.name}
                </div>
              </div>
            </button>
            {itemOpen
              ? (keyArray.length - 1) % 2 === 1
                ? (keyIndex % 2 === 1 || keyIndex === keyArray.length - 1) &&
                  itemOpen.index <= keyIndex &&
                  keyIndex - itemOpen.index <= 1 &&
                  dataAll[keyIndex]?.services && (
                    <div className="col-span-2">
                      {!loading ? (
                        <ServiceOrder
                          data={dataAll[itemOpen.index]}
                          plusOrder={plusOrder}
                          minOrder={minOrder}
                          addNotes={addNotes}
                        />
                      ) : (
                        <ServiceLoader />
                      )}
                    </div>
                  )
                : keyIndex === keyArray.length - 1
                ? itemOpen.index === keyIndex &&
                  dataAll[keyIndex]?.services && (
                    <div className="col-span-2">
                      {!loading ? (
                        <ServiceOrder
                          data={dataAll[itemOpen.index]}
                          plusOrder={plusOrder}
                          minOrder={minOrder}
                          addNotes={addNotes}
                        />
                      ) : (
                        <ServiceLoader />
                      )}
                    </div>
                  )
                : keyIndex % 2 === 1 &&
                  itemOpen.index <= keyIndex &&
                  keyIndex - itemOpen.index <= 1 &&
                  dataAll[keyIndex]?.services && (
                    <div className="col-span-2">
                      {!loading ? (
                        <ServiceOrder
                          data={dataAll[itemOpen.index]}
                          plusOrder={plusOrder}
                          minOrder={minOrder}
                          addNotes={addNotes}
                        />
                      ) : (
                        <ServiceLoader />
                      )}
                    </div>
                  )
              : null}
          </React.Fragment>
        ))}
      </div>
    </>
  );
};

export default ServiceList;
