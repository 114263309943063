import TabTitle from "../../../utils/TabTitle";
import ModalAlert from "../../../components/ModalAlert";
import React, { useState, useEffect } from "react";
import Button from "../../../components/Button";
import TextField from "../../../components/TextField";
import { connect, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { apiUpload } from "../../../api/apiUpload";
import ProgessUpload from "../../../components/FormPage/ProgressUpload";
import TextArea from "../../../components/TextArea";
import { formatRupiah } from "../../../utils/RupiahFormat";

const AddPenyaluranPage = ({ id, ...props }) => {
    TabTitle("Campaign - Kabar Terbaru");
    const navigate = useNavigate();
    const auth = useSelector(state => state.auth);
    const profile = useSelector(state => state.profile);

    // global condition component
    const [modalOpen, setModalOpen] = useState({ status: false, type: "" });
    const [modalAlert, setModalAlert] = useState({
        alertTitle: "",
        alertMessage: "",
    });
    const [selectedImage, setSelectedImage] = useState(null); // State for the selected image
    const [imgFile, setImgFile] = useState(null);

    const [statusUpload, setStatusUpload] = useState("");
    const [progressUpload, setProgressUpload] = useState(0);
    const [editorData, setEditorData] = useState('');

    const [selectedImages, setSelectedImages] = useState([]);
    const [existingImages, setExistingImages] = useState([])

    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);
        setSelectedImages((prevImages) => [...prevImages, ...files]);
    };

    const removeImage = (indexToRemove) => {
        setSelectedImages((prevImages) =>
            prevImages.filter((_, index) => index !== indexToRemove)
        );
    };

    const handleBack = () => {
        navigate("/");
    };

    const fetchCampaignData = async (id) => {
        try {
            const { data } = await apiUpload.get(`/v1/campaign/${id}`);
            setSelectedImage(data.data.image.resourceUrl);
            document.getElementById('target').value = parseFloat(data.data.goal_amount).toFixed(0);
            document.getElementById('raised').value = parseFloat(data.data.raised_amount).toFixed(0);
            document.getElementById('distributed').value = parseFloat(data.data.distribute_amount).toFixed(0);
            document.getElementById('dateDistributed').value = data.data.distribute_date;
            document.getElementById('note').value = data.data.notes;

            // Assuming data.data.evidence.items is an array of objects with a resourceUrl property
            setExistingImages(data.data.evidence.items.map(item => item.resourceUrl)); // Map to get URLs
        } catch (error) {
            console.error("Error fetching campaign data:", error);
        }
    };

    useEffect(() => {
        if (id) {
            fetchCampaignData(id);
        }
    }, [id]);

    const postCampaign = async () => {
        const formData = new FormData();
        
        // Add values from text fields
        selectedImages.forEach((image) => {
            formData.append("images[]", image); // Append multiple images
        });
        formData.append("distribute_amount", document.getElementById('distributed').value); // Get distributed amount
        formData.append("distribute_date", document.getElementById('dateDistributed').value); // Get distributed date
        formData.append("notes", document.getElementById('note').value); // Get note

        console.log(formData);
        console.log(id);
        try {
            setModalOpen({ status: false, type: "" });
            setStatusUpload("uploading");
            await apiUpload
                .post(
                    `/v1/campaign/distribute/${id}`,
                    formData,
                    {
                        onUploadProgress: (progressEvent) => {
                            setProgressUpload(
                                Math.round((progressEvent.loaded / progressEvent.total) * 100)
                            );
                        },
                    }
                )
                .then(({ response }) => {
                    setStatusUpload("success");
                    navigate("/dashboard/campaign");
                });
        } catch ({ response }) {
            setStatusUpload("failed");
            const errorMsg = response.data;
            if (errorMsg.error.errors[0].message) {
                alert(errorMsg.error.errors[0].message);
            }
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    return (
        <>
            {statusUpload !== "" && (
                <ProgessUpload
                    status={statusUpload}
                    setStatus={setStatusUpload}
                    progress={progressUpload}
                />
            )}
            <ModalAlert
                isOpen={modalOpen.status && modalOpen.type === "alert"}
                closeModal={() => {
                    setModalOpen({ status: false, type: "" });
                    setModalAlert({ alertTitle: "", alertMessage: "" });
                }}
                alertTitle={modalAlert.alertTitle}
                alertMessage={modalAlert.alertMessage}
            />

            <div className="row-span-2 col-span-9 bg-white">
                <div className="border-b-2 p-4 border-bright flex mb-4">
                    <p className="text-2xl text-primary font-bold">Penyaluran</p>
                </div>
                <div className="flow-root p-4">
                    <div className='flex flex-col gap-8'>
                        <div className='flex flex-col mx-auto w-full gap-6'>
                            <div className="flex flex-col items-center p-4">
                                {existingImages ? "" : <div className="w-full max-w-lg">
                                    <label
                                        className="block mb-2 text-lg font-medium text-gray-700"
                                        htmlFor="file_input"
                                    >
                                        Upload Multiple Images
                                    </label>
                                    <input
                                        type="file"
                                        id="file_input"
                                        multiple
                                        accept="image/*"
                                        onChange={handleImageChange}
                                        className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none focus:ring focus:ring-blue-300"
                                    />
                                </div>}
                                

                                <div className="mt-4 grid grid-cols-2 gap-4">
                                    {selectedImages.map((image, index) => (
                                        <div key={index} className="relative w-32 h-32">
                                            <img
                                                src={URL.createObjectURL(image)}
                                                alt={`uploaded preview ${index}`}
                                                className="w-full h-full object-cover rounded-lg"
                                            />
                                            <button
                                                type="button"
                                                className="absolute top-1 right-1 bg-red-500 text-white rounded-full w-8 h-8 flex items-center justify-center"
                                                onClick={() => removeImage(index)}
                                            >
                                                X
                                            </button>
                                        </div>
                                    ))}
                                    {existingImages.map((image, index) => (
                                        <div key={index} className="relative w-32 h-32">
                                            <img
                                                src={image}
                                                alt={`uploaded preview ${index}`}
                                                className="w-full h-full object-cover rounded-lg"
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <form
                            id='form-update-profile'
                            className='flex flex-col gap-6 lg:w-full'>
                            <div className="flex flex-col gap-2">
                                <TextField
                                    inputId='target'
                                    inputName='target'
                                    type='number'
                                    label='Target Donasi'
                                    isRequired
                                    customLabelStyle='col-span-4 sm:col-span-1'
                                    customContainerStyle='col-span-4 sm:col-span-3 '
                                    placeholder='Masukkan Target Donasi'
                                />
                            </div>
                            <div className="flex flex-col gap-2">
                                <TextField
                                    inputId='raised'
                                    inputName='raised'
                                    type='number'
                                    label='Terkumpul'
                                    isRequired
                                    customLabelStyle='col-span-4 sm:col-span-1'
                                    customContainerStyle='col-span-4 sm:col-span-3 '
                                    placeholder='Masukkan Target Donasi'
                                />
                            </div>
                            <div className="flex flex-col gap-2">
                                <TextField
                                    inputId='distributed'
                                    inputName='distributed'
                                    type='number'
                                    label='Disalurkan'
                                    isRequired
                                    customLabelStyle='col-span-4 sm:col-span-1'
                                    customContainerStyle='col-span-4 sm:col-span-3 '
                                    placeholder='Masukkan Jumlah Disalurkan'
                                />
                            </div>
                            <div className="flex flex-col gap-2">
                                <TextField
                                    inputId='dateDistributed'
                                    inputName='dateDistributed'
                                    type='date'
                                    label='Tanggal Disalurkan'
                                    isRequired
                                    customLabelStyle='col-span-4 sm:col-span-1'
                                    customContainerStyle='col-span-4 sm:col-span-3 '
                                />
                            </div>

                            <div className="flex flex-col gap-2">
                                <TextArea
                                    isRequired={false}
                                    label='Catatan'
                                    inputId='note'
                                    inputName='note'
                                    placeholder="Masukkan Catatan"
                                />
                            </div>
                        </form>
                    </div>
                    <div className='flex flex-row justify-end mt-4'>
                        <Button
                            typeNavigation='button'
                            btnType='primary'
                            btnStyle='primary'
                            customStyle='px-4 py-0.5 w-fit rounded-full'
                            form='form-update-profile'
                            onClick={postCampaign} // Pass the function reference
                        >
                            <div>Simpan</div>
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default connect(null)(AddPenyaluranPage)
