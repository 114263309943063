import { Menu, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { NavLink } from 'react-router-dom';
import Icon from "@mdi/react";
import { mdiChevronDown, mdiChevronUp } from "@mdi/js";

function Dropdown({ label, items, isMobile }) {
  const [isOpen, setIsOpen] = useState(false);

  const handleMouseEnter = () => setIsOpen(true);
  const handleMouseLeave = () => setIsOpen(false);

  const toggleDropdown = () => setIsOpen(!isOpen);

  if (isMobile) {
    return (
      <div className="w-full">
        <button
          onClick={toggleDropdown}
          className="flex items-center justify-between w-full px-4 py-2 text-sm font-bold leading-[140%] tracking-wider text-dark"
        >
          {label}
          <Icon
            path={mdiChevronDown}
            size={1}
            className="text-dark"
          />
        </button>
        {isOpen && (
          <div className="mt-2 space-y-2 pl-4">
            {items.map((item, index) => (
              <NavLink
                key={index}
                to={`/services/${item.toLowerCase().replace(/\s+/g, '-')}`}
                className="block px-4 py-2 text-sm font-bold leading-[140%] tracking-wider text-dark"
              >
                {item}
              </NavLink>
            ))}
          </div>
        )}
      </div>
    );
  }
  
  return (
    <Menu as="div" className="relative inline-block text-left">
      {({ open }) => (
        <>
          <Menu.Button
            className="flex items-center justify-between w-full px-4 py-6 text-sm font-bold leading-[140%] tracking-wider text-dark hover:text-primary focus:outline-none"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {label}
            <Icon
              path={mdiChevronDown}
              size={1}
              className={open || isOpen ? 'text-primary' : 'text-dark'}
            />
          </Menu.Button>
          <Transition
            show={open || isOpen}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="absolute left-0 z-50 w-56 mt-1 origin-top-left bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <div className="py-1">
                {items.map((item, index) => (
                  <Menu.Item key={index}>
                    {({ active }) => (
                      <NavLink
                        to={`/services/${item.toLowerCase().replace(/\s+/g, '-')}`}
                        className={`${
                          active ? 'bg-primary text-white' : 'text-dark'
                        } block px-4 py-4 text-sm font-bold leading-[140%] tracking-wider`}
                      >
                        {item}
                      </NavLink>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
}

export default Dropdown;